import styled from '@emotion/styled';

import { Theme } from '#types';

interface TextStyle {
  theme? : Theme;
  colour?: keyof Theme['colours'];
  size?: keyof Theme['fonts']['sizes'];
  bold?: boolean;
}

const Text = styled.span<TextStyle>`
  ${({ theme, colour, size, bold }) => `
    ${colour ? `color: ${theme.colours[colour]};` : ''}
    ${size ? `font-size: ${theme.fonts.sizes[size]};` : ''}
    ${bold ? `font-weight: ${theme.boldWeight};` : ''}
  `}
`;

export default Text;
