import React from 'react';
import styled from '@emotion/styled';

import { Assembly } from '#mrktbox/types';
import { useOptions } from '#mrktbox';

import { Theme } from '#types';

import useRequests from '#hooks/useRequests';

import Heading from '#materials/Heading';

interface Style { theme? : Theme; }

const ProductOptionHeaderView = styled.div`
  display: flex;
  margin: 0 0 1rem;
  justify-content: space-between;
  align-items: flex-end;
`;

const ProductOptionHeaderName = styled(Heading)<Style>`
  margin-bottom: 0.5rem;
  font-size: ${(props) => props.theme.fonts.sizes.big};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.main};
  }
`;

interface NameQuantityStyle extends Style {
  belowMin? : boolean;
  belowIncluded? : boolean;
}

const ProductOptionHeaderNameQuantity = styled.span<NameQuantityStyle>`
  padding: 0 0 0 0.5rem;
  ${(props) =>
    props.belowMin
      ? `color: ${props.theme.colours.error};`
      : `color: ${props.theme.colours.success};`
  }
`;

interface ProductOptionHeaderProps {
  assembly : Assembly;
  count : number;
}

function ProductOptionHeader({
  assembly,
  count,
} : ProductOptionHeaderProps) {
  const { getAssemblyCounts } = useOptions();
  const { time } = useRequests();

  const { min, max } = getAssemblyCounts(assembly, time ?? new Date());

  return (
    <ProductOptionHeaderView>
      <div>
        <ProductOptionHeaderName as="p">
          { assembly.name }
          <ProductOptionHeaderNameQuantity
            belowMin={!!min && count < min}
          >
            { count }
            {max ? `/${max}` : ' selected'}
            {(min && (count < min)) ? `, ${min} required` : ''}
          </ProductOptionHeaderNameQuantity>
        </ProductOptionHeaderName>
      </div>
    </ProductOptionHeaderView>
  );
}

export default ProductOptionHeader;
