import React from 'react';

import { ServiceChannel } from '#mrktbox/types';

import IconTextButton from '#materials/IconTextButton';

import ServiceIcon from '#components/orders/ServiceIcon';

interface ServiceButtonProps {
  serviceChannel? : ServiceChannel | null;
  fallbackName? : string;
  label? : string;
  href? : string;
  disabled? : boolean;
  onClick? : () => void;
  fullButton? : boolean;
  compact? : boolean;
}

function ServiceButton({
  serviceChannel,
  fallbackName,
  label,
  href,
  disabled,
  onClick,
  fullButton = true,
  compact = false,
} : ServiceButtonProps) {
  return (
    <IconTextButton
      text={serviceChannel?.name ?? fallbackName}
      label={label ?? serviceChannel?.name ?? fallbackName}
      href={href}
      disabled={disabled}
      onClick={onClick}
      icon={<ServiceIcon channel={serviceChannel ?? null} />}
      useButton={fullButton}
      size={compact ? 'compact' : 'header'}
    />
  );
}

export default ServiceButton;
