import React from 'react';

import IconButton from '#materials/IconButton';
import IconLink from '#materials/IconLink';
import { ChevronLeftCircle } from '#materials/icons';

interface BackButtonProps {
  path?: string;
  onClick?: () => void;
}

const BackButton = ({ path = '/', onClick } : BackButtonProps) => {
  function Icon({ children } : { children : React.ReactNode } ) {
    return (
      path ? (
        <IconLink margin="0 0 0 -1.25rem" to={path} onClick={onClick}>
          {children}
        </IconLink>
      ) : (
        <IconButton margin="0 0 0 -1.25rem" onClick={onClick}>
          {children}
        </IconButton>
      )
    );
  }

  return (<Icon><ChevronLeftCircle size={25} /></Icon>);
}

export default BackButton;
