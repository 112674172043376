import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { DraftOrder, Product, Subscription } from '#mrktbox/types';
import { useOptions, useSubscriptions, useNotes } from '#mrktbox';

import { Theme } from '#types';

import useSidebar from '#hooks/useSidebar';
import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';

import CardItem from '#materials/CardItem';
import ButtonStyled from '#materials/ButtonStyled';
import ButtonLink from '#materials/ButtonLink';
import { Plus } from '#materials/icons';
import { Refresh } from '#materials/icons';

import ProductCardButton from '#components/products/ProductCardButton';
import ProductCount from '#components/products/ProductCount';
import ProductSubscriptionOverlay
  from '#components/products/ProductSubscriptionOverlay';
import Cart from '#components/cart/Cart';

interface Style {
  theme? : Theme;
}

interface ProductCardButtonsAddStyle extends Style {
  disabled : boolean;
}

interface ProductCardButtonStyle extends Style {
  hasBox : boolean;
}

const ProductCardView = styled(CardItem)<Style>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: .5px solid ${(props) => props.theme.colours.success};
  background-color: white;
`;

const ProductCardButtonsAdd = styled.div<ProductCardButtonsAddStyle>`
  display: flex;
  gap: 0.5rem;

  ${(props) => props.disabled
    ? `
      button, button:active, button:hover, button:disabled {
        border: 0;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        color: black;
      }
    ` : ''}
`;

const ProductCardButtons = styled.div<ProductCardButtonStyle>`
  flex-grow: 0;
  padding: ${(props) => (props.hasBox ? '0 1.3rem 1.5rem' : '0')};

  .compact & {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: ${(props) => (props.hasBox ? '0 1.3rem 1rem' : '0')};
    }
  }
`;

const ProductCardButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QuickButtonView = styled.div<Style>`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

const SubscribeButton = styled.div<Style>`
  display: block;
  margin: auto 0 auto 0.5rem;

  * {
    margin-right: 0.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

const MobileSubscribeButton = styled.div<Style>`
  display: none;
  font-size: 1.5rem;
  padding: 0.5rem 0 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: block;
  }
`;

interface SubscriptionButtonProps{
  subscription : Subscription | null;
  order : DraftOrder | null;
  onClick : () => void;
}

function SubscriptionButton({
  subscription,
  order,
  onClick,
} : SubscriptionButtonProps) {
  const { evaluateReccurence, formatRecurrence } = useRequests();

  const recurrence = evaluateReccurence(
    subscription,
    { iteration : order?.timeSlotIteration },
  );

  return (
    <ButtonLink onClick={onClick}>
      { !!recurrence && (<Refresh size={11} />) }
      { recurrence
        ? formatRecurrence(recurrence)
        : 'subscribe' }
    </ButtonLink>
  );
}

interface ProductCardProps{
  product : Product;
  onClick? : () => void;
  isSimple? : boolean;
}

function ProductCard({ product, onClick } : ProductCardProps) {
  const { openSidebar } = useSidebar();
  const { isProductCustomisable, getProductAssemblies } = useOptions();
  const {
    findProductSubscription,
    determineProductsCount,
  } = useSubscriptions();
  const { doesProductRequireNote } = useNotes();
  const {
    getProductSlug,
    isProductStocked,
    isCustomisationRequired,
    isAssemblyManditory,
    getCurrentSelections,
  } = useCatalogue();
  const {
    waiting,
    time,
    currentOrder,
    canUpdateOrder,
    canSubscribe,
    evaluateReccurence,
    addItem,
    subscribeProduct,
    scrollToLineItem,
  } = useRequests();

  const [selectSubscriptions, setSelectSubscriptions] = useState(false);

  const handleAdd = useCallback(async () => {
    const subscription = currentOrder
      && findProductSubscription(product, currentOrder);

    if (subscription) {
      if (evaluateReccurence(subscription)) {
        openSidebar(<Cart />);
        scrollToLineItem(subscription.lineItemId);
        return;
      }
    }

    await addItem({
      product,
      quantity : 1,
      selections : getCurrentSelections(product),
    });
  }, [
    product,
    currentOrder,
    findProductSubscription,
    openSidebar,
    getCurrentSelections,
    evaluateReccurence,
    scrollToLineItem,
    addItem,
  ]);

  const handleSubscribe = useCallback(() => {
    setSelectSubscriptions(true);
  }, []);

  const handleSetSubscription = useCallback(async (period : number) => {
    const success = await subscribeProduct(product, period);
    if (success) setSelectSubscriptions(false);
  }, [product, subscribeProduct]);

  const customisable = useMemo(() => (
    isProductCustomisable(product, time ?? new Date())
      && getProductAssemblies(product)
        .some((a) => !isAssemblyManditory(a, time ?? new Date()))
  ), [
    product,
    time,
    isAssemblyManditory,
    isProductCustomisable,
    getProductAssemblies
  ]);
  const customisationRequired = useMemo(() => (
    isCustomisationRequired(product)
      || doesProductRequireNote(product)
  ), [product, isCustomisationRequired, doesProductRequireNote]);

  const qty = useMemo(() => (
    currentOrder
      ? determineProductsCount([product], currentOrder)
      : 0
  ), [product, currentOrder, determineProductsCount]);
  const subscription = useMemo(() => (
    currentOrder
      ? findProductSubscription(product, currentOrder)
      : null
  ), [product, currentOrder, findProductSubscription]);

  const outOfStock = useMemo(
    () => (!isProductStocked(product)),
    [product, isProductStocked],
  );
  const recurrence = useMemo(() => (evaluateReccurence(
    subscription,
    { iteration : currentOrder?.timeSlotIteration },
  )), [subscription, currentOrder, evaluateReccurence]);

  return (
    <ProductCardView className={'compact'}>
      { qty > 0 && <ProductCount><span>{ qty }</span></ProductCount> }
      <ProductCardButton
        product={product}
        onClick={onClick}
        outOfStock={outOfStock}
      />
      <ProductCardButtons hasBox={true}>
        { (!customisable && canSubscribe()) && (
          <MobileSubscribeButton>
            <SubscriptionButton
              subscription={subscription}
              order={currentOrder}
              onClick={handleSubscribe}
            />
          </MobileSubscribeButton>
        ) }
        <ProductCardButtonsContainer>
          <ProductCardButtonsAdd disabled={false}>
            <ButtonStyled
              size="small"
              href={(customisable || customisationRequired)
                ? `/product/${getProductSlug(product)}`
                : undefined}
              onClick={customisable ? onClick : handleAdd}
              disabled={waiting || outOfStock || !canUpdateOrder()}
            >
              { outOfStock
                ? 'Out of Stock'
                : ((customisable || customisationRequired)
                  ? 'Customise'
                  : (recurrence ? 'Manage' : 'Add to Order'))
              }
            </ButtonStyled>
            { (customisable && !customisationRequired) && (
              <QuickButtonView>
                <ButtonStyled
                  size="small"
                  onClick={handleAdd}
                  disabled={waiting || outOfStock}
                >
                  <Plus size={16} />
                </ButtonStyled>
              </QuickButtonView>
            ) }
            { (!outOfStock && !customisable && canSubscribe()) && (
              <SubscribeButton>
                <SubscriptionButton
                  subscription={subscription}
                  order={currentOrder}
                  onClick={handleSubscribe}
                />
              </SubscribeButton>
            ) }
          </ProductCardButtonsAdd>
        </ProductCardButtonsContainer>
      </ProductCardButtons>
      { selectSubscriptions && (
        <ProductSubscriptionOverlay
          onSelect={handleSetSubscription}
          onCancel={() => setSelectSubscriptions(false)}
        />
      ) }
    </ProductCardView>
  );
}

export default ProductCard;
