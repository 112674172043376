import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';

import { PaymentToken, Subtotal } from '#mrktbox/types';
import { useIntegrations } from '#mrktbox';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

import Form from '#materials/Form';
import Text from '#materials/Text';
import { AlertCircle } from '#materials/icons';
import ButtonStyled from '#materials/ButtonStyled';

const SQR_PRODUCTION_SCRIPT_URL = 'https://web.squarecdn.com/v1/square.js';
const SQR_SANDBOX_SCRIPT_URL = 'https://sandbox.web.squarecdn.com/v1/square.js';

const CONTAINER_ID = 'card-verification';
const SCRIPT_ID = 'square-payments';

declare global {
  interface Window {
    Square: any;
  }
}

interface Style { theme? : Theme; }

const ErrorMessage = styled.div<Style>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin: 0 0 ${(props) => props.theme.layout.padding};
`;

interface CardStyle {
  errorColour? : string,
  focusColour? : string,
}

interface InitArgs {
  appId : string,
  locationId : string,
  containerId : string,
  cardStyle? : CardStyle,
}

async function initSquareCard({
  appId,
  locationId,
  containerId,
  cardStyle,
} : InitArgs) {
  const payments = window.Square.payments(appId, locationId);
  const card = await payments.card({
    ...(cardStyle && { 'style' : {
      '.input-container.is-focus' : {
        ...(cardStyle.focusColour && { borderColor : cardStyle.focusColour }),
      },
      'input.is-error' : {
        ...(cardStyle.errorColour && { color : cardStyle.errorColour }),
      },
      '.input-container.is-error' : {
        ...(cardStyle.errorColour && { borderColor : cardStyle.errorColour }),
      },
      '.message-icon.is-error' : {
        ...(cardStyle.errorColour && { color : cardStyle.errorColour }),
      },
      '.message-text.is-error' : {
        ...(cardStyle.errorColour && { color : cardStyle.errorColour }),
      },
    } }),
  });
  card.attach('#' + containerId);
  return card;
}

function disposeSquareCard(card : any) {
  card.destroy();
};

function loadSquare(
  scriptId : string,
  url : string,
  init : () => void,
) {
  const script = document.createElement('script');
  script.id = scriptId;
  script.src = url;
  script.crossOrigin = 'anonymous';
  script.onload = () => init();
  script.onerror = () => console.error(
    'Failed to load Square payment script',
  );
  document.getElementsByTagName('head')[0].appendChild(script);
}

function checkSquare(scriptId : string) {
  return !!document.getElementById(scriptId);
}

function unloadSquare(scriptId : string) {
  const script = document.getElementById(scriptId);
  if (script) {
    script.remove();
  }
}

async function verify(cardInterface : any) {
  if (cardInterface && cardInterface.status !== 2) {
    const result = await cardInterface.tokenize();
    if (result.status === 'OK') {
      return {
        token : result.token,
        brand : result.details.card.brand,
        last4 : result.details.card.last4,
        expMonth : result.details.card.expMonth,
        expYear : result.details.card.expYear,
        postal : result.details.billing.postalCode,
      };
    }
  }
  return null;
}

interface SquareFormProps {
  total? : Subtotal,
  onSaved? : (token : PaymentToken) => boolean | Promise<boolean>,
  onCancel? : () => void,
}

function SquareForm({
  total,
  onSaved,
  onCancel,
} : SquareFormProps) {
  const { theme } = useConfig();
  const { getIntegration } = useIntegrations();

  const initiated = useRef(false);

  const [colour] = useState<string>(theme.colours.primary);
  const [cardInterface, setCardInterface] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    setError(false);

    const tokenized = await verify(cardInterface);
    if (tokenized) {
      const success = (!!(await onSaved?.({
        last4 : tokenized.last4,
        brand : tokenized.brand,
        expMonth : tokenized.expMonth,
        expYear : tokenized.expYear,
        token : tokenized.token,
        postal : tokenized.postal,
      })) ?? true);
      if (!success) setError(true);
    } else {
      setError(true);
    }
    setSubmitting(false);
  }, [onSaved, cardInterface]);

  useEffect(() => {
    const integration = getIntegration({
      engineName : 'square',
      channelName : 'accounts',
    })
    const environment = integration?.settings?.environment ?? 'sandbox';
    const app_id = integration?.settings?.app_id
    const location_id = integration?.settings?.location_id

    if (!app_id || !location_id) {
      console.error('No Square credentials found.');
      return;
    }

    if (!document.getElementById(CONTAINER_ID)) return;

    if (!initiated.current && !checkSquare(SCRIPT_ID)) {
      initiated.current = true;
      loadSquare(
        SCRIPT_ID,
        environment === 'production'
          ? SQR_PRODUCTION_SCRIPT_URL
          : SQR_SANDBOX_SCRIPT_URL,
        async () => {
          const card = await initSquareCard({
            appId : app_id,
            locationId : location_id,
            containerId : CONTAINER_ID,
            cardStyle : {
              errorColour : theme.colours.error,
              focusColour : theme.inputs.borderColourFocus,
            },
          });
          setCardInterface(card ?? null);
        },
      );
    }

    return () => {
      if (cardInterface) disposeSquareCard(cardInterface);
      if (checkSquare(SCRIPT_ID)) unloadSquare(SCRIPT_ID);
    }
  }, [colour, cardInterface, theme, getIntegration]);

  return (
    <Form
      compact
      onSubmit={handleSubmit}
      buttons={
        <>
          <ButtonStyled
            type="button"
            preventDefault
            onClick={onCancel}
          >
            Cancel
          </ButtonStyled>
          <ButtonStyled
            type="submit"
            disabled={!cardInterface || submitting}
          >
            Submit { total && ` - ${formatCurrency(total.total)}` }
          </ButtonStyled>
        </>
      }
    >
      <div style={{ minHeight : '100px' }}>
        <div id={CONTAINER_ID}></div>
      </div>
      { error && (
        <ErrorMessage>
          <AlertCircle size={16} />
          <Text style={{ flex : 'none' }}>
            There was an error processing your card. Please try again.
          </Text>
        </ErrorMessage>
      ) }
    </Form>
  );
}

export default SquareForm;
