import React from 'react';
import styled from '@emotion/styled';

import useConfig from '#hooks/useConfig';

import Box from '#materials/Box';
import Heading from '#materials/Heading';
import Preface from '#materials/Preface';

interface Style { theme? : any; }
interface CardViewStyle {
  hasBox : boolean;
}

const CardView = styled(Box)<CardViewStyle>`
  position: relative;
  height: 100%;
  padding: 1.5rem 1.5rem 1rem;
  ${(props) =>
    !props.hasBox
      ? `
        border: ${props.theme.border.width}
        solid ${props.theme.border.colour};
      `
      : ''};
`;

const CardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.div`
  flex-grow: 0;
`;

const CardPreface = styled.p<Style>`
  span {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`;

const CardTitle = styled(Heading)`
  margin: 0.5rem 0;
`;

const CardSubtitle = styled.p<Style>`
  font-size: ${(props) => props.theme.fonts.sizes.small};
`;

const CardContent = styled.div<Style>`
  margin: 1rem 0 0;
  flex-grow: 1;

  > p {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
  }
`;

const CardFooter = styled.div`
  flex-grow: 0;
  margin: 1.9rem 0 0;

  > div {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: flex-start;
    flex-wrap: wrap;

    button {
      margin: 0 0.5rem 0.5rem 0;
      &:last-child {
        margin: 0;
      }
    }
  }
`;

interface CardProps {
  tag? : React.ReactNode;
  preface? : React.ReactNode;
  title? : React.ReactNode;
  subtitle? : React.ReactNode;
  content? : React.ReactNode;
  footer? : React.ReactNode;
  style? : React.CSSProperties;
}

function Card({
  tag,
  preface,
  title,
  subtitle,
  content,
  footer,
  style,
} : CardProps) {
  const { theme } = useConfig()
  const hasBox = theme.cards.default.bgColour !== 'transparent'

  return (
    <CardView hasBox={hasBox} style={style}>
      { tag }
      <CardContainer>
        <CardHeader>
          { preface && (
            <CardPreface>
              <Preface>{preface}</Preface>
            </CardPreface>
          ) }
          { title && (
            <CardTitle as="p" className="title">
              { title }
            </CardTitle>
          ) }
          { subtitle && <CardSubtitle>{ subtitle }</CardSubtitle> }
        </CardHeader>
        <CardContent>{ content }</CardContent>
        <CardFooter>
          <div>{ footer }</div>
        </CardFooter>
      </CardContainer>
    </CardView>
  );
}

export default Card;
