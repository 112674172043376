import React, { createContext, useCallback, useContext, useState } from 'react';

import GooglMapsContext from '#context/GoogleMapsContext';

import useConfig from '#hooks/useConfig';

type Marker = google.maps.Marker;
interface Map {
  map : google.maps.Map;
  currentMarker : Marker | null;
  pins : { [key : string] : Marker };
}

const MapContext = createContext({
  makeMap :
    (ref : React.MutableRefObject<null>) => null as Map | null,
  showMap : false,
  openMap : () => {},
  closeMap : () => {},
  setCenter : (lat : number, lng : number) => {},
  setCurrentLocation : (lat : number, lng : number) => {},
  removeCurrentLocation : () => {},
  setPin : (
    lat : number,
    lng : number,
    options? : {
      key? : string,
      onClick? : () => void,
    },
  ) => {
    return null as { key : string } | null;
  },
  removePin : (key : string) => {},
});

interface MapProviderProps {
  children : React.ReactNode;
}

export function MapProvider({ children } : MapProviderProps) {
  const { makeMap : makeGoogleMap } = useContext(GooglMapsContext);
  const { settings } = useConfig();

  const [maps, setMaps] = useState<Map[]>([]);
  const [showMap, setShowMap] = useState(false);

  const openMap = useCallback(() => setShowMap(true), []);
  const closeMap = useCallback(() => setShowMap(false), []);

  const makeMap = useCallback((ref : React.MutableRefObject<null>) => {
    if (!ref.current) return null;

    const newMap = {
      map : makeGoogleMap(ref),
      currentMarker : null,
      pins : {},
    };
    if (!newMap.map) return null;

    setMaps((maps) => [...maps, newMap as Map]);
    return newMap as Map;
  }, [makeGoogleMap]);

  const setMapCenter = useCallback((lat : number, lng : number, map : Map) => {
    const center = new google.maps.LatLng(lat, lng);
    map.map.panTo(center);
  }, []);

  const setMapCurrentLocation = useCallback(
    (lat : number, lng : number, map : Map) => {
      const center = new google.maps.LatLng(lat, lng);
      if (map.currentMarker) {
        map.currentMarker.setPosition(center);
        return;
      }

      const scale = new google.maps.Size(
        settings.maps.icons.current.size.height,
        settings.maps.icons.current.size.width,
      );
      const anchor = new google.maps.Point(
        settings.maps.icons.current.anchor.x,
        settings.maps.icons.current.anchor.y,
      );
      const url = settings.maps.icons.current.url ||
        process.env.REACT_APP_DEFAULT_MAP_MARKER;

      map.currentMarker = new google.maps.Marker({
        position : center,
        icon : {
          url,
          scaledSize : scale,
          anchor,
        },
        map: map.map,
      });
    },
    [settings],
  );

  const removeMapCurrentLocation = useCallback((map : Map) => {
    if (!map.currentMarker) return;
    map.currentMarker.setMap(null);
    map.currentMarker = null;
  }, []);

  const setMapPin = useCallback((
    lat : number,
    lng : number,
    map : Map,
    options : {
      key : string,
      onClick? : () => void,
    }
  ) => {
    const key = options.key;
    const onClick = options?.onClick;

    const position = new google.maps.LatLng(lat, lng);
    if (map.pins[key]) {
      map.pins[key].setPosition(position);
      return { key };
    }

    const scale = new google.maps.Size(
      settings.maps.icons.pin.size.height,
      settings.maps.icons.pin.size.width,
    );
    const anchor = new google.maps.Point(
      settings.maps.icons.pin.anchor.x,
      settings.maps.icons.pin.anchor.y,
    );
    const url = settings.maps.icons.pin.url ||
      process.env.REACT_APP_DEFAULT_MAP_PIN ||
      process.env.REACT_APP_DEFAULT_MAP_MARKER;
    const marker = new google.maps.Marker({
      position : position,
      icon : {
        url,
        scaledSize : scale,
        anchor,
      },
      map: map.map,
    });
    if (onClick) marker.addListener('click', onClick);

    map.pins[key] = marker;
  }, [settings]);

  const removeMapPin = useCallback((key : string, map : Map) => {
    if (!map.pins[key]) return;

    map.pins[key].setMap(null);
    delete map.pins[key];
  }, []);

  const setPin = useCallback((
    lat : number,
    lng : number,
    options? : {
      key? : string,
      onClick? : () => void,
    },
  ) => {
    const key = options?.key || `${lat}-${lng}`;
    maps.forEach((map) => setMapPin(
      lat,
      lng,
      map,
      options ? { key, ...options } : { key },
    ));
    return { key };
  }, [maps, setMapPin]);

  const removePin = useCallback((key : string) => {
    maps.forEach((map) => removeMapPin(key, map));
  }, [maps, removeMapPin]);

  const setCenter = useCallback((lat : number, lng : number) => {
    maps.forEach((map) => setMapCenter(lat, lng, map));
  }, [maps, setMapCenter]);

  const setCurrentLocation = useCallback((lat : number, lng : number) => {
    maps.forEach((map) => setMapCurrentLocation(lat, lng, map));
  }, [maps, setMapCurrentLocation]);

  const removeCurrentLocation = useCallback(() => {
    maps.forEach((map) => removeMapCurrentLocation(map));
  }, [maps, removeMapCurrentLocation]);

  const context = {
    makeMap,
    showMap,
    openMap,
    closeMap,
    setCenter,
    setCurrentLocation,
    removeCurrentLocation,
    setPin,
    removePin,
  };

  return (
    <MapContext.Provider value={context}>
      { children }
    </MapContext.Provider>
  );
}

export default MapContext;
