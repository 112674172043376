import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import Label from '#materials/Label';

const TextAreaView = styled.textarea`
  resize: vertical;
  overflow: hidden;

  :disabled {
    opacity: 1;
  }
`;

interface TextAreaProps {
  label? : string;
  name? : string;
  value? : string | number;
  onChange? : (value : string) => void;
  error? : string;
  showLabel? : boolean;
  disabled? : boolean;
  readOnly? : boolean;
  required? : boolean;
  placeholder? : string;
  style? : React.CSSProperties;
}

function TextArea({
  label,
  name,
  value,
  onChange,
  error,
  showLabel = true,
  disabled = false,
  readOnly = false,
  required = false,
  placeholder = '',
  style = undefined,
} : TextAreaProps){
  const handleChange = useCallback(
    (e : React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <Label
      htmlFor={name}
      text={label}
      value={value}
      required={required}
      errMsg={error}
      showLabel={showLabel}
      disabled={disabled}
      style={style}
      isTextarea={true}
    >
      <TextAreaView
        rows={0}
        aria-label={label}
        id={name}
        name={name}
        value={value || ''}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        onChange={handleChange}
      />
    </Label>
  )
}

export default TextArea;
