import React from 'react';

import {
  AuthProvider,
  IntegrationsProvider,
  AreaProvider,
  LocationProvider,
  ServiceChannelProvider,
  ServiceProvider,
  ScheduleProvider,
  RouteProvider,
  RevenueCentreProvider,
  ProductsProvider,
  TagProvider,
  TaxProvider,
  CategoryProvider,
  SupplierProvider,
  InventoryProvider,
  CustomersProvider,
  CardsProvider,
  AdjustmentProvider,
  ConditionProvider,
  OrderProvider,
  OptionsProvider,
  SubscriptionsProvider,
  NoteProvider,
} from '#mrktbox';

import { GoogleMapsProvider } from '#context/GoogleMapsContext';
import { ChatProvider } from '#context/ChatContext';
import { CustomerProvider } from '#context/CustomerContext';
import { RequestProvider } from '#context/RequestContext';
import { CatalogueProvider } from '#context/CatalogueContext';
import { LoginProvider } from '#context/LoginContext';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

interface ProvidersProps {
  children? : React.ReactNode;
}

function Providers({ children } : ProvidersProps) {
  return (
    <>
      <AuthProvider>
      <IntegrationsProvider>
      <AreaProvider>
      <LocationProvider>
      <ServiceChannelProvider>
      <ServiceProvider>
      <ScheduleProvider>
      <RouteProvider>
      <RevenueCentreProvider>
      <ProductsProvider>
      <TagProvider>
      <TaxProvider>
      <CategoryProvider>
      <SupplierProvider>
      <InventoryProvider>
      <CustomersProvider>
      <CardsProvider>
      <AdjustmentProvider>
      <ConditionProvider>
      <OrderProvider>
      <OptionsProvider>
      <SubscriptionsProvider>
      <NoteProvider>
      <GoogleMapsProvider apiKey={googleMapsApiKey}>
      <ChatProvider>
      <CustomerProvider>
      <RequestProvider>
      <CatalogueProvider>
      <LoginProvider>
      { children }
      </LoginProvider>
      </CatalogueProvider>
      </RequestProvider>
      </CustomerProvider>
      </ChatProvider>
      </GoogleMapsProvider>
      </NoteProvider>
      </SubscriptionsProvider>
      </OptionsProvider>
      </OrderProvider>
      </ConditionProvider>
      </AdjustmentProvider>
      </CardsProvider>
      </CustomersProvider>
      </InventoryProvider>
      </SupplierProvider>
      </CategoryProvider>
      </TaxProvider>
      </TagProvider>
      </ProductsProvider>
      </RevenueCentreProvider>
      </RouteProvider>
      </ScheduleProvider>
      </ServiceProvider>
      </ServiceChannelProvider>
      </LocationProvider>
      </AreaProvider>
      </IntegrationsProvider>
      </AuthProvider>
    </>
  );
}

export default Providers;
