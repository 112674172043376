import React, { useCallback } from 'react'
import styled from '@emotion/styled'

import { Theme } from '#types'

import Label from '#materials/Label';

interface InputViewStyle {
  theme? : Theme;
  hasValue? : boolean;
  hasError? : boolean;
}

const InputView = styled.input<InputViewStyle>`
  width: 100%;
  padding: ${(props) => props.theme.inputs.padding};
  padding-top: ${(props) => props.theme.inputs.paddingTop};
  padding-bottom: ${(props) => props.theme.inputs.paddingBottom};
  border-style: ${(props) => props.theme.inputs.borderStyle};
  border-color: ${(props) => props.theme.inputs.borderColour};
  border-width: ${(props) => props.theme.inputs.borderWidth};
  border-radius: ${(props) => props.theme.inputs.radius};
  line-height: ${(props) => props.theme.inputs.lineHeight};
  font-family: ${(props) => props.theme.inputs.family};
  font-size: ${(props) => props.theme.inputs.fontSize};
  font-weight: ${(props) => props.theme.inputs.weight};
  letter-spacing: ${(props) => props.theme.inputs.letterSpacing};
  text-transform: ${(props) => props.theme.inputs.textTransform};
  -webkit-font-smoothing: ${(props) => props.theme.inputs.fontSmoothing};
  color: ${(props) => props.theme.inputs.colour};
  background-color: ${(props) => props.theme.inputs.bgColour};
  box-shadow: ${(props) => props.theme.inputs.boxShadow};
  transition: ${(props) => props.theme.links.transition};

  ${(props) =>
    props.theme.inputs.bottomBorderOnly
      ? `
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    border-width: 0;
    border-bottom-width: ${props.theme.inputs.borderWidth};
  `
      : ''}

  ${(props) =>
    props.hasError ? `border-color: ${props.theme.colours.error};` : ''}

  ${(props) =>
    props.hasValue
      ? `padding-top: ${props.theme.inputs.paddingTopActive};` +
        `padding-bottom: ${props.theme.inputs.paddingBottomActive};`
      : ''}

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.inputs.fontSizeMobile};
  }

  &::placeholder {
    color: ${(props) => props.theme.inputs.placeholderColour};
  }

  &:active,
  &:focus {
    color: ${(props) => props.theme.inputs.colourFocus};
    background-color: ${(props) => props.theme.inputs.bgColourFocus};
    border-color: ${(props) => props.theme.inputs.borderColourFocus};
    padding-top: ${(props) => props.theme.inputs.paddingTopActive};
    padding-bottom: ${(props) => props.theme.inputs.paddingBottomActive};

    ${(props) =>
      props.hasError ? `border-color: ${props.theme.colours.error};` : ''}

    ${(props) => !props.theme.inputs.showOutline && `outline: none;`}
  }

  &::selection {
    color: ${(props) => props.theme.colours.light};
    background-color: ${(props) => props.theme.colours.dark};
  }

  &:disabled,
  &:read-only {
    cursor: default;
    opacity: 1;
    color: ${(props) => props.theme.inputs.colour};
    background-color: ${(props) => props.theme.inputs.bgColour};
    border-color: ${(props) => props.theme.inputs.borderColour};
  }
`

interface InputProps {
  icon? : React.ReactNode;
  label? : string;
  name : string;
  type? : string;
  value? : string;
  onChange? : (value : string) => void;
  onBlur? : () => void;
  error? : string;
  showLabel? : boolean;
  placeholder? : string;
  disabled? : boolean;
  readOnly? : boolean;
  required? : boolean;
  autoComplete? : string;
  pattern? : string;
  min? : string;
  max? : string;
  style? : React.CSSProperties;
  inputRef? : React.Ref<HTMLInputElement>;
  children? : React.ReactNode;
}

function Input({
  icon = null,
  label,
  name,
  type,
  value,
  onChange,
  onBlur,
  error = '',
  showLabel = true,
  placeholder = '',
  disabled = false,
  readOnly = false,
  required = false,
  autoComplete,
  pattern,
  min,
  max,
  style,
  inputRef,
  children,
} : InputProps) {
  const handleChange = useCallback(
    (e : React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <Label
      htmlFor={name}
      icon={icon}
      text={label}
      value={value}
      required={required}
      errMsg={error}
      showLabel={showLabel}
      disabled={disabled}
      style={style}
    >
      <InputView
        aria-label={label}
        id={name}
        name={name}
        type={type}
        pattern={pattern}
        min={min}
        max={max}
        autoComplete={autoComplete || undefined}
        value={value || ''}
        placeholder={placeholder || ''}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        onChange={handleChange}
        onBlur={onBlur}
        ref={inputRef}
        hasValue={!!value}
        hasError={!!error}
      />
      {children}
    </Label>
  );
}

export default Input;
