import useConfig from "#hooks/useConfig";

interface ChevronLeftCircleProps {
  size?: number;
  strokeWidth?: number;
}

function ChevronLeftCircle({
  size,
  strokeWidth,
} : ChevronLeftCircleProps) {
  const { theme } = useConfig();

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth || theme.icons.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      overflow="visible"
    >
      <path d="M15 18L9 12L15 6" />
      <circle cx="12.5" cy="12.5" r="12" />
    </svg>
  )
}

export default ChevronLeftCircle;
