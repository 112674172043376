import {
  TimeSlot,
  Schedule,
  Service,
  Window,
  isTimeSlot,
  isSchedule,
  isWindow,
} from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request } from '#mrktbox/clerk/api/mrktbox';

import { parseDateTime } from '#mrktbox/clerk/utils/date';

const SERVICES_PATH = 'services/';
const SCHEDULES_PATH = 'scheduling/';
const TIMESLOTS_PATH = 'time-slots/';
const WINDOW_PATH = `${SCHEDULES_PATH}windows/`;

interface TimeSlotsProps {
  timeSlot : TimeSlot;
}

interface ScheduleProps {
  schedule : Schedule;
}

function parseWindow(window : any) : Window {
  const parsedWindow = {
    ...window,
    start : parseDateTime(window.start),
  }
  if (!isWindow(parsedWindow))
    throw new TypeError('Window is not a Window object');

  return parsedWindow;
}

function parseWindows(windows : any) : { [id : number] : Window } {
  const parsedWindows : { [id : number] : Window } = {};
  for (const windowId in windows) {
    if (typeof windowId !== 'string')
      throw new TypeError('Window id is not a string');

    const id = parseInt(windowId);
    if (isNaN(id))
      throw new TypeError('Window id is not a number');

    parsedWindows[id] = parseWindow(windows[id]);
  }
  return parsedWindows;
}

function parseTimeSlot(timeSlot : any) : TimeSlot {
  const parsedTimeSlot = {
    ...timeSlot,
    start : parseDateTime(timeSlot.start),
    nextWindow : parseDateTime(timeSlot.nextWindow),
    windows : timeSlot.windows ? parseWindows(timeSlot.windows) : {},
  }
  if (!isTimeSlot(parsedTimeSlot))
    throw new TypeError('Time slot is not a TimeSlot object');

  return parsedTimeSlot;
}

function parseTimeSlots(timeSlots : any) : { [id : number] : TimeSlot } {
  const parsedTimeSlots = {} as { [id : number] : TimeSlot };
  for (const timeSlotId in timeSlots) {
    if (typeof timeSlotId !== 'string')
      throw new TypeError('TimeSlot id is not a string');

    const id = parseInt(timeSlotId);
    if (isNaN(id))
      throw new TypeError('TimeSlot id is not a number');

    parsedTimeSlots[id] = parseTimeSlot(timeSlots[id]);
  }
  return parsedTimeSlots;
}

function parseSchedule(schedule : any): Schedule {
  if (!isSchedule(schedule))
    throw new TypeError('schedule is not a Schedule Object');

  return {
    id : schedule.id,
    name : schedule.name,
    timeSlots : parseTimeSlots(schedule.timeSlots),
    serviceIds : schedule.serviceIds,
  }
}

function parseSchedules(schedules : any) : { [id : number] : Schedule } {
  const parsedSchedules = {} as { [id : number] : Schedule };
  for (const scheduleId in schedules) {
    if (typeof scheduleId != 'string')
      throw new TypeError('Schedule id is not a string');

    const id = parseInt(scheduleId);
    if (isNaN(id))
      throw new TypeError('Schedule id is not a number');

    parsedSchedules[id] = parseSchedule(schedules[id]);
  }
  return parsedSchedules;
}

export async function createTimeSlot({
  timeSlot
} : TimeSlotsProps) {
  const response = await request(
    getUrl(`${SCHEDULES_PATH}${TIMESLOTS_PATH}`),
    methods.post,
    { timeSlot },
  );

  try {
    return parseTimeSlot(response.timeSlot);
  } catch {
    throw new DeserializationError(
      'Could not deserialize time slot',
      response,
    );
  }
}

export async function retrieveTimeSlots() {
  const response = await request(
    getUrl(`${SCHEDULES_PATH}${TIMESLOTS_PATH}`),
    methods.get,
  );
  try {
    return parseTimeSlots(response.timeSlots);
  } catch {
    throw new DeserializationError(
      'Could not deserialize time slots',
      response,
    );
  }
}

export async function retrieveTimeSlot({
  timeSlotId
} : { timeSlotId : number }) {
  const response = await request(
    getUrl(`${SCHEDULES_PATH}${TIMESLOTS_PATH}${timeSlotId}`),
    methods.get,
  );
  try {
    return parseTimeSlot(response.timeSlot);
  } catch {
    throw new DeserializationError(
      'Could not deserialize time slot',
      response,
    );
  }
}

export async function updateTimeSlot({ timeSlot } : TimeSlotsProps) {
  const response = await request(
    getUrl(`${SCHEDULES_PATH}${TIMESLOTS_PATH}${timeSlot.id}`),
    methods.put,
    { timeSlot },
  );

  try {
    return parseTimeSlot(response.timeSlot);
  } catch {
    throw new DeserializationError(
      'Could not deserialize time slot',
      response,
    );
  }
}

export async function deleteTimeSlot({ timeSlot } : TimeSlotsProps) {
  const response = await request(
    getUrl(`${SCHEDULES_PATH}${TIMESLOTS_PATH}${timeSlot.id}`),
    methods.delete,
  );

  return !!response?.success;
}

export async function createWindow({ window } : { window : Window }) {
  const response = await request(
    getUrl(WINDOW_PATH),
    methods.post,
    { window },
  );

  try {
    return parseWindow(response.window);
  } catch {
    throw new DeserializationError(
      'Could not deserialize window',
      response,
    );
  }
}

export async function addTimeSlotToSchedule(
  { schedule, timeSlot } : { schedule : Schedule, timeSlot : TimeSlot }
) {
  const response = await request(
    getUrl(`${SCHEDULES_PATH}${schedule.id}/${TIMESLOTS_PATH}${timeSlot.id}`),
    methods.post,
  );

  return !!response;
}

export async function removeTimeSlotFromSchedule(
  { schedule, timeSlot } : { schedule : Schedule, timeSlot : TimeSlot }
) {
  const response = await request(
    getUrl(`${SCHEDULES_PATH}${schedule.id}/${TIMESLOTS_PATH}${timeSlot.id}`),
    methods.delete,
  );

  return !!response;
}

export async function createSchedule({ schedule } : ScheduleProps) {
  const response = await request(
    getUrl(SCHEDULES_PATH),
    methods.post,
    { schedule : { name : schedule.name } },
  );

  try {
    return parseSchedule(response.schedule);
  } catch {
    throw new DeserializationError(
      'Could not deserialize schedule',
      response,
    );
  }
}

export async function retrieveSchedules() {
  const response = await request(
    getUrl(SCHEDULES_PATH),
    methods.get,
  );
  try {
    return parseSchedules(response.schedules);
  } catch {
    throw new DeserializationError(
      'Could not deserialize schedules',
      response,
    );
  }
}

export async function retrieveSchedule({
  scheduleId
} : { scheduleId : number }) {
  const response = await request(
    getUrl(`${SCHEDULES_PATH}${scheduleId}`),
    methods.get,
  );

  try {
    return parseSchedule(response.schedule);
  } catch {
    throw new DeserializationError(
      'Could not deserialize schedule',
      response,
    );
  }
}

export async function updateSchedule({ schedule } : { schedule : Schedule }) {
  const response = await request(
    getUrl(`${SCHEDULES_PATH}${schedule.id}`),
    methods.put,
    { schedule },
  );

  try {
    return parseSchedule(response.schedule);
  } catch {
    throw new DeserializationError(
      'Could not deserialize schedule',
      response,
    );
  }
}

export async function deleteSchedule({ schedule } : ScheduleProps) {
  const response = await request(
    getUrl(`${SCHEDULES_PATH}${schedule.id}`),
    methods.delete,
  );

  return !!response?.success;
}

export async function addScheduleToService(
  { service, schedule } : { service : Service, schedule : Schedule },
) {
  const response = await request(
    getUrl(`${SCHEDULES_PATH}${schedule.id}/${SERVICES_PATH}${service.id}`),
    methods.post,
  );

  return !!response;
}

export async function removeScheduleFromService(
  { service, schedule } : { service : Service, schedule : Schedule },
) {
  const response = await request(
    getUrl(`${SCHEDULES_PATH}${schedule.id}/${SERVICES_PATH}${service.id}`),
    methods.delete,
  );

  return !!response;
}
