import styled from '@emotion/styled'

import { Link } from '#mrktbox';

import { Theme } from '#types'

interface IconLinkStyle {
  theme? : Theme;
  margin? : string;
}

const IconLink = styled(Link)<IconLinkStyle>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  margin: ${(props) => props.margin || '0'};
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.color || props.theme.buttons.colours.header.colour};

  &:hover {
    color: ${(props) =>
      props.color || props.theme.buttons.colours.headerHover.colour};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      color: ${(props) =>
        props.color || props.theme.buttons.colours.header.colour};
    }
  }
`;

export default IconLink;
