import React, { useCallback, useState, useEffect } from 'react';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';
import { useAdjustments } from '#mrktbox';
import { Product } from '#mrktbox/types';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';
import useCatalogue from '#hooks/useCatalogue';

import Body from '#materials/Body';
import Heading from '#materials/Heading';

import ProductCardImage from '#components/products/ProductCardImage';
import ProductPriceCals from '#components/products/ProductPriceCals';

interface Style {
  theme? : Theme;
}

interface ProductCardButtonStyle extends Style {
  disabled? : boolean;
}

interface ProductCardContentStyle extends Style {
  hasBox : boolean;
}

interface ProductCardDescriptionStyle extends Style {
  open? : boolean;
}

const ProductCardButtonView = styled(Link)<ProductCardButtonStyle>`
  position: relative;
  flex-grow: 1;
  display: block;
  text-align: left;
  width: 100%;
  margin: 0 0 1.3rem;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  text-decoration: none;

  .compact & {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      margin: 0;
    }
  }

  &:disabled {
    opacity: 1;
  }
`;

const ProductCardContainer = styled.div<Style>`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ProductCardContent = styled.div<ProductCardContentStyle>`
  padding: 1.1rem 0 0;
  .compact & {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: 0.7rem 0 0;
    }
  }
`;

interface ProductCardInfoStyle extends Style {
  reverseStack? : boolean;
}

const ProductCardInfo = styled.div<ProductCardInfoStyle>`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.reverseStack ? 'flex-start' : 'flex-end')};

  padding: 0 1.3rem 0.5rem;

  .compact & {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      flex-direction: ${(props) => (props.reverseStack
        ? 'column-reverse'
        : 'column')};
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .centered & {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
`;

const ProductCardName = styled(Heading)`
  display: block;
  flex: 1 1 auto;
  padding: 0 0.5rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.big};

  .compact & {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.small};
    }
  }

  .centered & {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fonts.sizes.main};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fonts.sizes.small};
    }
  }
`;

const ProductCardPriceCals = styled.div<Style>`
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;

  .compact & {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      margin: 0.1rem 0 0;
      text-align: left;
    }
  }

  .centered & {
    width: 100%;
    margin: 0.4rem 0 0;
    text-align: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin: 0.1rem 0 0;
    }
  }
`;

const ProductAdjustment = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;

  opacity: 0.8;
  font-family: 'MRKTBOX Font';

  * {
    white-space: nowrap;
    margin: 0;
  }
`;

const ProductCardDescription = styled(Body)<ProductCardDescriptionStyle>`
  display: block;
  max-height: ${(props) => (props.open ? '100px' : '0')};
  margin: 0.8rem 0 0.5rem;
  opacity: ${(props) => (props.open ? '1' : '0')};
  font-size: ${(props) => props.theme.fonts.sizes.small};
  padding: 0 1.3rem;

  overflow: hidden;
  transition: opacity 0.75s ease-in-out, max-height 0.75s ease-in-out;

  .compact & {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      display: none;
    }
  }

  .centered & {
    display: none;
  }
`;

interface ProductCardButtonProps extends Style{
  product : Product;
  outOfStock? : boolean;
  onClick? : () => void;
}

function ProductCardButton({
  product,
  outOfStock,
  onClick,
} : ProductCardButtonProps) {
  const { theme } = useConfig();
  const {
    getProductAdjustments,
    calculateAdjustmentAbsolute,
    calculateAdjustmentRelative,
  } = useAdjustments();
  const {
    getProductSlug,
    formatAdjustment,
    calculatePrice,
    getCurrentSelections,
  } = useCatalogue();

  const getAdjustments = useCallback(() => (
    getProductAdjustments(product)
  ), [getProductAdjustments, product]);

  const [adjustments, setAdjustments] = useState(getAdjustments());

  const getPrice = useCallback(() => (
    calculatePrice({ product, adjustments : []})
  ), [product, calculatePrice]);
  const getAdjusted = useCallback(() => (
    calculatePrice({ product, adjustments })
  ), [product, adjustments, calculatePrice]);
  const getSelectionPrice = useCallback(() => {
    const selections = getCurrentSelections(product);
    return calculatePrice({ product, selections, relative : true })
  }, [product, getCurrentSelections, calculatePrice]);

  const [price, setPrice] = useState(getPrice());
  const [newPrice, setNewPrice] = useState(getAdjusted());
  const [selectionPrice, setSelectionPrice] = useState(getSelectionPrice());

  useEffect(() => setAdjustments(getAdjustments()), [getAdjustments]);
  useEffect(() => setPrice(getPrice()), [getPrice]);
  useEffect(() => setNewPrice(getAdjusted()), [getAdjusted]);
  useEffect(() => setSelectionPrice(getSelectionPrice()), [getSelectionPrice]);

  const onClickHandler = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  const hasAdjustment = price.amount !== newPrice.amount;
  const relative = Math.abs(adjustments.length
    ? calculateAdjustmentRelative(adjustments[0], product)
    : 0) * 100;

  const href = `/product/${getProductSlug(product)}`;
  const hasBox = theme?.cards.menuItem.bgColor !== 'transparent'

  return (
    <ProductCardButtonView to={href} onClick={onClickHandler}>
      <ProductCardContainer>
        <ProductCardImage product={product} outOfStock={outOfStock} />
        <ProductCardContent hasBox={hasBox}>
          <ProductCardInfo>
            <ProductCardName className="title">
              { product.name }
            </ProductCardName>
            <ProductCardPriceCals>
              <ProductPriceCals
                price={price}
                adjustment={selectionPrice}
                size="small"
                variant={hasAdjustment ? 'strike' : 'default'}
              />
            </ProductCardPriceCals>
          </ProductCardInfo>
          { !!adjustments.length && (
            <ProductCardInfo reverseStack style={{fontFamily : 'MRKTBOX Font'}}>
              <ProductAdjustment>
                <span>{ formatAdjustment(adjustments[0], product) }</span>
                <ProductPriceCals
                  price={calculateAdjustmentAbsolute(adjustments[0], product)}
                  size="main"
                  variant='deal'
                  absolute
                />
                { `(${relative % 1 ? relative.toFixed(1) : relative}%)` }
              </ProductAdjustment>
              { hasAdjustment && (
                <ProductPriceCals
                  price={newPrice}
                  size='main'
                  variant='deal'
                  style={selectionPrice.amount
                    ? { marginRight : '6rem' }
                    : {}}
                />
              ) }
            </ProductCardInfo>
          ) }
          <ProductCardDescription open={!!product.summary}>
            { product.summary }
          </ProductCardDescription>
        </ProductCardContent>
      </ProductCardContainer>
    </ProductCardButtonView>
  )
}

export default ProductCardButton;
