import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';

import { useNavigation, useSearchParams } from '#mrktbox';

import useConfig from '#hooks/useConfig';
import useSidebar from '#hooks/useSidebar';

import ScreenreaderTitle from '#materials/ScreenreaderTitle';

import HighlightedCatalogue from '#slices/HighlightedCatalogue';
import CategoriesList from '#slices/CategoriesList';

import Content from '#components/page/Content';
import Main from '#components/page/Main';
import PageHero from '#components/page/PageHero';
import Cart from '#components/cart/Cart';

const HeroImage = process.env.REACT_APP_CATALOGUE_HERO;
const HeroImageMobile = process.env.REACT_APP_CATALOGUE_HERO_MOBILE;

const MenuNewView = styled.div``;

const MenuAnnouncements = styled.div``;

const Catalogue = () => {
  const [urlParams] = useSearchParams();
  const { adjustSearchParams } = useNavigation();
  const { brand } = useConfig();
  const { openSidebar } = useSidebar();

  useEffect(() => {
    if (urlParams.get('openCart') !== 'true') return;
    openSidebar(<Cart />);
    adjustSearchParams({ openCart : undefined });
  }, [urlParams, openSidebar, adjustSearchParams]);

  const heroRef = useRef<HTMLDivElement>(null);

  const heroHeight = isMobile ? '24rem' : '36rem'
  const img = isMobile ? HeroImageMobile : HeroImage

  return (
    <>
      <Helmet>
        <title>Menu | {brand.title}</title>
      </Helmet>
      <Content>
        <Main>
          <ScreenreaderTitle>Menu</ScreenreaderTitle>
          <MenuNewView>
            <MenuAnnouncements ref={heroRef}>
              <PageHero
                height={heroHeight}
                imageUrl={img}
              />
            </MenuAnnouncements>
            <HighlightedCatalogue />
            <div id='categories'>
              <CategoriesList />
            </div>
          </MenuNewView>
        </Main>
      </Content>
    </>
  );
}

export default Catalogue;
