import React from 'react'
import styled from '@emotion/styled'

import { Theme } from '#types'

import { X } from '#materials/icons'

interface Style { theme? : Theme; }

const ButtonClearView = styled.button<Style>`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  border: 0.1rem solid ${(props) => props.theme.links.primary.colour};
  color: ${(props) => props.theme.links.primary.colour};
  opacity: 1;

  &:hover {
    opacity: 1;
  }

  * {
    position: relative;
    top: -0.1rem;
  }
`;

interface ClearButtonProps {
  ariaLabel? : string;
  onClick? : () => void;
  disabled? : boolean;
}

function ClearButton ({
  ariaLabel,
  onClick,
  disabled,
} : ClearButtonProps) {
  const handleClick = (evt : React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (!disabled && onClick) onClick();
  };

  return (
    <ButtonClearView
      type="button"
      aria-label={ariaLabel}
      onClick={handleClick}
      disabled={disabled}
    >
      <X />
    </ButtonClearView>
  );
}

export default ClearButton;
