import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Category } from '#mrktbox/types';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';

import ScreenreaderTitle from '#components/page/ScreenreaderTitle';
import Categories from '#components/categories/Categories';

interface Style {
  theme? : Theme;
}

const CategoryIndexView = styled.div<Style>`
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 0;
  }
`;

interface CategoryIndexProps {
  category : Category;
}

function CategoryIndex({ category } : CategoryIndexProps) {
  const [subCategories, setSubCategories] = useState<Category[] | null>(null);
  const { loadCategory, getSubcategories } = useCatalogue();

  const retrieve = useCallback(async () => {
    const subCategories = getSubcategories(category);
    setSubCategories(subCategories);
  }, [category, getSubcategories]);

  useEffect(() => { retrieve(); }, [retrieve]);
  useEffect(() => { loadCategory(category); }, [category, loadCategory]);

  return (
    <>
      <ScreenreaderTitle>{category?.name}</ScreenreaderTitle>
      <CategoryIndexView>
        { category &&
          <Categories category={category} isChild={false} />
        }
        { subCategories?.map((subCategory) => (
          <div key={subCategory.id} id={subCategory.name}>
            <Categories
              category={subCategory}
              isChild={true}
              currentCategory={category}
            />
          </div>
        ))}
      </CategoryIndexView>
    </>
  )
}

export default CategoryIndex;
