import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Product, Category } from '#mrktbox/types';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';

import Container from '#materials/Container';

import CategoryHeader from '#components/categories/CategoryHeader';
import ProductGrid from '#components/products/ProductGrid';
import ProductCard from '#components/products/ProductCard';

interface Style {
  theme? : Theme;
}

export const CategoriesView = styled.div<Style>`
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`;

interface CategoriesProps {
  category : Category;
  products? : Product[];
  isChild : boolean;
  currentCategory? : Category;
}

function Categories({ category, isChild, currentCategory } : CategoriesProps) {
  const { setLast, getCategorySlug, getCategoryProducts } = useCatalogue();

  const handleClick = useCallback(() => {
    setLast(`/category/${getCategorySlug(currentCategory ?? category)}`);
  }, [category, currentCategory, setLast, getCategorySlug])

  return (
    <CategoriesView>
      <Container>
        <CategoryHeader
          title={category.name}
          subtitle={category.description}
          id={getCategorySlug(category)}
          isChild={isChild}
        />
        { category.productIds.length > 0 &&
          <ProductGrid perRow={2}>
            { getCategoryProducts(category)?.map((product, index) => (
              <ProductCard
                product={product}
                onClick={handleClick}
                key={`${product.id}-${index}`}
              />
            ))}
          </ProductGrid>
        }
      </Container>
    </CategoriesView>
  );
}

export default Categories;
