import React from 'react';
import styled from '@emotion/styled';

import { Tag } from '#mrktbox/types';
import { Theme } from '#types';

import Preface from '#materials/Preface';

interface Style { theme? : Theme; }
interface ViewStyle extends Style {
  isCentered: boolean;
}

const ProductTagsAllergensView = styled.div<ViewStyle>`
  display: flex;
  justify-content: ${(props) => (props.isCentered ? 'center' : 'flex-start')};
  align-items: center;
  flex-wrap: wrap;
  margin: 0.8rem 0 0;

  .compact & {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      display: none;
    }
  }

  .centered & {
    justify-content: center;
  }

  span {
    display: block;
  }

  span + span {
    margin-left: 2rem;
  }
`;

interface TagStyle extends Style {
  size: keyof Theme['fonts']['sizes'];
}

const ProductTag = styled(Preface)<TagStyle>`
  font-size: ${(props) => props.theme.fonts.sizes[props.size]};
`;

interface ProductTagsAllergensProps {
  tags : Tag[];
  size? : keyof Theme['fonts']['sizes'];
  isCentered? : boolean;
  style? : any;
}

function ProductTagsAllergens({
  tags,
  size = 'xxSmall',
  isCentered = false,
  style,
} : ProductTagsAllergensProps) {
  if (!tags.length) return null;

  return (
    <ProductTagsAllergensView isCentered={isCentered} style={style}>
      { tags.map((tag) => (
        <ProductTag key={tag.id} size={size}>
          { tag.name }
        </ProductTag>
      )) }
    </ProductTagsAllergensView>
  );
}

export default ProductTagsAllergens;
