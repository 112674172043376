import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

import Container from '#materials/Container';

const BRAND = process.env.REACT_APP_BRAND;
const HOME = process.env.REACT_AAP_HOME;
const ABOUT = process.env.REACT_APP_ABOUT_LINK;
const TEAM = process.env.REACT_APP_TEAM_LINK;
const BLOG = process.env.REACT_APP_BLOG_LINK;
const GIFT_CARD = process.env.REACT_APP_GIFT_CARD_LINK;
const TERMS = process.env.REACT_APP_TERMS_LINK;
const PRIVACY = process.env.REACT_APP_PRIVACY_LINK;
const PRICING = process.env.REACT_APP_PRICING_LINK;
const LOGO = process.env.REACT_APP_FOOTER_LOGO;
const LOGO_ALT = process.env.REACT_APP_FOOTER_LOGO_ALT;
const LOGO_LINK = process.env.REACT_APP_FOOTER_LOGO_LINK;
const HELP_LINK = process.env.REACT_APP_HELP_LINK;

interface Style {
  theme : Theme;
}

interface FooterContainerStyle extends Style {
  tall : boolean;
}


const FooterView = styled.footer<Style>`
  position: relative;
  z-index: 1;
  width: 100%;
  color: ${(props) => props.theme.colours.light};
  background-color: ${(props) => props.theme.bgColours.dark};
`

const FooterContainer = styled.div<FooterContainerStyle>`
  height: ${(props) => props.tall ? '32rem' : '28rem'};
  padding: ${(props) => props.theme.layout.margin} 0
    ${(props) => props.theme.layout.padding};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    // padding: ${(props) => props.theme.layout.padding} 0;
    // align-items: flex-start;
  }
`

const FooterContent = styled.div<Style>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    // justify-content: flex-start;
  }
`

const FooterNav = styled.div``

const FooterLogo = styled.a<Style>`
  display: inline-block;
  height: 3.2rem;
  margin: 0 2rem 1rem 0;
  font-family: "MRKTBOX Font";
  color: white;
  font-size: 40px;
  text-decoration: none;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 2.2rem;
  }

  img {
    display: inline-block;
    width: auto;
    height: 100%;
    pointer-events: none;
  }
`

const FooterLinks = styled.ul<Style>`
  margin: 2rem 0 0;
  display: flex;
  justify-content: flex-start;
  font-size: ${(props) => props.theme.fonts.sizes.small};

  li {
    display: block;
    margin: 0 3rem 0 0;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin: 0 2rem 0 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    a {
      text-decoration: none;
      color: ${(props) => props.theme.links.light.colour};
    }

    a:hover,
    a:active,
    a:focus {
      color: ${(props) => props.theme.links.light.hover};
    }
  }
`

const FooterTerms = styled.nav<Style>`
  margin: 1rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 1rem 0 2rem;
  }

  ul li {
    float: left;
    margin: 1.5rem 2rem 0 0;
    &:last-child {
      margin-right: 0;
    }

    a {
      text-decoration: none;
      color: ${(props) => props.theme.links.light.colour};
    }

    a:hover,
    a:active,
    a:focus {
      color: ${(props) => props.theme.links.light.hover};
    }
  }
`

const CCorpLogo = styled.div<Style>`
  max-width: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    text-align: left;
  }

  img {
    height: 9rem;
    margin-bottom: 1rem;
  }
`

const Footer = ({ hasRouter = true }) => {
  const { brand, theme } = useConfig();

  return (
    <FooterView theme={theme} role="contentinfo">
      <Container theme={theme}>
        <FooterContainer theme={theme} tall={isMobile}>
          <FooterContent theme={theme}>
            <FooterNav>
              <FooterLogo
                theme={theme}
                href={HOME}
                rel="noopener noreferrer"
              >
                { BRAND }
              </FooterLogo>
              { hasRouter && (
                <FooterLinks theme={theme}>
                  { brand.url && (
                    <>
                      { ABOUT && (
                        <li>
                          <Link to={ABOUT}>About Us</Link>
                        </li>
                      ) }
                      { TEAM && (
                        <li>
                          <Link to={TEAM}>Our Team</Link>
                        </li>
                      ) }
                      { BLOG && (
                        <li>
                          <Link to={BLOG}>Blog</Link>
                        </li>
                      ) }
                    </>
                  ) }
                  { GIFT_CARD && (
                    <li>
                      <Link to={GIFT_CARD}>Gift Cards</Link>
                    </li>
                  ) }
                  { HELP_LINK && (
                    <li>
                      <Link to={HELP_LINK}>Help</Link>
                    </li>
                  ) }
                </FooterLinks>
              ) }
            </FooterNav>
            <FooterTerms theme={theme} aria-label="Legal Policies Navigation">
              <ul>
                {hasRouter && (
                  <>
                    { TERMS && (
                      <li>
                        <Link to={TERMS}>Terms & Conditions</Link>
                      </li>
                    ) }
                    { PRIVACY && (
                      <li>
                        <Link to={PRIVACY}>Privacy</Link>
                      </li>
                    ) }
                    { PRICING && (
                      <li>
                        <Link to={PRICING}>Pricing Policy</Link>
                      </li>
                    ) }
                  </>
                ) }
              </ul>
            </FooterTerms>
          </FooterContent>
          { LOGO && (
            <CCorpLogo theme={theme}>
              <a href={LOGO_LINK ?? '#'}>
                <img src={LOGO} alt={LOGO_ALT}/>
              </a>
            </CCorpLogo>
          ) }
        </FooterContainer>
      </Container>
    </FooterView>
  );
}

export default Footer;
