import styled from '@emotion/styled';

import { Theme } from '#types';

interface ContainerStyle {
  theme? : Theme;
}

const Container = styled.div<ContainerStyle>`
  width: 100%;
  margin: 0 auto;
  max-width: ${(props) => props.theme.layout.containerMaxWidth};
  padding: 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

export default Container;
