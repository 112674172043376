import React from 'react';

import ButtonStyled from '#materials/ButtonStyled';
import ButtonLink from '#materials/ButtonLink';
import ButtonOverlay from '#materials/ButtonOverlay';

interface SubscriptionButtonProps {
  period : number;
  disabled? : boolean;
  onClick? : (period : number) => void;
  children : React.ReactNode;
}

function SubscriptionButton({
  period,
  disabled,
  onClick,
  children,
} : SubscriptionButtonProps) {
  return (
    <ButtonStyled
      disabled={disabled}
      onClick={() => onClick?.(period)}
      size='small'
    >
      {children}
    </ButtonStyled>
  );
}

interface ProductSubscriptionOverlayProps {
  selected? : number;
  responsive? : boolean;
  showOneTime? : boolean;
  onSelect? : (period : number) => void;
  onCancel? : () => void;
}

function ProductSubscriptionOverlay({
  selected,
  responsive = true,
  showOneTime = false,
  onSelect,
  onCancel,
} : ProductSubscriptionOverlayProps) {
  return (
    <ButtonOverlay
      text="Set a subscription"
      responsive={responsive}
    >
      { [1, 2, 3, 4].map((period) => (
        <SubscriptionButton
          key={period}
          period={period}
          onClick={onSelect}
        >
          Every {period} Week{period > 1 ? 's' : ''}
        </SubscriptionButton>
      )) }
      <ButtonLink
        onClick={onCancel}
        colour='light'
      >
        Cancel
      </ButtonLink>
    </ButtonOverlay>
  );
}

export default ProductSubscriptionOverlay;
