import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { formats, formatDate, formatTime } from '#mrktbox/utils';

import { Theme } from '#types';

import Select from '#materials/Select';

import { calculateDate, calculateDates } from '#utils/date';

interface Style { theme? : Theme; }

const DateTimeSelects = styled.div<Style>`
  width: 100%;
  max-width: 24rem;
  margin: 3rem auto 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const DateTimeSelectView = styled.div<Style>`
  width: 47.5%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }

  select {
    padding-top: 0.5rem;
    font-size: ${(props) => props.theme.fonts.sizes.main};
  }
`;

interface DateTimeSelectProps {
  value : Date | null;
  options : Date[];
  onChange? : (value : Date | null) => void;
}

function DateTimeSelect({
  value,
  options,
  onChange,
} : DateTimeSelectProps) {
  const [date, setDate] = useState(value ? calculateDate(value) : null);
  const [time, setTime] = useState(value);
  const [dates, setDates] = useState(calculateDates(options));
  const [times, setTimes] = useState(options);

  const handleDateChange = useCallback((value : Date | null) => {
    if (!value) {
      if (onChange) onChange(null);
      return;
    }

    if (!date) {
      const dateTimes = options.filter(
        (option) => value.getTime() === calculateDate(option).getTime()
      );
      if (dateTimes[0] && onChange) onChange(dateTimes[0]);
      return;
    }

    const offset = value.getTime() - date.getTime();
    if (onChange) onChange(
      time ? new Date(time.getTime() + offset) : null
    );
  }, [options, date, time, onChange]);

  const handleTimeChange = useCallback((value : Date | null) => {
    if (onChange) onChange(value);
  }, [onChange]);

  const generateDateOptions = useCallback((option : Date | null) => ({
    value : option,
    key : option ? option.toISOString() : undefined,
    name : option ? formatDate(option, formats.short) : 'Select a date',
  }), []);

  const generateTimeOptions = useCallback((option : Date | null) => ({
    value : option,
    key : option ? option.toISOString() : undefined,
    name : option ? formatTime(option, formats.short) : 'Select a time',
  }), []);

  useEffect(() => {
    setDate(value ? calculateDate(value) : null);
    setTime(value);
  }, [value]);

  useEffect(() => {
    const newDate = value ? calculateDate(value) : null;
    setDates(calculateDates(options));

    const newTimes = newDate
      ? options
        .filter(option => newDate.getTime() === calculateDate(option).getTime())
        .sort((a, b) => a.getTime() - b.getTime())
      : [];
    setTimes(newTimes);
  }, [options, value]);

  return (
    <DateTimeSelects>
      <DateTimeSelectView>
        <Select<Date | null>
          id="order-date"
          label="Order Date"
          value={date}
          options={dates}
          onChange={handleDateChange}
          generateOption={generateDateOptions}
        />
      </DateTimeSelectView>
      <DateTimeSelectView>
        <Select
          id="order-time"
          label="Order Time"
          value={time}
          options={times}
          onChange={handleTimeChange}
          generateOption={generateTimeOptions}
        />
      </DateTimeSelectView>
    </DateTimeSelects>
  );
}

export default DateTimeSelect;
