import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

interface SkipLinkViewStyle {
  theme : Theme;
}

const SkipLinkView = styled.a<SkipLinkViewStyle>`
  position: absolute;
  z-index: 10000;

  bottom: 100%;
  left: ${(props) => props.theme.layout.paddingSmall};

  margin: 1rem 0 0 1rem;
  display: inline-block;
  line-height: 1;
  text-align: center;
  margin: 0;
  font-family: ${(props) => props.theme.buttons.sizes.default.family};
  font-weight: ${(props) => props.theme.buttons.sizes.default.weight};
  -webkit-font-smoothing: ${(props) =>
    props.theme.buttons.sizes.default.fontSmoothing};
  letter-spacing: ${(props) => props.theme.buttons.sizes.default.letterSpacing};
  text-transform: ${(props) => props.theme.buttons.sizes.default.textTransform};
  font-size: ${(props) => props.theme.buttons.sizes.default.fontSize};
  padding: ${(props) => props.theme.buttons.sizes.default.padding};
  border-style: solid;
  border-width: ${(props) => props.theme.buttons.sizes.default.borderWidth};
  border-radius: ${(props) => props.theme.buttons.sizes.default.borderRadius};
  color: ${(props) => props.theme.buttons.colours.primary.colour};
  background-color: ${(props) => props.theme.buttons.colours.primary.bgColour};
  border-color: ${(props) => props.theme.buttons.colours.primary.borderColour};

  &:focus {
    bottom: auto;
    top: calc(${(props) => props.theme.layout.navHeight} +
      ${(props) => props.theme.layout.paddingSmall});
    color: ${(props) => props.theme.buttons.colours.primary.colour};
    background-color: ${(props) => props.theme.buttons.colours.primary.bgColour};
    border-color: ${(props) => props.theme.buttons.colours.primary.borderColour};
  }
`

const SkipLink = ({ id = 'main' }) => {
  const { theme } = useConfig();

  const mainId = id.includes('#') ? id : `#${id}`

  return (
    <SkipLinkView theme={theme} href={mainId}>
      Skip to Main Content
    </SkipLinkView>
  );
}

export default SkipLink;
