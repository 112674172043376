import styled from '@emotion/styled';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

const LogoLight = process.env.REACT_APP_LOGO_LIGHT;

interface Style {
  theme : Theme;
}

const HeaderLogoLink = styled.a<Style>`
  display: block;
  max-width: 14rem;
  margin: 0.4rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    max-width: 13rem;
  }

  img {
    pointer-events: none;
  }
`

function HeaderLogo({ useLight = false }) {
  const { theme } = useConfig();
  // const { url, logo, logoLight } = useSelector(selectBrand)
  const logo = process.env.REACT_APP_LOGO;

  return (
    <HeaderLogoLink
      theme={theme}
      href={'/about'}
      rel="noopener noreferrer"
    >
      <img src={logo} alt="logo" />
    </HeaderLogoLink>
  )
}

export default HeaderLogo;
