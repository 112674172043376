import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

import Main from '#components/page/Main';
import PageTitle from '#components/page/PageTitle';

import ScreenreaderTitle from '#components/page/ScreenreaderTitle';

import OrderTypesSlice from '#slices/OrderTypes';

interface Style { theme? : Theme; }

const OrderTypeView = styled.div<Style>`
  padding: 0 ${(props) => props.theme.layout.padding};
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`;

function OrderTypes() {
  const { brand } = useConfig();

  return (
    <>
      <Helmet>
        <title>Order Types | {brand.title}</title>
      </Helmet>
      <Main>
        <ScreenreaderTitle>Order Types</ScreenreaderTitle>
        <OrderTypeView>
          <PageTitle
            title={'How would you like to order?'}
            subtitle={'Select an option below to begin setting up your order.'}
            textAlign='left'
            fullWidth
          />
          <OrderTypesSlice />
        </OrderTypeView>
      </Main>
    </>
  );
}

export default OrderTypes;
