import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import FormError from '#materials/FormError';

interface Style {
  theme? : Theme;
}

interface LabelViewStyle extends Style{
  hasIcon? : boolean;
  hasError? : boolean;
  hidePlaceholder? : boolean;
  isTextarea? : boolean;
}

interface LabelIconStyle extends Style {
  hasValue? : boolean;
  disabled? : boolean;
}

interface LabelTextStyle extends Style {
  hasValue? : boolean;
  hasError? : boolean;
  hasIcon? : boolean;
  isTextarea? : boolean;
}

const LabelView = styled('label')<LabelViewStyle>`
  position: relative;
  display: block;
  width: 100%;
  margin: 0 0 ${(props) => props.isTextarea ? '1rem' : '3rem'};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: ${(props) => props.theme.inputs.colour};

  input {
    ${(props) =>
      props.hasIcon ? `padding-left: ${props.theme.inputs.iconPadding};` : ''}
`;

const LabelIconView = styled.span<LabelIconStyle>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  top: ${(props) => props.theme.inputs.paddingTop};
  left: ${(props) => props.theme.inputs.iconLeft};
  width: 1.8rem;
  height: ${(props) => props.theme.inputs.selectSize};
  color: ${(props) =>
    props.hasValue
      ? props.theme.inputs.colour
      : props.theme.inputs.placeholderColour};
`;

const LabelIcon = styled.span`
  display: block;
  width: 1.8rem;
  height: 1.8rem;
`;

const LabelText = styled.span<LabelTextStyle>`
  position: absolute;
  top: ${(props) => props.isTextarea ? '-1.25rem' : '0'};
  left: ${(props) => props.isTextarea ? '1rem' : '0'};
  transition: all 0.2s cubic-bezier(0.17, 0.67, 0.12, 1);
  padding: ${(props) => props.theme.inputs.padding};
  padding-top: ${(props) => props.theme.inputs.paddingTop};
  padding-bottom: ${(props) => props.theme.inputs.paddingBottom};
  padding-left: ${(props) =>
    props.hasIcon
      ? props.theme.inputs.iconPadding
      : props.theme.inputs.paddingHorizontal};
  border-style: solid;
  border-color: transparent;
  border-width: ${(props) => props.theme.inputs.borderWidth};
  line-height: ${(props) => props.theme.inputs.lineHeight};
  font-size: ${(props) => props.theme.inputs.fontSize};
  font-family: ${(props) => props.theme.inputs.family};
  letter-spacing: ${(props) => props.theme.inputs.letterSpacing};
  text-transform: ${(props) => props.theme.inputs.textTransform};
  -webkit-font-smoothing: ${(props) => props.theme.inputs.fontSmoothing};
  color: ${(props) =>
    props.hasError
      ? props.theme.colours.error
      : props.theme.inputs.placeholderColour};

  ${(props) =>
    props.theme.inputs.bottomBorderOnly
      ? `border-width: 0; border-bottom-width: ${props.theme.inputs.borderWidth};`
      : ''}

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.inputs.fontSizeMobile};

    ${(props) =>
      props.hasValue
        ? `font-size: ${props.theme.inputs.label.fontSizeMobile};`
        : ''}
  }

  input:focus + &,
  textarea:focus + &,
  select:focus + & {
    cursor: default;

    ${(props) =>
      props.theme.inputs.showLabel && `
        transform: translate(
          ${props.isTextarea ? '-1rem' : '0'},
          ${props.isTextarea ? '-2.5rem' : props.theme.inputs.label.offset}
        );
        font-size: ${props.theme.inputs.label.fontSize};
      `}

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      ${(props) =>
        props.theme.inputs.showLabel &&
        `font-size: ${props.theme.inputs.label.fontSizeMobile};`}
    }
  }

  ${(props) =>
    props.hasValue
      ? `cursor: default;
        transform: translate(
          ${props.isTextarea ? '-1rem' : '0'},
          ${props.isTextarea ? '-2.5rem' : props.theme.inputs.label.offset}
        );
        font-size: ${props.theme.inputs.label.fontSize};
      `
      : ''}

  ${(props) =>
    props.hasValue && !props.theme.inputs.showLabel ? `display: none;` : ''}
`

const LabelRequired = styled.span<Style>`
  color: ${(props) => props.theme.colours.error};
`

interface LabelProps {
  htmlFor? : string;
  icon? : React.ReactNode;
  text? : React.ReactNode;
  required? : boolean;
  value? : string | number | boolean;
  errMsg? : string;
  showLabel? : boolean;
  disabled? : boolean;
  isTextarea? : boolean;
  style? : React.CSSProperties;
  className? : string;
  children? : React.ReactNode;
}

function Label({
  htmlFor,
  icon,
  text,
  required,
  value,
  errMsg,
  showLabel = true,
  disabled = false,
  isTextarea = false,
  className = undefined,
  children,
} : LabelProps) {
  return (
    <>
      <LabelView
        htmlFor={htmlFor}
        className={className}
        hidePlaceholder={!!text}
        hasError={!!errMsg}
        hasIcon={!!icon}
        isTextarea={isTextarea}
      >
        {icon && (
          <LabelIconView hasValue={!!value} disabled={disabled}>
            <LabelIcon>{icon}</LabelIcon>
          </LabelIconView>
        )}
        {children}
        {showLabel && (
          <LabelText
            hasValue={!!value}
            hasError={!!errMsg}
            hasIcon={!!icon}
            isTextarea={isTextarea}
          >
            {text}
            {required ? <LabelRequired>&nbsp;*</LabelRequired> : null}
          </LabelText>
        )}
        <FormError errMsg={errMsg} />
      </LabelView>
    </>
  );
}

export default Label;
