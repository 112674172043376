import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Product, Assembly } from '#mrktbox/types';
import { useOptions } from '#mrktbox';

import { Theme } from '#types';

import useRequests from '#hooks/useRequests';

import ProductOptionHeader from '#components/products/ProductOptionHeader';
import ProductOptionSquare from '#components/products/ProductOptionSquare';

interface Style { theme? : Theme; }

const ProductGroup = styled.div<Style>`
  margin: 0 0 3rem;

  &:last-of-type {
    margin: 0;
  }
`;

const ProductOptionsSquare = styled.div`
  margin: 2rem -0.6rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
`;

interface ProductOptionsProps {
  assembly : Assembly;
  selected : Product[];
  setSelected : (selected : Product[]) => void;
}

function ProductOption({
  assembly,
  selected,
  setSelected,
} : ProductOptionsProps) {
  const { time, isOrderOpen } = useRequests();
  const {
    getAssemblyProducts,
    getAssemblyCounts,
  } = useOptions();

  const [products, setProducts] = useState<Product[]>(
    getAssemblyProducts(assembly, time ?? new Date()),
  );

  const handleAddProduct = useCallback((product : Product) => () => {
    setSelected([...selected, product]);
  }, [selected, setSelected]);

  const handleRemoveProduct = useCallback((product : Product) => () => {
    const newSelection = [...selected];
    const index = newSelection.findIndex((p) => p.id === product.id);
    if (index > -1) newSelection.splice(index, 1);
    setSelected(newSelection);
  }, [selected, setSelected]);

  useEffect(() => {
    setProducts(getAssemblyProducts(assembly, time ?? new Date()));
  }, [assembly, time, getAssemblyProducts]);

  const { max, min } = getAssemblyCounts(assembly, time ?? new Date());

  return (
    <ProductGroup key={assembly.id} id={`assembly-${assembly.id}`} >
      <ProductOptionHeader assembly={assembly} count={selected.length} />
      <ProductOptionsSquare>
        { products.map((product) => (
          <ProductOptionSquare
            key={product.id}
            product={product}
            price={assembly.complimentary ? undefined : product.price}
            count={selected.filter(p => p.id ===product.id).length}
            disabled={(!!max && selected.length >= max)
              || !isOrderOpen()}
            disabledRemove={(products.length === 1) && (selected.length <= min)}
            addProduct={handleAddProduct(product)}
            removeProduct={handleRemoveProduct(product)}
          />
        ))}
      </ProductOptionsSquare>
    </ProductGroup>
  );
}

export default ProductOption;
