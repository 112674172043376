import React, { createContext, useCallback } from 'react';

import { Address, Area } from '#mrktbox/clerk/types';

import useData, {
  DataIndex,
  useLoad,
  useRefreshIndex,
  useRefresh,
  useRetrieveIndex,
  useRetrieve,
  useChange,
  useDelete,
} from '#mrktbox/clerk/hooks/useData';
import useAddressesAPI from '#mrktbox/clerk/hooks/api/useAddressesAPI';

export type AddressIndex = DataIndex<Address>;
export type AreaIndex = DataIndex<Area>;

const MAX_AGE = 1000 * 60 * 60;

const AreaContext = createContext({
  areas : null as DataIndex<Area> | null,
  loaded : false as boolean,
  load : () => {},
  createAddress : async (address : Address) => null as Address | null,
  refreshAddresses : async () => null as AddressIndex | null,
  refreshAddress : async (id : number) => null as Address | null,
  retrieveAddresses : async () => null as AddressIndex | null,
  retrieveAddress : async (id : number) => null as Address | null,
  deleteAddress : async (address : Address) => null as boolean | null,
  createArea : async (area : Area) => null as Area | null,
  refreshAreas : async () => null as AreaIndex | null,
  refreshArea : async (id : number) => null as Area | null,
  retrieveAreas : async () => null as AreaIndex | null,
  retrieveArea : async (id : number) => null as Area | null,
  updateArea : async (area : Area) => null as Area | null,
  deleteArea : async (area : Area) => null as boolean | null,
});

interface AreaProviderProps {
  children : React.ReactNode;
}

export function AreaProvider({ children } : AreaProviderProps) {
  const {
    createAddress,
    retrieveAddress,
    deleteAddress,
    createArea,
    retrieveAreas,
    retrieveArea,
    updateArea,
    deleteArea,
  } = useAddressesAPI();

  const {
    data : addresses,
    dispatch : dispatchAddresses,
    lastUpdated : lastUpdatedAddresses,
  } = useData<Address>({ storageKey : 'addresses' });

  const useCache = useCallback(
    async () => { return addresses || {} },
    [addresses],
  );

  const newAddress = useChange({
    dispatch : dispatchAddresses,
    change : createAddress,
  });
  // DEPR: retrieving full index too inefficient
  const refreshAddresses = useRefreshIndex({
    dispatch : dispatchAddresses,
    retrieve : useCache,
  });
  const refreshAddress = useRefresh({
    dispatch : dispatchAddresses,
    retrieve : retrieveAddress,
  });
  // DEPR: retrieving full index too inefficient
  const getAddresses = useRetrieveIndex({
    data : addresses,
    timestamp : lastUpdatedAddresses,
    maxAge : MAX_AGE,
    refresh : refreshAddresses,
  });
  const getAddress = useRetrieve({
    data : addresses,
    timestamp : lastUpdatedAddresses,
    maxAge : MAX_AGE,
    refresh : refreshAddress,
  });
  const removeAddress = useDelete({
    dispatch : dispatchAddresses,
    delete : deleteAddress,
  });

  const {
    data : areas,
    dispatch : dispatchAreas,
    lastUpdated,
  } = useData<Area>({ storageKey : 'areas' });

  const newArea = useChange({
    dispatch : dispatchAreas,
    change : createArea,
  });
  const refreshAreas = useRefreshIndex({
    dispatch : dispatchAreas,
    retrieve : retrieveAreas,
  });
  const refreshArea = useRefresh({
    dispatch : dispatchAreas,
    retrieve : retrieveArea,
  });
  const getAreas = useRetrieveIndex({
    data : areas,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshAreas,
  });
  const getArea = useRetrieve({
    data : areas,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshArea,
  });
  const amendArea = useChange({
    dispatch : dispatchAreas,
    change : updateArea,
  });
  const removeArea = useDelete({
    dispatch : dispatchAreas,
    delete : deleteArea,
  });

  const { load, loaded } = useLoad({ data : areas, loader : refreshAreas });

  const context = {
    areas,
    loaded,
    load,
    createAddress : newAddress,
    refreshAddresses,
    refreshAddress,
    retrieveAddresses : getAddresses,
    retrieveAddress : getAddress,
    deleteAddress : removeAddress,
    createArea : newArea,
    refreshAreas,
    refreshArea,
    retrieveAreas : getAreas,
    retrieveArea : getArea,
    updateArea : amendArea,
    deleteArea : removeArea,
  }

  return (
    <AreaContext.Provider value={context}>
      { children }
    </AreaContext.Provider>
  );
}

export default AreaContext;
