import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import Preface from '#materials/Preface';

interface Style { theme? : Theme; }
interface OrderSectionViewStyle extends Style { split? : boolean; }

const OrderSectionView = styled.div<OrderSectionViewStyle>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.split ? '2rem' : '3rem'} 0 0;
  border-top: ${(props) => props.theme.border.width} solid
    ${(props) => props.theme.border.colour};
  margin: 0 0 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &:first-of-type {
    border: 0;
    padding: 0;
  }

  &:last-of-type {
    margin: 0;
  }
`;

const OrderSectionLabel = styled.div<Style>`
  flex: 0 0 15rem;
  margin: 0 2rem 0 0;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex: 0 0 auto;
    margin: 0 0 0.5rem;
  }

  span {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fonts.sizes.xSmall};
    }
  }
`;

interface OrderSectionContentStyle extends Style {
  noTitle? : boolean;
}

const OrderSectionContent = styled.div<OrderSectionContentStyle>`
  width: 100%;
  flex: 1;

  p {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
    margin: 0.3em 0 0;

    &:first-of-type {
      font-size: ${(props) =>
        props.theme.fonts.sizes[props.noTitle ? 'small' : 'main']};
      color: ${(props) =>
        props.theme.fonts[props.noTitle ? 'body' : 'headings'].colour};
    }
  }
`;

interface OrderSectionProps {
  label? : string;
  noTitle? : boolean;
  children : React.ReactNode;
}

function OrderSection({
  label,
  noTitle = false,
  children,
} : OrderSectionProps) {

  return (
    <OrderSectionView split={label !== undefined}>
      { label && (
        <OrderSectionLabel>
          <Preface size="small" color="tertiary">
            { label }
          </Preface>
        </OrderSectionLabel>
      ) }
      <OrderSectionContent noTitle={noTitle}>{ children }</OrderSectionContent>
    </OrderSectionView>
  );
}

export default OrderSection;
