import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

/*
  credit: Scott Galloway
  source: https://codepen.io/scottloway/pen/zqoLyQ
*/

interface Style {
  theme? : Theme;
}

interface CheckmarkViewStyle extends Style {
  colour? : string;
}

const CheckmarkView = styled.span<CheckmarkViewStyle>`
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 1.9rem;
  height: 1.9rem;
  border-width: 0.2rem;
  border-style: solid;
  border-color: ${(props) => props.colour || props.theme.colours.success};
  background-color: ${(props) => props.colour || props.theme.colours.success};
`;

const CheckmarkTick = styled.span<Style>`
  display: block;

  &:after {
    position: absolute;
    left: 0.2rem;
    top: 0.8rem;
    height: 1rem;
    width: 0.5rem;
    border-right: 0.2rem solid ${(props) => props.theme.colours.light};
    border-top: 0.2rem solid ${(props) => props.theme.colours.light};
    content: '';
    opacity: 1;
    transform-origin: left top;
    transform: scaleX(-1) rotate(135deg);
  }
`;

interface CheckmarkProps {
  color? : string;
}

function Checkmark({ color } : CheckmarkProps) {
  return (
    <CheckmarkView color={color}>
      <CheckmarkTick />
    </CheckmarkView>
  );
}

export default Checkmark;
