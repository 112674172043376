import {
  Address,
  Customer,
  CreditCard,
  ServiceChannel,
  Location,
  TimeSlot,
  Product,
  Adjustment,
  AppliedAdjustment,
  LineItem,
  Fulfilment,
  Order,
  Hold,
  isLineItem,
  isFulfilment,
  isOrder,
  isHold,
} from '#mrktbox/clerk/types';

import { APIError, DeserializationError, methods } from '#mrktbox/clerk/api';
import { RequestOptions, getUrl, request } from '#mrktbox/clerk/api/mrktbox';

import { formats, parseDateTime, formatDateTime } from '#mrktbox/clerk/utils/date';

const LINEITEMS_PATH = 'orders/line-items/';

export function parseLineItem(lineItem : any) : LineItem {
  if (!isLineItem(lineItem))
    throw new TypeError('LineItem is not a line item')
  return lineItem;
}

export function parseLineItems(lineItems : any) {
  const parsedLineItems : { [id : number] : LineItem } = {};
  for (const lineItemId in lineItems) {
    if (typeof lineItemId !== 'string')
      throw new TypeError('LineItem id is not a string');

    const id = parseInt(lineItemId);
    if (isNaN(id)) throw new TypeError('LineItem id is not a number');

    parsedLineItems[id] = parseLineItem(lineItems[id]);
  }

  return parsedLineItems;
}

function parseFulfilment(fulfilment : any) : Fulfilment {
  if (!isFulfilment(fulfilment))
    throw new TypeError('Fulfilment is not a fulfilment');
  return fulfilment;
}

export function parseFulfilments(fulfilments : any) {
  const parsedFulfilments : { [id : number] : Fulfilment } = {};
  for (const fulfilmentId in fulfilments) {
    if (typeof fulfilmentId !== 'string')
      throw new TypeError('Fulfilment id is not a string');

    const id = parseInt(fulfilmentId);
    if (isNaN(id)) throw new TypeError('Fulfilment id is not a number');

    parsedFulfilments[id] = parseFulfilment(fulfilments[id]);
  }

  return parsedFulfilments;
}

function parseOrder(order : any) : Order {
  if (!isOrder(order)) throw new TypeError('Order is not an order');
  return order;
}

export function parseOrders(orders : any) {
  const parsedOrders : { [id : number] : Order } = {};
  for (const orderId in orders) {
    if (typeof orderId !== 'string')
      throw new TypeError('Order id is not a string');

    const id = parseInt(orderId);
    if (isNaN(id)) throw new TypeError('Order id is not a number');

    parsedOrders[id] = parseOrder(orders[id]);
  }

  return parsedOrders;
}

function parseHold(hold : any) {
  try {
    hold.start = parseDateTime(hold.start);
    hold.end = parseDateTime(hold.end);
  } catch {
    throw new DeserializationError(
      'Could not parse hold dates',
      hold,
    );
  }

  if (!isHold(hold)) throw new TypeError('Hold is not a hold');
  return hold;
}

function parseHolds(holds : any) {
  const parsedHolds : { [id : number] : Hold } = {};
  for (const holdId in holds) {
    if (typeof holdId !== 'string')
      throw new TypeError('Hold id is not a string');

    const id = parseInt(holdId);
    if (isNaN(id)) throw new TypeError('Hold id is not a number');

    parsedHolds[id] = parseHold(holds[id]);
  }

  return parsedHolds;
}

export function currentGuestCode() {
  return localStorage.getItem('guestCode');
}

export async function getGuestCode() {
  const guestCode = localStorage.getItem('guestCode');
  if (guestCode) return guestCode;

  const response = await request(
    getUrl(LINEITEMS_PATH + 'guest/'),
    methods.get,
  );

  if (response.guestCode) {
    localStorage.setItem('guestCode', response.guestCode);
  }

  return response.guestCode;
}

export async function claimGuestCode(
  { customer, token } : { customer : Customer, token? : string },
  options? : RequestOptions,
) {
  const guestCode = localStorage.getItem('guestCode');
  if (!guestCode) return null;

  const response = await request(
    getUrl(`${LINEITEMS_PATH}guest/${guestCode}/claim/${customer.id}`),
    methods.post,
    {},
    {
      ...options,
      token : token ?? options?.token ?? '' ,
    },
  );

  try {
    return parseLineItems(response.lineItems);
  } catch {
    throw new DeserializationError(
      'Could not parse line item list',
      response,
    );
  }
}

export async function createLineItem({
  lineItem,
  address,
  customer,
  serviceChannel,
  location,
  timeSlot,
  product,
} : {
  lineItem : LineItem,
  address? : Address | null,
  customer? : Customer | null,
  serviceChannel? : ServiceChannel | null,
  location? : Location | null,
  timeSlot? : TimeSlot | null,
  product? : Product,
}, options? : RequestOptions,
) {
  const path = options?.token
    ? 'orders/line-items/'
    : `orders/line-items/guest/${await getGuestCode()}/`;

  const clean = { ...lineItem } as any;
  delete clean.price

  const response = await request(
    getUrl(path),
    methods.post,
    { lineItem : {
      ...clean,
      ...(address !== undefined ? { addressId : address?.id ?? null } : {}),
      ...(customer ? { customerId : customer.id } : {}),
      ...(serviceChannel !== undefined
        ? { serviceChannelId : serviceChannel?.id ?? null }
        : {}),
      ...(location !== undefined ? { locationId : location?.id ?? null } : {}),
      ...(timeSlot !== undefined ? { timeSlotId : timeSlot?.id ?? null } : {}),
      ...(product ? { productId : product.id } : {}),
    } },
    options,
  );

  try {
    return {
      lineItem : parseLineItem(response.lineItem),
      orders : parseOrders(response.orders),
    };
  } catch {
    throw new DeserializationError(
      'Could not deserialize line item',
      response,
    );
  }
}

export async function retrieveLineItems(
  input : {},
  options? : RequestOptions,
) {
  const path = options?.token
    ? 'orders/line-items/'
    : `orders/line-items/guest/${await getGuestCode()}/`;

  const response = await request(
    getUrl(path),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseLineItems(response.lineItems);
  } catch {
    throw new DeserializationError(
      'Could not parse line item list',
      response,
    );
  }
}

export async function retrieveLineItem(
  { lineItemId } : { lineItemId : number },
  options? : RequestOptions,
) {
  const path = options?.token
    ? 'orders/line-items/'
    : `orders/line-items/guest/${await getGuestCode()}/`;

  const response = await request(
    getUrl(`${path}${lineItemId}/`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseLineItem(response.lineItem);
  } catch {
    throw new DeserializationError(
      'Could not parse line item',
      response,
    );
  }
}

export async function updateLineItem({
  lineItem,
  address,
  customer,
  serviceChannel,
  location,
  timeSlot,
} : {
  lineItem : LineItem,
  address? : Address | null,
  customer? : Customer | null,
  serviceChannel? : ServiceChannel | null,
  location? : Location | null,
  timeSlot? : TimeSlot | null,
}, options? : RequestOptions,
) {
  const path = options?.token
    ? 'orders/line-items/'
    : `orders/line-items/guest/${await getGuestCode()}/`;

  const response = await request(
    getUrl(`${path}${lineItem.id}`),
    methods.put,
    { lineItem : {
      ...lineItem,
      ...(address !== undefined ? { addressId : address?.id ?? null } : {}),
      ...(customer ? { customerId : customer.id } : {}),
      ...(serviceChannel !== undefined
        ? { serviceChannelId : serviceChannel?.id ?? null }
        : {}),
      ...(location !== undefined ? { locationId : location?.id ?? null } : {}),
      ...(timeSlot !== undefined ? { timeSlotId : timeSlot?.id ?? null } : {}),
    } },
    options,
  );

  try {
    return {
      lineItem : parseLineItem(response.lineItem),
      orders : parseOrders(response.orders),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse line item',
      response,
    );
  }
}

export async function deleteLineItem(
  { lineItem } : { lineItem : LineItem },
  options? : RequestOptions,
) {
  if (!lineItem.id) throw new APIError('LineItem id is required');

  const path = options?.token
    ? 'orders/line-items/'
    : `orders/line-items/guest/${await getGuestCode()}/`;

  const response = await request(
    getUrl(`${path}${lineItem.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return {
      lineItem : parseLineItem(response.lineItem),
      orders : parseOrders(response.orders),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse line item',
      response,
    );
  }
}

export async function bulkCreateLineItems({
  lineItems,
  address,
  customer,
  serviceChannel,
  location,
  timeSlot,
  product,
} : {
  lineItems : LineItem[],
  address? : Address | null,
  customer? : Customer | null,
  serviceChannel? : ServiceChannel | null,
  location? : Location | null,
  timeSlot? : TimeSlot | null,
  product? : Product,
}, options? : RequestOptions,
) {
  const path = options?.token
    ? 'orders/line-items/bulk/'
    : `orders/line-items/guest/${await getGuestCode()}/bulk/`;

  const clean = lineItems.map(lineItem => {
    const { price, ...rest } = lineItem;
    return rest;
  });

  const response = await request(
    getUrl(path),
    methods.post,
    { lineItems : clean.map(lineItem => ({
      ...lineItem,
      ...(address !== undefined ? { addressId : address?.id ?? null } : {}),
      ...(customer ? { customerId : customer.id } : {}),
      ...(serviceChannel !== undefined
        ? { serviceChannelId : serviceChannel?.id ?? null }
        : {}),
      ...(location !== undefined ? { locationId : location?.id ?? null } : {}),
      ...(timeSlot !== undefined ? { timeSlotId : timeSlot?.id ?? null } : {}),
      ...(product ? { productId : product.id } : {}),
    })) },
    options,
  );

  try {
    return {
      lineItems : parseLineItems(response.lineItems),
      orders : parseOrders(response.orders),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse line item list',
      response,
    );
  }
}

export async function bulkUpdateLineItems({
  lineItems,
  address,
  customer,
  serviceChannel,
  location,
  timeSlot,
} : {
  lineItems : LineItem[],
  address? : Address | null,
  customer? : Customer | null,
  serviceChannel? : ServiceChannel | null,
  location? : Location | null,
  timeSlot? : TimeSlot | null,
}, options? : RequestOptions,
) {
  const path = options?.token
    ? 'orders/line-items/bulk/'
    : `orders/line-items/guest/${await getGuestCode()}/bulk/`;

  if (lineItems.some(lineItem => !lineItem.id)) {
    throw new APIError('LineItem id is required');
  }

  const clean = lineItems.map(lineItem => {
    const { price, ...rest } = lineItem;
    return rest;
  });

  const response = await request(
    getUrl(path),
    methods.put,
    { lineItems : clean.reduce((acc, lineItem) => {
      if (!lineItem.id) return acc;
      return {
        ...acc,
        [lineItem.id] : {
          ...lineItem,
          ...(address !== undefined ? { addressId : address?.id ?? null } : {}),
          ...(customer ? { customerId : customer.id } : {}),
          ...(serviceChannel !== undefined
            ? { serviceChannelId : serviceChannel?.id ?? null }
            : {}),
          ...(location !== undefined
            ? { locationId : location?.id ?? null }
            : {}),
          ...(timeSlot !== undefined
            ? { timeSlotId : timeSlot?.id ?? null }
            : {}),
        }
      }
    }, {}) },
    options,
  );

  try {
    return {
      lineItems : parseLineItems(response.lineItems),
      orders : parseOrders(response.orders),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse line item list',
      response,
    );
  }
}

export async function bulkDeleteLineItems({
  lineItems,
} : {
  lineItems : LineItem[],
}, options? : RequestOptions) {
  const path = options?.token
    ? 'orders/line-items/bulk/'
    : `orders/line-items/guest/${await getGuestCode()}/bulk/`;

  const lineItemIds = lineItems.map(lineItem => lineItem.id)
    .filter(id => id !== undefined) as number[];

  if (lineItemIds.length !== lineItems.length) {
    throw new APIError('LineItem id is required');
  }

  const response = await request(
    getUrl(path, lineItemIds.map(id => ['id', id.toString()])),
    methods.delete,
    undefined,
    options,
  );

  try {
    return {
      lineItems : parseLineItems(response.lineItems),
      orders : parseOrders(response.orders),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse line item list',
      response,
    );
  }
}

export async function retrieveOrders(
  input : {},
  options? : RequestOptions,
) {
  if (!options?.token) return {};

  const response = await request(
    getUrl('orders/'),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseOrders(response.orders);
  } catch {
    throw new DeserializationError(
      'Could not parse order list',
      response,
    );
  }
}

export async function retrieveOrder(
  { orderId } : { orderId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`orders/${orderId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseOrder(response.order);
  } catch {
    throw new DeserializationError(
      'Could not parse order',
      response,
    );
  }
}

export async function addAdjustmentToOrder({
  order,
  adjustment,
} : {
  order : Order,
  adjustment : Adjustment,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(adjustment.id
      ? `orders/${order.id}/adjustments/${adjustment.id}`
      : `orders/${order.id}/adjustments/`),
    methods.post,
    adjustment.id
      ? {}
      : {
        amount : adjustment.currency?.amount ?? null,
        name : adjustment.name,
        factor : adjustment.factor,
      },
    options,
  );

  try {
    return parseOrder(response.order);
  } catch {
    throw new DeserializationError(
      'Could not parse order',
      response,
    );
  }
}

export async function removeAdjustmentFromOrder({
  order,
  appliedAdjustment,
} : {
  order : Order,
  appliedAdjustment : AppliedAdjustment,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`orders/${order.id}/adjustments/${appliedAdjustment.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseOrder(response.order);
  } catch {
    throw new DeserializationError(
      'Could not parse order',
      response,
    );
  }
}

export async function payOrders(
  { orders, card, token } : {
    orders : Order[],
    card? : CreditCard,
    token? : string,
  },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl('orders/pay/'),
    methods.post,
    {
      orderIds : orders.map(order => order.id),
      ...(card?.id && { cardId : card.id }),
      ...(token && { token }),
    },
    options,
  );

  try {
    return parseOrders(response.orders);
  } catch {
    throw new DeserializationError(
      'Could not parse order list',
      response,
    );
  }
}

export async function createFulfilment(
  { fulfilment } : { fulfilment : Fulfilment },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl('orders/fulfilments/'),
    methods.post,
    { fulfilment },
    options,
  );

  try {
    return {
      fulfilment : parseFulfilment(response.fulfilment),
      order : parseOrder(response.order),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse fulfilment',
      response,
    );
  }
}

export async function updateFulfilment(
  { fulfilment } : { fulfilment : Fulfilment },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`orders/fulfilments/${fulfilment.id}`),
    methods.put,
    { fulfilment },
    options,
  );

  try {
    return {
      fulfilment : parseFulfilment(response.fulfilment),
      order : parseOrder(response.order),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse fulfilment',
      response,
    );
  }
}

export async function deleteFulfilment(
  { fulfilment } : { fulfilment : Fulfilment },
  options? : RequestOptions,
) {
  if (!fulfilment.id) throw new APIError('Fulfilment id is required');

  const response = await request(
    getUrl(`orders/fulfilments/${fulfilment.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return {
      fulfilment : parseFulfilment(response.fulfilment),
      order : parseOrder(response.order),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse fulfilment',
      response,
    );
  }
}

export async function bulkCreateFulfilments (
  { lineItems } : { lineItems : LineItem[] },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl('orders/fulfilments/bulk/'),
    methods.post,
    { lineItemIds : lineItems.map(lineItem => lineItem.id) },
    options,
  );

  try {
    return {
      fulfilments : parseFulfilments(response.fulfilments),
      orders : parseOrders(response.orders),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse order list',
      response,
    );
  }
}

export async function bulkUpdateFulfilments (
  { fulfilments } : { fulfilments : Fulfilment[] },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl('orders/fulfilments/bulk/'),
    methods.put,
    { fulfilments : fulfilments.reduce((acc, fulfilment) => {
      if (!fulfilment.id) return acc;
      return {
        ...acc,
        [fulfilment.id] : fulfilment
      }
    }, {}) },
    options,
  );

  try {
    return {
      fulfilments : parseFulfilments(response.fulfilments),
      orders : parseOrders(response.orders),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse order list',
      response,
    );
  }
}

export async function bulkDeleteFulfilments (
  { fulfilments } : { fulfilments : Fulfilment[] },
  options? : RequestOptions,
) {
  const fulfilmentIds = fulfilments.map(fulfilment => fulfilment.id)
    .filter(id => id !== undefined) as number[];

  if (fulfilmentIds.length !== fulfilments.length) {
    throw new APIError('Fulfilment id is required');
  }

  const response = await request(
    getUrl(
      'orders/fulfilments/bulk/',
      fulfilmentIds.map(id => ['id', id.toString()])
    ),
    methods.delete,
    undefined,
    options,
  );

  try {
    return {
      fulfilments : parseFulfilments(response.fulfilments),
      orders : parseOrders(response.orders),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse order list',
      response,
    );
  }
}

export async function addAdjustmentToFulfilment({
  fulfilment,
  adjustment,
} : {
  fulfilment : Fulfilment,
  adjustment : Adjustment,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(adjustment.id
      ? `orders/fulfilments/${fulfilment.id}/adjustments/${adjustment.id}`
      : `orders/fulfilments/${fulfilment.id}/adjustments/`),
    methods.post,
    adjustment.id
      ? {}
      : {
        amount : adjustment.currency?.amount ?? null,
        name : adjustment.name,
        factor : adjustment.factor,
      },
    options,
  );

  try {
    return parseOrder(response.order);
  } catch {
    throw new DeserializationError(
      'Could not parse fulfilment',
      response,
    );
  }
}

export async function removeAdjustmentFromFulfilment({
  fulfilment,
  appliedAdjustment,
} : {
  fulfilment : Fulfilment,
  appliedAdjustment : AppliedAdjustment,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`orders/fulfilments/${fulfilment.id}/adjustments/${appliedAdjustment.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseOrder(response.order);
  } catch {
    throw new DeserializationError(
      'Could not parse fulfilment',
      response,
    );
  }
}

export async function createHold(
  { hold } : { hold : Hold },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl('orders/holds/'),
    methods.post,
    {
      hold : {
        ...hold,
        start : formatDateTime(hold.start, formats.iso),
        end : formatDateTime(hold.end, formats.iso),
      }
    },
    options,
  );

  try {
    return {
      hold : parseHold(response.hold),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse hold',
      response,
    );
  }
}

export async function retrieveHolds(
  input : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl('orders/holds/'),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseHolds(response.holds);
  } catch {
    throw new DeserializationError(
      'Could not parse hold list',
      response,
    );
  }
}

export async function retrieveHold(
  { holdId } : { holdId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`orders/holds/${holdId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseHold(response.hold);
  } catch {
    throw new DeserializationError(
      'Could not parse hold',
      response,
    );
  }
}

export async function deleteHold(
  { hold } : { hold : Hold },
  options? : RequestOptions,
) {
  if (!hold.id) throw new APIError('Hold id is required');

  const response = await request(
    getUrl(`orders/holds/${hold.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return {
      hold : parseHold(response.hold),
    }
  } catch {
    throw new DeserializationError(
      'Could not parse hold',
      response,
    );
  }
}
