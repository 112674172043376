import styled from '@emotion/styled';
import { Theme } from '#types';

interface Style {
  theme? : Theme;
  size? : keyof Theme['fonts']['sizes'];
}

const Body = styled.span<Style>`
  ${({ theme, size }) => `
    font-family: ${theme.fonts.body.family};
    font-weight: ${theme.fonts.body.weight};
    letter-spacing: ${theme.fonts.body.letterSpacing};
    line-height: ${theme.fonts.body.lineHeight};
    text-transform: ${theme.fonts.body.textTransform};
    -webkit-font-smoothing: ${theme.fonts.body.fontSmoothing};
    color: ${theme.fonts.body.colour};
    ${size ? `font-size: ${theme.fonts.sizes[size]};` : ''}
  `}
`;

export default Body;