import { ResponseStatus, ResponseWithReturns } from '#mrktbox/clerk/types';


export const responseStatus = {
  success : 'success',
  bad : 'bad',
  unauthorized : 'unauthorized',
  notFound : 'notFound',
  conflict : 'conflict',
  error : 'error',
} as { [key : string] : ResponseStatus };

export function noResponse() { return { status : responseStatus.error } }

export function responseResource<T>(response : ResponseWithReturns<T>) {
  return response[0];
}
