import styled from '@emotion/styled'
import { Theme } from '#types';

interface Style {
  theme? : Theme;
  size? : keyof Theme['fonts']['sizes'];
  colour? : keyof Theme['colours'];
}

const Preface = styled.span<Style>`
  ${({ theme, size, colour }) => `
  font-family: ${theme.fonts.preface.family};
  font-weight: ${theme.fonts.preface.weight};
  letter-spacing: ${theme.fonts.preface.letterSpacing};
  line-height: ${theme.fonts.preface.lineHeight};
  text-transform: ${theme.fonts.preface.textTransform};
  -webkit-font-smoothing: ${theme.fonts.preface.fontSmoothing};
  font-size: ${size ? theme.fonts.sizes[size] : theme.fonts.preface.fontSize};
  color: ${theme.colours[colour || 'primary']};
  `}
`;

export default Preface