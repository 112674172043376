import { useCallback, useRef } from 'react';

function useLoading<LT = any, CT = any>() {
  const loadingPromise = useRef<{
    [key : string | number] : Promise<[LT, CT?]> | null;
  }>({});

  const load = useCallback(
    async <LR extends LT, CR extends CT>(
      loader : () => Promise<LR>,
      callback? : (output : LR) => Promise<CR>,
      key : string = '',
    ) : Promise<[LT, CT?]> => {
      const current = loadingPromise.current[key] ?? null;
      if (current !== null) {
        return await current
      }

      async function loadAndCallback(
        loader : () => Promise<LR>,
        callback? : (output : LR) => Promise<CR>,
      ) : Promise<[LT, CT?]> {
        const result = await loader();

        const callbackResult = callback ? await callback(result) : undefined;
        return [result, callbackResult];
      }
      const promise = loadAndCallback(
        loader,
        callback,
      );

      loadingPromise.current[key] = promise;
      const results = await promise;
      loadingPromise.current[key] = null;

      return results;
    },
    [],
  );

  const splitLoad = useCallback(async (
    keys : (string | number)[],
    loader : (keys : (string | number)[]) =>
      Promise<{ [key : string | number] : LT } | null>,
    callback? : (output : { [key : string] : LT } | null) => {},
  ) : Promise<{ [key : string] : LT }> => {
    const pending = keys.filter(
      key => loadingPromise.current[key] !== null
        && loadingPromise.current[key] !== undefined,
    );
    const required = keys.filter(
      key => loadingPromise.current[key] === null
        || loadingPromise.current[key] === undefined,
    );

    async function buildFromPending(key : string | number) {
      const result = await loadingPromise.current[key];
      if (result === null || result === undefined) return {};
      return { [key] : result[0] };
    }

    const pendingPromises = pending.map(key => buildFromPending(key));
    const newPromise = required.length > 0 ? loader(required) : null;
    loadingPromise.current = {
      ...loadingPromise.current,
      ...required.reduce((acc, key) => ({ ...acc, [key] : newPromise }), {}),
    };

    if (newPromise) {
      newPromise.then((out) => {
        if (callback) callback(out);
        return out;
      });
    }
    const results = (await Promise.all([
      newPromise,
      ...pendingPromises,
    ])).reduce((acc, results) => ({ ...acc, ...results }), {});
    if (results === null) return {};

    return Object.entries(results).reduce(
      (acc : { [key : string] : LT }, [key, value]) => ({
        ...acc,
        ...((key in keys) && { [key] : value }),
      }),
      {},
    );
  }, []);

  return {
    load,
    splitLoad,
  };
}

export default useLoading;
