import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }
interface SelectViewStyle extends Style {
  hasError? : boolean;
  showLabel? : boolean;
}

const SelectOnlyView = styled.span<Style>`
  position: relative;
  display: block;
  flex-grow: 1;
  border-radius: ${(props) => props.theme.inputs.radius};
  background-color: ${(props) => props.theme.inputs.bgColour};
  box-shadow: ${(props) => props.theme.inputs.boxShadow};
`;

const SelectView = styled.select<SelectViewStyle>`
  position: relative;
  z-index: 3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 100%;
  padding: ${(props) => props.theme.inputs.padding};
  border-style: ${(props) => props.theme.inputs.borderStyle};
  border-color: ${(props) => props.theme.inputs.borderColour};
  border-width: ${(props) => props.theme.inputs.borderWidth};
  border-radius: ${(props) => props.theme.inputs.radius};
  line-height: ${(props) => props.theme.inputs.lineHeight};
  font-family: ${(props) => props.theme.inputs.family};
  font-size: ${(props) => props.theme.inputs.fontSize};
  font-weight: ${(props) => props.theme.inputs.weight};
  letter-spacing: ${(props) => props.theme.inputs.letterSpacing};
  text-transform: ${(props) => props.theme.inputs.textTransform};
  -webkit-font-smoothing: ${(props) => props.theme.inputs.fontSmoothing};
  color: ${(props) => props.theme.inputs.colour};
  transition: ${(props) => props.theme.links.transition};
  background-color: transparent;

  ${(props) =>
    props.theme.inputs.bottomBorderOnly
      ? `
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    border-width: 0;
    border-bottom-width: ${props.theme.inputs.borderWidth};
    padding-top: ${props.theme.inputs.paddingTop};
    padding-bottom: ${props.theme.inputs.paddingBottom};
  `
      : ''}

  ${(props) =>
    props.hasError ? `border-color: ${props.theme.colours.error};` : ''}

  ${(props) =>
    props.showLabel
      ? `padding-top: ${props.theme.inputs.paddingTopActive};
    padding-bottom: ${props.theme.inputs.paddingBottomActive};`
      : ''}

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.inputs.fontSizeMobile};
  }

  &::selection {
    color: ${(props) => props.theme.inputs.bgColour};
    background-color: ${(props) => props.theme.inputs.colour};
  }

  &:active,
  &:focus {
    color: ${(props) => props.theme.inputs.colourFocus};
    background-color: ${(props) => props.theme.inputs.bgColourFocus};
    border-color: ${(props) => props.theme.inputs.borderColourFocus};

    ${(props) =>
      props.hasError ? `border-color: ${props.theme.colours.error};` : ''}

    ${(props) => !props.theme.inputs.showOutline && `outline: none;`}
  }

  &:disabled,
  &:read-only {
    cursor: default;
    opacity: 1;
    color: ${(props) => props.theme.inputs.colour};
    border-color: ${(props) => props.theme.inputs.borderColour};
    background-color: ${(props) => props.theme.inputs.bgColour};
    background-color: transparent;
  }
`;

const SelectArrowView = styled.span<Style>`
  display: block;
  position: absolute;
  z-index: 2;
  bottom: ${(props) => props.theme.inputs.selectPaddingBottom};
  right: ${(props) => props.theme.inputs.paddingHorizontal};
  padding: 0 0.2rem;
  height: ${(props) => props.theme.inputs.selectSize};
  background-color: ${(props) => props.theme.inputs.bgColour};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    height: ${(props) => props.theme.inputs.selectSizeMobile};
  }
`;

const SelectArrowContainer = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectArrow = styled.span<Style>`
  display: block;
  content: ' ';
  border-bottom-width: 0.2rem;
  border-bottom-style: solid;
  border-right-width: 0.2rem;
  border-right-style: solid;
  transform: scale(1) rotate(45deg);
  border-color: ${(props) => props.theme.inputs.colour};
  width: 0.8rem;
  height: 0.8rem;
  margin-top: -0.6rem;
`

interface SelectProps<T> {
  id? : string;
  label? : string;
  showLabel? : boolean;
  value : T;
  options : T[];
  onChange? : (value : T) => void;
  generateOption : (option : T) => {
    value : T;
    key? : string | number;
    name : string;
    disabled? : boolean;
  };
  disabled? : boolean;
}

function Select<T>({
  id,
  label,
  showLabel,
  value,
  options : opts,
  onChange,
  generateOption,
  disabled,
} : SelectProps<T>) {
  const [inputOption, setInputOption] = useState(generateOption(value));
  const [options, setOptions] = useState(opts.map(generateOption));

  const handleChange = useCallback(
    (e : React.ChangeEvent<HTMLSelectElement>) => {
      const val = e.target.value;
      const option = options.find((o) => o.key === val);

      if (option) {
        setInputOption(option);
        if (onChange) {
          onChange(option.value);
        }
      }
    },
    [onChange, options]
  );

  useEffect(() => {
    setInputOption(generateOption(value));
  }, [value, generateOption]);

  useEffect(() => {
    setOptions(opts.map(generateOption));
  }, [opts, generateOption]);

  return (
    <SelectOnlyView>
      <SelectView
        aria-label={label}
        id={id}
        value={inputOption.key}
        onChange={handleChange}
        disabled={disabled}
        showLabel={!!label && showLabel}
      >
        {options ? (
          options.map((option, index) => (
            <option
              key={`${option.value}-${index}`}
              value={option.key}
              disabled={option.disabled || false}
            >
              {option.name}
            </option>
          ))
        ) : (
          <option>No Options Available</option>
        )}
      </SelectView>
      <SelectArrowView>
        <SelectArrowContainer>
          <SelectArrow />
        </SelectArrowContainer>
      </SelectArrowView>
    </SelectOnlyView>
  );
}

export default Select;
