import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useModal from '#hooks/useModal';

import Heading from '#materials/Heading';
import ButtonStyled from '#materials/ButtonStyled';

import ModalView from '#components/modal/ModalView';
import ModalClose from '#components/modal/ModalClose';

interface Style { theme? : Theme; }

const SubscriptionView = styled(ModalView)`
  text-align: center;

  padding: 3.5rem 3.5rem 4rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 3rem ${(props) => props.theme.layout.paddingMobile};
  }
`;

const Title = styled.p<Style>`
  width: 100%;
  margin: 0 0 1rem;
  text-align: center;
  line-height: ${(props) => props.theme.lineHeight};
  font-size: ${(props) => props.theme.fonts.sizes.h3};
`;

const Preface = styled.p<Style>`
  white-space: pre-wrap;
  margin: 0 0 2rem;
  line-height: ${(props) => props.theme.lineHeight};
`;

const Buttons = styled.div<Style>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin-top: ${(props) => props.theme.layout.margin};

  * {
    white-space: nowrap;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: ${(props) => props.theme.layout.marginMobile};
  }
`;

interface SubscriptionChangeProps {
  title : string;
  prompt : string;
  selectTarget? : (target : 'this' | 'future') => Promise<boolean | void>
    | boolean
    | void;
}

function SubscriptionChange({
  title,
  prompt,
  selectTarget,
} : SubscriptionChangeProps) {
  const { closeModal } = useModal();
  const [processing, setProcessing] = useState(false);

  const handleThis = useCallback(async () => {
    setProcessing(true);
    const success = selectTarget ? (await selectTarget?.('this')) : false;
    setProcessing(false);
    if (success) closeModal();
  }, [selectTarget, closeModal]);

  const handleFuture = useCallback(async () => {
    setProcessing(true);
    const success = selectTarget ? (await selectTarget?.('future')) : false;
    setProcessing(false);
    if (success) closeModal();
  }, [selectTarget, closeModal]);

  return (
    <SubscriptionView>
      <ModalClose onClick={closeModal} />
      <Heading>
        <Title>{ title }</Title>
      </Heading>
      <Preface>{ prompt }</Preface>
      <Buttons>
        <ButtonStyled onClick={handleThis} disabled={processing}>
          This Order
        </ButtonStyled>
        <ButtonStyled onClick={handleFuture} disabled={processing}>
          Future Orders
        </ButtonStyled>
      </Buttons>
    </SubscriptionView>
  );
}

export default SubscriptionChange;
