import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

import Footer from '#components/footer/Footer';

interface ContentViewStyle {
  theme : Theme;
  maxWidth: string;
}

const ContentView = styled.div<ContentViewStyle>`
  label: ContentView;

  width: 100%;
  height: 100%;
  max-width: ${(props) => props.maxWidth};
  background-color: ${(props) => props.theme.bgColours.primary};
`;

interface ContentProps {
  maxWidth? : string;
  scrollTop? : boolean;
  hasRouter? : boolean;
  hasFooter? : boolean;
  children : React.ReactNode;
}

function Content({
  maxWidth = '100%',
  scrollTop = true,
  hasRouter = true,
  hasFooter = true,
  children,
} : ContentProps) {
  const { theme } = useConfig();

  useEffect(() => {
    if (scrollTop) window.scrollTo(0, 0)
  }, [scrollTop]);

  return (
    <ContentView theme={theme} maxWidth={maxWidth}>
      <>
        { children }
        { hasFooter && <Footer hasRouter={hasRouter} /> }
      </>
    </ContentView>
  );
}

export default Content;
