import React, { useEffect, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import styled from '@emotion/styled';

import { Product } from '#mrktbox/types';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';

import Heading from '#materials/Heading';
import Container from '#materials/Container';

import ProductCard from '#components/products/ProductCard';
import SeeMoreLink from '#components/products/SeeMoreLink';

import { scrollToId } from '#utils/scroll';

interface Style {
  theme? : Theme;
}

interface HighlightedCatalogueNavStyle extends Style {
  marginRight : string;
  fontSize : keyof Theme['fonts']['sizes'];
}

interface HighlightedCatalogueButtonViewStyle extends Style {
  isActive : boolean;
}

interface HighlightedCatalogeProductStyle extends Style {
  count : number;
}

const HighlightedCatalogueProduct = styled.div<HighlightedCatalogeProductStyle>`
  flex: ${(props) => (props.count >= 4 ? '1' : '0')} 0 31rem;
  padding: 1.5rem 0;
  margin-right: ${(props) => props.theme.layout.padding};

  @media (max-width: 1390px) {
    flex: 0 0 31rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex: 0 0 20rem;
    margin-right: ${(props) => props.theme.layout.paddingMobile};
  }

  &:first-of-type {
    margin-left: ${(props) => props.theme.layout.padding};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin-left: ${(props) => props.theme.layout.paddingMobile};
    }
  }

  & > div {
    height: 100%;
  }
`

const HighlightedCatalogueProducts = styled.div<Style>`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: auto;
  margin: 0 -${(props) => props.theme.layout.padding} -1.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 -${(props) => props.theme.layout.paddingMobile} -1.5rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const HighlightedCatalogueView = styled.div<Style>`
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`;

const HighlightedCatalogueHeader = styled.div<Style>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 0 0rem;
  }
`;

const HighlightedCatalogueNav = styled.div<HighlightedCatalogueNavStyle>`
  flex: 1;

  button {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      margin-right: ${(props) => props.marginRight}rem;

      span {
        font-size: ${(props) => props.theme.fonts.sizes[props.fontSize]};
      }
    }
  }

  button:last-of-type {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      margin-right: 0;
    }
  }
`;

const HighlightedCatalogueMore = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 0 0.2rem;
`;

const HighlightedCatalogueButtonView = styled.button<HighlightedCatalogueButtonViewStyle>`
  padding: 0 0 0.2rem;
  margin: 0 4rem 0 0;
  border-bottom: ${(props) => props.theme.border.width} solid transparent;
  border-color: ${(props) =>
    props.isActive ? props.theme.border.colour : 'transparent'};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 3rem 0 0;
  }

  span {
    font-weight: ${(props) =>
      props.theme.fonts[props.isActive ? 'headings' : 'body'].weight};
  }
`;

interface HighlightedCatalogueButtonProps {
  title : string,
  section : string,
  menuSection : string,
  onClick : () => void,
}

function HighlightedCatalogueButton({
  title,
  section,
  menuSection,
  onClick,
} : HighlightedCatalogueButtonProps) {
  return (
    <HighlightedCatalogueButtonView isActive={menuSection === section} onClick={onClick}>
      <HighlightedCatalogueTitle>{title}</HighlightedCatalogueTitle>
    </HighlightedCatalogueButtonView>
  )
}

const HighlightedCatalogueTitle = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.xBig};
  font-family: "MRKTBOX Font";
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.big};
  }
`;

function HighlightedCatalogue(){
  const {
    products,
    categories,
    getFeaturedCategory,
    getDealsCategory,
  } = useCatalogue();

  const [featuredProducts, setFeaturedProducts] = useState<Product[] | null>(null);
  const [productDeals, setProductDeals] = useState<Product[] | null>(null);

  const displayedKeys = ['FEATURED'];
  const [currentSection, setCurrentSection] = useState(displayedKeys[0]);
  const menuSlug = 'category' as string;

  const currentTitle = isBrowser
    ? ( currentSection.charAt(0).toUpperCase() +
      currentSection.slice(1).toLowerCase() )
    : '';
  const displayMore = currentSection.length > 4;
  const marginRight = displayedKeys.length > 2 ? '1.5' : '3';
  const fontSize = displayedKeys.length > 2 ? 'main' : 'big';

  const handleChangeSection = (section : string) => {
    setCurrentSection(section);
  }

  useEffect(() => {
    const featured = getFeaturedCategory();
    if (!featured || !products) return;

    setFeaturedProducts((Object.values(products).filter((product) => (
      product?.id && featured.productIds.includes(product.id)
    )) ?? []) as Product[]);
  },
  [
    products,
    categories,
    getFeaturedCategory,
  ]);

  useEffect(() => {
    const deals = getDealsCategory();
    if (!deals || !products) return;

    setProductDeals((Object.values(products).filter((product) => (
      product?.id && deals.productIds.includes(product.id)
    )) ?? []) as Product[]);
  },
  [
    products,
    categories,
    getDealsCategory,
  ]);

  if (productDeals?.length) displayedKeys.push('DEALS');

  return (
    <HighlightedCatalogueView className="compact">
      <Container>
        <HighlightedCatalogueHeader>
          <HighlightedCatalogueNav
            marginRight={marginRight}
            fontSize={fontSize}
          >
            { displayedKeys.map((section) => (
              <HighlightedCatalogueButton
                key={section}
                title={section} // TODO: capitalize
                section={section}
                menuSection={currentSection}
                onClick={() => handleChangeSection(section)}
              />
            ) ) }
            { isBrowser && (
              <HighlightedCatalogueButton
                title="Full Menu"
                section="FULL_MENU"
                menuSection={currentSection}
                onClick={() => scrollToId('categories')}
              />
            ) }
          </HighlightedCatalogueNav>
          <HighlightedCatalogueMore>
            {displayMore && (
              <SeeMoreLink
                text={`View All ${currentTitle}`}
                to={`${menuSlug}/${currentSection.toLowerCase()}`}
              />
            )}
          </HighlightedCatalogueMore>
        </HighlightedCatalogueHeader>
        <HighlightedCatalogueProducts>
          { (featuredProducts && currentSection === 'FEATURED') && (
            featuredProducts.map((product) => {
              return (
                <HighlightedCatalogueProduct count={1} key={product?.name}>
                  { product && <ProductCard product={product} /> }
                </HighlightedCatalogueProduct>
              )
            })
          )}
          { productDeals && currentSection === 'DEALS' && (
            productDeals.map((product : Product) => {
              return (
                <HighlightedCatalogueProduct count={1} key={product?.name}>
                  { product && <ProductCard product={product} /> }
                </HighlightedCatalogueProduct>
              )
            } )
          ) }
        </HighlightedCatalogueProducts>
      </Container>
    </HighlightedCatalogueView>
  );
}

export default HighlightedCatalogue;
