import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import Preface from '#materials/Preface';
import Text from '#materials/Text';

interface Style { theme? : Theme; }

const FormSectionView = styled.div<Style>`
  text-align: left;
  margin: 4rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0 0;
  }

  & > p {
    margin: 0.5em 0 0;
    @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
      font-size: ${(props) => props.theme.fonts.sizes.small};
    }
  }
`;

const FormSectionLabel = styled.div<Style>`
  div {
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fonts.sizes.xSmall};
    }
  }
`;

const FormSectionContent = styled.div`
  margin: 1.5rem 0 0;
`;

interface FormSectionProps {
  title? : string;
  subtitle? : string;
  children? : React.ReactNode;
  style? : React.CSSProperties;
}

function FormSection({
  title,
  subtitle,
  children,
  style,
} : FormSectionProps) {
  return (
    <FormSectionView style={style}>
      {title ? (
        <FormSectionLabel>
          <Preface as="div" size="small" color="tertiary">
            {title}
          </Preface>
        </FormSectionLabel>
      ) : null}
      {subtitle ? (
        <Text as="p" size="small">
          {subtitle}
        </Text>
      ) : null}
      <FormSectionContent>{children}</FormSectionContent>
    </FormSectionView>
  );
}

export default FormSection;
