import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';

export type IconElement = React.ReactElement<{
  icon? : React.ReactElement;
  size? : string;
  colour? : string;
}>;
export type ActionElement = React.ReactElement<{
  label : string;
  size? : string;
  variant? : string;
  colour? : string;
}>;

const TIMEOUT_DURATION = 3000; //ms

export interface Notification {
  message : string;
  key : string;
  count? : number;
  colour? : string;
  icon? : IconElement;
  actions? : ActionElement | ActionElement[];
}

interface NotificationContextReturn {
  open : boolean;
  close : () => void;
  closed : () => void;
  notification : Notification | null;
  createNotification : (notification : Notification) => void;
}

interface NotificationProviderProps {
  children : React.ReactNode;
}

const NotificationContext = createContext<NotificationContextReturn>({
  open: false,
  close: () => {},
  closed: () => {},
  notification: null,
  createNotification: () => {},
});

export function NotificationProvider({ children } : NotificationProviderProps) {
  const [open, setOpen] = useState(false);
  const [queue, setQueue] = useState<Notification[]>([]);
  const [notification, setNotification] = useState<Notification | null>(null);
  const [
    timer,
    setTimer,
  ] = useState<ReturnType<typeof setTimeout> | null>();
  const [context, setContext] = useState<NotificationContextReturn>({
    open,
    close: () => setOpen(false),
    closed: () => {},
    notification,
    createNotification: () => {},
  });

  const resetTimer = useCallback(() => {
    if (timer) clearTimeout(timer);
    setTimer(setTimeout(() => { setOpen(false); }, TIMEOUT_DURATION));
  }, [ timer, setOpen, setTimer ]);

  const openNext = useCallback(() => {
    resetTimer();
    const nextNotification = queue.shift();
    if (!nextNotification) {
      setNotification(null);
      return;
    }

    setNotification(nextNotification);
    setQueue(queue);
    setOpen(true);
  }, [
    queue,
    setNotification,
    setQueue,
    setOpen,
    resetTimer,
  ]);

  const close = useCallback(() => {
    if (timer) clearTimeout(timer);
    setTimer(null);
    setOpen(false);
  }, [ timer, setOpen, setTimer ]);

  const create = useCallback((newNotification : Notification) => {
    if (
      notification &&
      !queue.length &&
      newNotification.key === notification.key
    ) {
      setNotification({
        ...notification,
        count: (notification.count ?? 1) + (newNotification.count ?? 1),
      });
      if (!open) setOpen(true);
      resetTimer();
    } else if (
      queue.length > 0 &&
      newNotification.key === queue[queue.length - 1].key
    ) {
      const last = queue[queue.length - 1];
      setQueue([
        ...queue.slice(0, queue.length - 1),
        {
          ...queue[queue.length - 1],
          count : last.count ? last.count + 1 : 2,
        },
      ])
    } else if (!notification) {
      setNotification({ ...newNotification });
      setOpen(true);
      resetTimer();
    } else {
      setQueue([...queue, { ...newNotification }]);
    }
  }, [open, notification, queue, setNotification, setOpen, resetTimer]);

  useEffect(() => {
    setContext({
      open,
      close,
      closed: openNext,
      notification,
      createNotification: create,
    });
  }, [open, setOpen, notification, openNext, close, create, setContext]);

  return (
    <NotificationContext.Provider value={context}>
      { children }
    </NotificationContext.Provider>
  );
}

export default NotificationContext;
