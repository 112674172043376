import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useModal from '#hooks/useModal';

import Heading from '#materials/Heading';
import ButtonLink from '#materials/ButtonLink';
import DateTimeOptions from '#materials/DateTimeOptions';

import ModalView from '#components/modal/ModalView';
import ModalClose from '#components/modal/ModalClose';

interface Style { theme? : Theme; }

const SkipView = styled(ModalView)`
  width: 54rem;
  text-align: center;

  padding: 3.5rem 3.5rem 4rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 3rem ${(props) => props.theme.layout.paddingMobile};
  }
`;

const Title = styled.p<Style>`
  width: 100%;
  margin: 0 0 1rem;
  text-align: center;
  line-height: ${(props) => props.theme.lineHeight};
  font-size: ${(props) => props.theme.fonts.sizes.h3};
`

const Preface = styled.p<Style>`
  white-space: pre-wrap;
  margin: 0 0 2rem;
  line-height: ${(props) => props.theme.lineHeight};
`;

interface SkipOrderProps {
  orderName? : string;
  newTimes : { start : Date, end : Date | null, cutoff? : Date | null }[];
  onSelect : (time : Date) => Promise<boolean | void> | boolean | void;
  onCancel : () => void;
}

function SkipOrder({
  orderName,
  newTimes,
  onSelect,
  onCancel,
} : SkipOrderProps) {
  const { closeModal } = useModal();

  const [processing, setProcessing] = useState(false);

  const handleConfirm = useCallback(async (time : Date) => {
    setProcessing(true);
    const result = await onSelect(time);
    setProcessing(false);
    if (result !== false) closeModal();
  }, [onSelect, closeModal]);

  const text = `Defer ${orderName ?? 'this order'} until a later date? `;

  return (
    <SkipView>
      <ModalClose onClick={closeModal} />
      <Heading>
        <Title>Skip Subscription Order</Title>
      </Heading>
      <Preface>
        <p>{ text }</p>
        <p>All future orders will be rescheduled accordingly.</p>
      </Preface>
      <DateTimeOptions
        options={newTimes}
        selected={null}
        onSelect={handleConfirm}
        disabled={processing}
      />
      <ButtonLink onClick={onCancel}>
        Cancel
      </ButtonLink>
    </SkipView>
  );
}

export default SkipOrder;
