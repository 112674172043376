import styled from '@emotion/styled';

import { Theme } from '#types';

import useRequests from '#hooks/useRequests';

import { ShoppingBag } from '#materials/icons';
import IconButton from '#materials/IconButton';

interface Style {
  theme? : Theme;
}

interface CartIconStyle extends Style {
  isFilled? : boolean;
}

const CartIcon = styled.div<CartIconStyle>`
  position: relative;
  margin: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 -1rem 0 0.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 -1rem 0 0;
  }

  button {
    color: ${(props) => props.theme.buttons.colours.header.colour};

    svg {
      transition: ${(props) => props.theme.links.transition};
      fill: ${(props) =>
        props.isFilled
          ? props.theme.buttons.colours.cart.bgColor
          : 'transparent'};
    }

    &:hover,
    &:active {
      color: ${(props) => props.theme.buttons.colours.headerHover.colour};

      svg {
        // transform: scale(1.15);
        fill: ${(props) =>
          props.isFilled
            ? props.theme.buttons.colours.cartHover.bgColor
            : 'transparent'};
      }
    }
  }
`

interface CountStlye extends Style { fade? : boolean; }

const CartCount = styled.div<CountStlye>`
  position: absolute;
  z-index: 1;
  top: 0.5rem;
  right: 0.6rem;
  display: flex;
  ${(props) => props.fade && 'opacity: 0.6;'}
  justify-content: center;
  align-items: center;
  min-width: 1.7rem;
  height: 1.7rem;
  border-radius: 1.2rem;
  padding-bottom: 0.1rem;
  font-size: ${(props) => props.theme.fonts.sizes.xxSmall};
  color: ${(props) => props.theme.counts.alerts.colour};
  background-color: ${(props) => props.theme.counts.alerts.bgColour};
`

interface CartButtonProps {
  quantity? : number;
  filled? : boolean;
  onClick? : () => void;
}

function CartButton({
  quantity = 0,
  filled = false,
  onClick,
} : CartButtonProps) {
  const { waiting } = useRequests();

  return (
    <CartIcon isFilled={filled}>
      { quantity > 0 && <CartCount fade={waiting}>{ quantity }</CartCount> }
      <IconButton onClick={onClick}>
        <ShoppingBag size={24} />
      </IconButton>
    </CartIcon>
  );
}

export default CartButton;
