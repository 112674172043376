import React from 'react';
import styled from '@emotion/styled';

import { Currency } from '#mrktbox/types';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

interface SubtotalViewStyle {
  theme? : Theme;
  isBold? : boolean;
}

const SubtotalView = styled.li<SubtotalViewStyle>`
  display: flex;
  width: 100%;
  margin: 1rem 0 0;
  padding: 0 0 0 calc(100% - 24rem);
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  color: ${(props) =>
    props.theme.colours[props.isBold ? 'primary' : 'secondary']};
  ${(props) =>
    props.isBold ? `font-weight: ${props.theme.boldWeight};` : null}

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: 0;
  }

  span {
    display: block;
  }
`;

interface SubtotalProps {
  label : string;
  subtotal : Currency;
  bold? : boolean;
}

function Subtotal({
  label,
  subtotal,
  bold: isBold = false
} : SubtotalProps) {
  return (
    <SubtotalView isBold={isBold}>
      <span>{ label }</span>
      <span>{ formatCurrency(subtotal) }</span>
    </SubtotalView>
  );
}

export default Subtotal;
