import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Location } from '#mrktbox/types';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

import LocationCard from '#components/orders/LocationCard';

interface Style { theme? : Theme; }
interface LocationsSelectListStyle extends Style { hasBox? : boolean; }

const LocationSelectView = styled.div<Style>`
  position: relative;
  flex-grow: 1;
  background-color: ${(props) => props.theme.bgColours.primary};
  padding: 1.5rem 0 0;
`;

const LocationsSelectList = styled.ul<LocationsSelectListStyle>`
  margin: 0 0 ${(props) => props.theme.layout.margin};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 0 ${(props) => props.theme.layout.marginMobile};
  }

  & > li {
    margin: ${(props) => props.theme.layout.padding} 0 0;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: 0 0 ${(props) =>
        (props.hasBox ? '0' : props.theme.layout.paddingMobile)
      };
      border-bottom: ${(props) =>
        props.hasBox ? '0' : props.theme.border.width} solid ${(props) =>
          props.theme.border.colour};
      margin: 0 0 ${(props) => props.theme.layout.paddingMobile};
    }

    &:last-of-type {
      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding: 0;
        border: 0;
        margin: 0;
      }
    }
  }
`;

interface RevenueCentersSelectProps {
  locations : Location[];
  selectLocation : (location : Location) => void;
  disabled? : boolean;
}

function Locations({
  locations,
  disabled,
  selectLocation,
} : RevenueCentersSelectProps) {
  const { theme } = useConfig();

  const handleSelect = useCallback((location : Location) => () => {
    selectLocation(location);
  }, [selectLocation]);

  const hasBox = theme.cards.default.bgColour !== 'transparent'

  return (
    <LocationSelectView>
      <LocationsSelectList hasBox={hasBox}>
        { locations.map((location) => (
          <li
            id={`location-${location.id}`}
            key={location.id}
          >
            <LocationCard
              location={location}
              showImage={true}
              disabled={disabled}
              onSelect={handleSelect(location)}
            />
          </li>
        ))}
      </LocationsSelectList>
    </LocationSelectView>
  );
}

export default Locations;
