import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useSidebar from '#hooks/useSidebar';

import Transition, { TransitionGroup } from '#materials/Transition';

interface Style { theme? : Theme; }

const SidebarOverlayView = styled.div<Style>`
  position: fixed;
  z-index: 16;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.overlay.dark};
`;

function SidebarOverlay() {
  const { closeSidebar, open } = useSidebar();

  return (
    <TransitionGroup component={null}>
      { open ? (
        <Transition key="sidebar-overlay" classNames="overlay" timeout={250}>
          <SidebarOverlayView onClick={closeSidebar} />
        </Transition>
      ) : null}
    </TransitionGroup>
  );
}

export default SidebarOverlay;
