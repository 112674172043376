import IconButton from '#materials/IconButton';
import { Hamburger } from '#materials/icons';

interface NavMenuProps {
  onClick? : () => void;
}

function NavMenu({ onClick } : NavMenuProps) {
  return (
    <IconButton margin="0 -1.5rem 0 0" onClick={onClick}>
      <Hamburger size={20} />
    </IconButton>
  );
}

export default NavMenu;
