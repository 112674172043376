import React from 'react';
import styled from '@emotion/styled';

import Tag from '#materials/Tag';
import { CheckCircle, Refresh } from '#materials/icons';

const OrderTagView = styled.div`
  position: absolute;
  top: -1.1rem;
  right: 1.5rem;
`;

interface OrderTagProps {
  status? : 'pending'
    | 'confirmed'
    | 'inProgress'
    | 'ready'
    | 'fulfilled'
    | 'cancelled'
    | 'draft'
    | 'error';
  subscription? : boolean;
}

const OrderTag = ({ status, subscription } : OrderTagProps) => {
  let tag = null as {
    text : string;
    icon? : React.ReactNode;
    colour? : 'success' | 'error';
  } | null;
  switch (status) {
    case 'draft':
      tag = { text : 'Draft' };
      break;
    case 'cancelled':
      tag = {
        text : 'Cancelled',
        colour : 'error' as const,
      };
      break;
    case 'fulfilled':
      break;
    case 'error':
      tag = {
        text : 'Invalid',
        colour : 'error' as const,
      };
      break;
    default:
      tag = {
        text : 'Coming up',
        icon : <CheckCircle />,
        colour : 'success' as const,
      };
      break;
  }

  if (tag === null) return null

  return (
    <OrderTagView>
      <Tag
        text={tag.text}
        icon={subscription
          ? <Refresh />
          : tag.icon
        }
        colour={tag.colour}
        bgColour={tag.colour}
      />
    </OrderTagView>
  );
}

export default OrderTag;
