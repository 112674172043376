import React, { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';

import { Link } from '@mrktbox/clerk';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

import Body from '#materials/Body';
import Heading from '#materials/Heading';
import { ArrowRight } from '#materials/icons';

interface Style { theme? : Theme; }
interface NavigationButtonViewStyle extends Style {
  delay? : number;
  isButton? : boolean;
  disabled? : boolean;
  error? : boolean;
}
interface NavigationButtonErrorStyle extends Style {
  error? : boolean;
}

const NavButtonView = styled.button<NavigationButtonViewStyle>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  height: 7rem;
  padding: 0 2rem 0 2.5rem;
  margin: 0 0 1rem;
  opacity: 0;
  text-decoration: none;
  animation: slide-up 0.25s ease-in-out ${(props) => props.delay}s forwards;
  transition: ${(props) => props.theme.links.transition};
  border-style: solid;
  border-width: ${(props) => props.theme.buttons.sizes.large.borderWidth};
  border-radius: ${(props) => props.theme.buttons.sizes.large.borderRadius};
  border-color: ${(props) => props.error
    ? props.theme.colours.error
    : props.theme.buttons.colours.large.borderColour};
  background-color: ${(props) => props.theme.buttons.colours.large.bgColour};

  ${(props) => props.disabled && `
    pointer-events: none;
    border-color: ${props.theme.buttons.colours.large.disabledBorderColour};

    * {
      opacity: 0.85;
    }
  `}

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    ${(props) =>
      !props.isButton
        ? `
    padding: 0 0rem 0 1.75rem;
    margin: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    background-color: transparent;
    border-bottom: ${props.theme.border.width} solid
      ${props.theme.border.colour};`
        : 'height: auto; min-height: 6.5rem; padding: 0 1.5rem 0 2rem;'}
  }

  &:hover {
    background-color: ${(props) => props.error
      ? props.theme.colours.error
      : props.theme.buttons.colours.largeHover.bgColour};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      border-color: ${(props) => props.theme.border.colour};
      background-color: transparent;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      ${(props) => (!props.isButton ? `border-bottom: 0;` : '')}
    }
  }
`;

const NavButtonLink = NavButtonView.withComponent(Link);

const NavButtonIcon = styled('span')<NavigationButtonErrorStyle>`
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  flex-shrink: 0;
  line-height: 0;
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.error
    ? props.theme.colours.error
    : props.theme.buttons.colours.large.iconColour};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 1.6rem;
    height: 1.6rem;
  }

  button:hover & {
    color: ${(props) => props.theme.buttons.colours.largeHover.iconColour};
  }
`;

const NavButtonText = styled.span<Style>`
  flex-grow: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 2.25rem;
  }
`;

const NavButtonTitle = styled(Heading)<NavigationButtonErrorStyle>`
  display: block;
  margin: 0;
  margin-left: ${(props) => props.theme.buttons.sizes.large.titleMarginLeft};
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.error
    ? props.theme.colours.error
    : props.theme.buttons.colours.large.colour};
  font-size: ${(props) => props.theme.buttons.sizes.large.titleFontSize};
  font-weight: ${(props) => props.theme.buttons.sizes.large.titleFontWeight};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-left: ${(props) =>
      props.theme.buttons.sizes.large.titleMarginLeftMobile};
    font-size: ${(props) =>
      props.theme.buttons.sizes.large.titleFontSizeMobile};
  }

  button:hover & {
    color: ${(props) => props.theme.buttons.colours.largeHover.colour};
  }
`;

const NavButtonSubtitle = styled(Body)<NavigationButtonErrorStyle>`
  display: block;
  margin: 0;
  margin-top: ${(props) => props.theme.buttons.sizes.large.subtitleMarginTop};
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.error
    ? props.theme.colours.error
    : props.theme.buttons.colours.large.subtitleColor};
  font-size: ${(props) => props.theme.buttons.sizes.large.subtitleFontSize};
  font-weight: ${(props) => props.theme.buttons.sizes.large.subtitleFontWeight};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: ${(props) =>
      props.theme.buttons.sizes.large.subtitleMarginTopMobile};
    font-size: ${(props) =>
      props.theme.buttons.sizes.large.subtitleFontSizeMobile};
  }

  button:hover & {
    color: ${(props) => props.theme.buttons.colours.largeHover.subtitleColour};
  }
`;

const NavButtonArrow = styled.span<NavigationButtonErrorStyle>`
  position: relative;
  width: 2.2rem;
  height: 2.2rem;
  line-height: 0;
  flex-shrink: 0;
  color: ${(props) => props.error
    ? props.theme.colours.error
    : props.theme.buttons.colours.large.iconColour};
  transition: ${(props) => props.theme.links.transition};
  transform: translateX(0);

  button:hover & {
    transform: translateX(1rem);
    color: ${(props) => props.theme.buttons.colours.largeHover.iconColour};

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      transform: translateX(0);
    }
  }
`;

type ButtonLinkEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>;

interface ViewProps {
  href? : string;
  onClick? : (event : ButtonLinkEvent) => void;
  index : number;
  delay : number;
  disabled? : boolean;
  error? : boolean;
  showButtonApp? : boolean;
  children : React.ReactNode;
}

function View({
  href,
  onClick,
  index,
  delay,
  disabled,
  error,
  showButtonApp,
  children,
} : ViewProps) {
  return href
    ? (<NavButtonLink
      to={href}
      onClick={onClick}
      delay={(index + 1) * 0.125 + delay}
      disabled={disabled}
      error={error}
    >
      {children}
    </NavButtonLink>)
    : (<NavButtonView
      onClick={onClick}
      delay={(index + 1) * 0.125 + delay}
      isButton={showButtonApp}
      disabled={disabled}
      error={error}
    >
      {children}
    </NavButtonView>);
}

interface NavigationButtonProps {
  title : string;
  subtitle? : string;
  icon? : React.ReactNode;
  href? : string;
  onClick? : () => void;
  index? : number;
  delay? : number;
  disabled? : boolean;
  error? : boolean;
}

function NavigationButton({
  title,
  subtitle,
  icon,
  href,
  onClick,
  index = 0,
  delay = 0.125,
  disabled = false,
  error = false,
} : NavigationButtonProps) {
  const { theme } = useConfig();

  const handleClick = useCallback(
    (event : ButtonLinkEvent) => {
      event.stopPropagation();
      if (onClick) onClick();
    },
    [onClick],
  );

  const {
    showSubtitle,
    showSubtitleMobile,
    showButtonApp
  } = theme.buttons.sizes.large;
  const show = isMobile ? showSubtitleMobile : showSubtitle;

  return (
    <View
      href={href}
      onClick={handleClick}
      index={index}
      delay={delay}
      disabled={disabled}
      error={error}
      showButtonApp={showButtonApp}
    >
      <NavButtonIcon error={error}>{icon}</NavButtonIcon>
      <NavButtonText>
        <NavButtonTitle error={error}>{title}</NavButtonTitle>
        {subtitle && show ? (
          <NavButtonSubtitle error={error}>{subtitle}</NavButtonSubtitle>
        ) : null}
      </NavButtonText>
      { !disabled && (
        <NavButtonArrow error={error}>
          <ArrowRight strokeWidth={2} />
        </NavButtonArrow>
      )}
    </View>
  );
}

export default NavigationButton;
