import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';

import { Outlet } from '#mrktbox';

import { ModalProvider } from '#context/ModalContext';
import { MapProvider } from '#context/MapContext';

import { Theme } from '#types';

import useMap from '#hooks/useMap';
import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';

import BackButton from '#materials/BackButton';
import Map from '#materials/Map';

import Modal from '#components/modal/Modal';
import Background from '#components/page/Background';
import Content from '#components/page/Content';
import Header from '#components/header/Header';

interface Style { theme? : Theme; }

const MapView = styled.div<Style>`
  position: fixed;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 76.8rem;
  width: calc(100% - 76.8rem);
  background-color: ${(props) => props.theme.bgColours.tertiary};
`;

function OrderingLayout() {
  const { showMap } = useMap();
  const { load : loadCatalogue } = useCatalogue();
  const {
    loaded,
    load : loadRequests,
    resetProposedChanges,
  } = useRequests();

  const onBack = useCallback(() => {
    resetProposedChanges();
  }, [resetProposedChanges]);

  useEffect(() => {
    loadCatalogue();
    loadRequests();
  }, [loadCatalogue, loadRequests]);

  const backPath = window.location.pathname === '/locations/'
    ? '/order-types/'
    : undefined;

  return (
    <>
      <Modal />
      { showMap
        ? (<MapView>
          <Map />
        </MapView>)
        : (<Background
          imageUrl={process.env.REACT_APP_CATALOGUE_HERO}
        />)
      }
      <Header
        maxWidth="76.8rem"
        left={<BackButton path={backPath} onClick={onBack} />}
      />
      { (loaded) && (
        <Content maxWidth="76.8rem">
          <Outlet />
        </Content>
      ) }
    </>
  );
}

function Ordering() {
  return (
    <ModalProvider>
      <MapProvider>
        <OrderingLayout />
      </MapProvider>
    </ModalProvider>
  );
}

export default Ordering;
