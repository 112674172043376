import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled';

import { useProducts } from '#mrktbox';
import { Product } from '#mrktbox/types';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';

import Container from '#materials/Container';

import ProductGrid from '#components/products/ProductGrid';
import ProductCard from '#components/products/ProductCard';
import SearchBar from '#components/catalogue/SearchBar';

interface Style {
  theme? : Theme;
}

const SearchText = styled.p<Style>`
  font-size: ${(props) => props.theme.fonts.sizes.big};
  font-family: "MRKTBOX Font";
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.big};
  }
`;

const Search = () => {
  const { retrieveProductsBulk } = useProducts();
  const { filterProducts } = useCatalogue();

  const [input, setInput] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Product[]>([]);

  useEffect(() => {
    if (searchResults.length > 0) {
      retrieveProductsBulk(
        searchResults
          .filter(p => p.id !== undefined)
          .map(product => product.id) as number[]
      );
    }
  }, [searchResults, retrieveProductsBulk]);

  return (
    <>
      <SearchBar
        input={input}
        setInput={setInput}
        setProducts={setSearchResults}
      />
      <Container style={{marginBottom: '2rem'}}>
        { searchResults.length > 0
          ? (<ProductGrid>
            { filterProducts(searchResults).map(product => (
              <ProductCard
                key={product.id}
                product={product}
              />
            ))}
          </ProductGrid>)
          : (<div style={{display: 'flex', justifyContent: 'center'}}>
            <SearchText>
              { input
                ? "Huh, can't seem to find what you're looking for."
                : "Just start typing in the search bar to search!"
              }
            </SearchText>
          </div>)
        }
      </Container>
    </>
  );
}

export default Search;
