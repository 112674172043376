import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }

const CardFrame = styled.div<Style>`
  width: 8rem;
  height: 5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.buttons.colours.primary.bgColour};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 6rem;
    height: 3.75rem;
  }
`;

const CardIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IconProps {
  size? : number | string;
}

function Amex({ size = 24 } : IconProps) {

  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width={size}
      height={size}
      style={{ margin : "0 -12% 0 0" }}
      viewBox="0 0 56.973564 50.088459"
      xmlns="http://www.w3.org/2000/svg"
    >
    <g
      id="g1"
      transform="matrix(1.721276,0,0,1.721276,-40.449986,-35.286158)">
      <path
        id="polygon1"
        d={"M 30.300781,20.5 23.5,35.900391 H 29 v 13.699218 h 17 l 2.699219,"
          + "-2.90039 2.701172,2.90039 h 5.199218 v -1.699218 h -4.298828 l "
          + "-3.5,-3.800782 -3.5,3.900391 h -4.201172 l 5.59961,-6 -5.59961,"
          + "-6 v 2.599609 h -6.90039 V 40.5 H 41 v 2.699219 H 34.199219 V 45 "
          + "h 7 v 2.800781 H 31 v -12 H 41.099609 V 36 h 4.300782 l 3.5,"
          + "3.800781 3.5,-3.800781 h 4.199218 v -1 -0.800781 h -3.199218 v "
          + "-8.398438 l -3.09961,8.398438 h -2.90039 l -3.09961,-8.398438 v "
          + "8.398438 H 41.199219 37.5 l -1,-2.398438 H 31 l -1,2.398438 h "
          + "-3.599609 l 5.298828,-12 h 4.201172 l 5.199218,11.77539 v "
          + "-11.77539 h 5.09961 l 2.701172,7.5 2.699218,-7.5 h 5 V 20.5 h "
          + "-6.40039 l -1.398438,4 -1.40039,-4 h -8.09961 v 5.300781 L 37,"
          + "20.5 Z m 26.298828,15.5 -5.699218,5.900391 5.699218,6 v -2.5 l "
          + "-3.298828,-3.5 3.298828,-3.5 z m -22.90039,-10.800781 -1.59961,"
          + "3.90039 h 3.201172 z"}
        fill='#FFF'
      />
    </g>
    </svg>
  );
}

function MasterCard({ size = 24 } : IconProps) {

  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 20338.233 9875.5195"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={"M 7309.6703,8137.6293 C 5927.2119,7971.3944 4772.3719,6962.8025 "
          + "4437.1252,5628.8458 4330.2438,5203.5638 4304.0932,4729.8423 "
          + "4365.2421,4326.4306 4593.5213,2820.3663 5773.0564,1677.151 "
          + "7296.5232,1485.431 c 212.9201,-26.7922 623.4492,-20.3766 "
          + "846.0693,13.2333 504.5698,76.1633 938.0704,240.2006 "
          + "1365.8928,516.8647 66.8847,43.2524 121.6085,80.951 "
          + "121.6085,83.7757 0,2.8727 -68.1678,71.1745 -151.4648,151.8766 "
          + "-426.5776,413.2389 -734.1799,908.9418 -917.464,1478.4793 "
          + "-248.2824,771.5032 -220.5231,1625.7883 77.3506,2380.3075 "
          + "181.6563,460.1664 430.4078,836.8396 795.9322,1205.2778 l "
          + "206.6099,208.254 -140.2423,91.9676 c -277.3152,181.8621 "
          + "-673.5481,355.0038 -1004.2944,438.8418 -280.4942,71.1017 "
          + "-417.2798,87.6577 -759.5837,91.9389 -177.1462,2.2119 "
          + "-369.4216,-1.6661 -427.267,-8.6179 z"}
        fill='#FFF'
      />
      <path
        d={"m 9606.8733,7195.7325 c -100.418,-99.423 -222.5052,-231.0561 "
          + "-271.2826,-292.5181 -574.1931,-723.3904 -830.6816,-1634.9061 "
          + "-710.2605,-2524.1972 92.9587,-686.4635 378.1545,-1300.3047 "
          + "849.0377,-1827.4247 113.9386,-127.5453 316.9385,-315.5117 "
          + "340.7431,-315.5117 22.3875,0 383.526,368.1576 477.146,486.4338 "
          + "315.722,398.8279 557.809,910.1618 656.676,1386.9924 58.927,"
          + "284.2172 68.168,380.1088 67.775,703.3565 0,355.4012 -18.596,"
          + "507.2567 -98.417,822.5357 -135.473,535.1079 -384.694,999.8246 "
          + "-764.515,1425.5902 -124.184,139.1986 -317.4457,315.5127 -345.8466,"
          + "315.5127 -10.1596,0 -100.6286,-81.3465 -201.0561,-180.7696 z"}
        fill='#FFF'
      />
      <path
        d={"m 11661.281,8143.7308 c -205.241,-23.3948 -297.29,-37.6488 "
          + "-425.927,-65.9558 -417.251,-91.8154 -786.366,-248.1158 -1135.516,"
          + "-480.836 l -115.2986,-76.8441 153.3316,-147.3905 c 555.339,"
          + "-533.842 919.533,-1224.4583 1042.194,-1976.306 140.923,-863.7983 "
          + "-30.45,-1728.9276 -489.316,-2470.1082 -123.925,-200.1656 -291.707,"
          + "-408.4513 -497.484,-617.5795 -113.67,-115.5089 -205.537,-210.9285 "
          + "-204.1485,-212.0488 71.1455,-57.156 341.6145,-216.5684 488.9425,"
          + "-288.1642 339.709,-165.1098 625.135,-254.0467 994.489,-309.8717 "
          + "246.205,-37.2199 649.342,-40.4181 892.08,-7.0859 868.916,119.3582 "
          + "1648.541,558.3744 2179.118,1227.106 396.233,499.399 624.914,"
          + "1035.9988 716.006,1680.0726 22.023,155.7173 21.841,674.4128 0,"
          + "828.2523 -60.747,422.3251 -169.725,770.0209 -350.989,1119.8904 "
          + "-578.761,1117.0896 -1732.595,1814.3427 -2984.176,1803.3156 "
          + "-122.968,-1.082 -241.331,-3.9929 -263.019,-6.4634 z"}
        fill='#FFF'
      />
    </svg>
  );
}

function Visa({ size = 24 } : IconProps) {

  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 324.68"
    >
      <path
        fill="#FFF"
        d={"m651.19.5c-70.93,0-134.32,36.77-134.32,104.69,0,77.9,112.42,83.28,"
          + "112.42,122.42,0,16.48-18.88,31.23-51.14,31.23-45.77,"
          + "0-79.98-20.61-79.98-20.61l-14.64,68.55s39.41,17.41,"
          + "91.73,17.41c77.55,0,138.58-38.57,138.58-107.66,"
          + "0-82.32-112.89-87.54-112.89-123.86,0-12.91,15.5-27.05,"
          + "47.66-27.05,36.29,0,65.89,14.99,65.89,14.99l14.33-66.2S696.61.5,"
          + "651.18.5h0ZM2.22,5.5L.5,15.49s29.84,5.46,56.72,16.36c34.61,12.49,"
          + "37.07,19.77,42.9,42.35l63.51,244.83h85.14L379.93,"
          + "5.5h-84.94l-84.28,"
          + "213.17-34.39-180.7c-3.15-20.68-19.13-32.48-38.68-32.48,"
          + "0,0-135.41,0-135.41,0Zm411.87,0l-66.63,313.53h81L494.85,"
          + "5.5h-80.76Zm451.76,0c-19.53,0-29.88,10.46-37.47,28.73l-118.67,"
          + "284.8h84.94l16.43-47.47h103.48l9.99,47.47h74.95L934.12,"
          + "5.5h-68.27Zm11.05,84.71l25.18,117.65h-67.45l42.28-117.65h0Z"}
      />
    </svg>
  );
}

function Card() {
  return (
    <div style={{
      width : '100%',
      height : '100%',
      display : 'block',
      padding : '0 2px',
      justifyContent : 'center',
      alignItems : 'center',
    }}>
      <div style={{
        height : '20%',
        margin : '10% 0 70%',
        width : '100%',
        backgroundColor : '#FFF',
      }} />
    </div>
  );
}

interface CreditCardsProps {
  brand? : 'amex' | 'mastercard' | 'visa';
}

function CreditCards({ brand } : CreditCardsProps) {
  let card = null;
  let style : React.CSSProperties = {};
  switch (brand) {
    case 'amex':
      card = (<Amex size="80%" />);
      style = {
        justifyContent : 'flex-end',
        alignContent : 'flex-end',
      };
      break;
    case 'visa':
      card = (<Visa size="70%" />);
      break;
    case 'mastercard':
      card = (<MasterCard size="90%" />);
      break;
    default:
      card = (<Card />);
      style = {
        height : '100%',
        width : '100%',
      };
  }

  return (
    <CardFrame>
      <CardIcon style={style}>
        { card }
      </CardIcon>
    </CardFrame>
  );
}

export default CreditCards;
