import styled from '@emotion/styled'

import { Currency } from '#mrktbox/types';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

import Heading from '#materials/Heading';

interface Style {
  theme? : Theme;
}

const ProductPriceCalsView = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

interface ProductPriceProps extends Style {
  size : keyof Theme['fonts']['sizes'];
  font? : string;
  fade? : boolean;
  strike? : boolean;
}

const ProductPrice = styled(Heading)<ProductPriceProps>`
  white-space: nowrap;
  white-space-collapse: preserve;
  font-family: ${(props) => props.font};
  font-size: ${(props) => props.theme.fonts.sizes[props.size]};
  ${(props) => props.strike && 'text-decoration: line-through;'}

  ${(props) => props.fade && `
    animation: fade-in 0.1s ease-in-out 0s forwards;
  `}
`;

const StyledDollarSign = styled.span`
  position: relative;
  top: -0.1rem;
  font-family: 'Comic Sans MS';
  font-size: 85%;
`;

interface ProductPriceCalsProps {
  price? : Currency;
  adjustment? : Currency;
  cals? : string | number;
  absolute? : boolean;
  size: keyof Theme['fonts']['sizes'];
  variant? : 'default' | 'strike' | 'deal';
  children? : any;
  style? : any;
}

function ProductPriceCals({
  price,
  adjustment,
  absolute = false,
  size = 'small',
  variant = 'default',
  children,
  style,
}: ProductPriceCalsProps){
  if (absolute && price && price?.amount < 0) {
    price = {
      ...price,
      amount : -price.amount,
      calculatedValue : -price.calculatedValue,
    };
  }

  const currencyFormatted = (price ? formatCurrency(price) : undefined)
  const currencyRaw = currencyFormatted?.toString()
    .replace(/[^0-9.-]+/g, '')
    .trim();

  return (
    <ProductPriceCalsView style={style}>
      { price && (
        <>
          { variant === 'deal' && (
            <StyledDollarSign>$</StyledDollarSign>
          ) }
          <ProductPrice
            size={size}
            font={variant === 'deal' ? 'MRKTBOX Font' : undefined}
            strike={variant === 'strike'}
          >
            { variant === 'deal' ? currencyRaw : currencyFormatted }
          </ProductPrice>
        </>
      ) }
      { !!adjustment?.amount && (
        <ProductPrice size={size} fade>
          { (adjustment.amount >= 0 ? ' + ' : ' - ')
            + formatCurrency(adjustment) }
        </ProductPrice>
      ) }
      { children }
    </ProductPriceCalsView>
  )
}

export default ProductPriceCals;
