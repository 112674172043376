import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }
interface CountViewStyle extends Style {
  color? : string;
  bgColor? : string;
  size? : string;
  radius? : string;
}

const CountView = styled.div<CountViewStyle>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: ${(props) => props.radius};
  border-width: ${(props) => props.theme.counts.alerts.borderWidth};
  padding-top: ${(props) => props.theme.counts.alerts.paddingTop};
  padding-bottom: ${(props) => props.theme.counts.alerts.paddingBottom};
  color: ${(props) => props.color || props.theme.counts.alerts.colour};
  border-color: ${(props) =>
    props.bgColor || props.theme.counts.alerts.borderColour};
  background-color: ${(props) =>
    props.bgColor || props.theme.counts.alerts.bgColour};
`;

interface CountCountStyle extends Style {
  fontSize? : keyof Theme['fonts']['sizes'];
}

const CountCount = styled.span<CountCountStyle>`
  display: block;
  line-height: 0;
  -webkit-font-smoothing: ${(props) => props.theme.counts.alerts.fontSmoothing};
  font-family: ${(props) => props.theme.counts.alerts.family};
  font-weight: ${(props) => props.theme.counts.alerts.weight};
  font-size: ${(props) => props.fontSize
    ? props.theme.fonts.sizes[props.fontSize]
    : props.theme.counts.quantity.fontSize};
`;

interface CountProps {
  count : number;
  colour? : string;
  bgColour? : string;
  size? : number;
  fontSize? : keyof Theme['fonts']['sizes'];
}

function Count({
  count,
  colour,
  bgColour,
  size = 20,
  fontSize = 'xSmall',
} : CountProps) {
  return (
    <CountView
      color={colour}
      bgColor={bgColour}
      size={`${size / 10}rem`}
      radius={`${size / 20}rem`}
    >
      <CountCount fontSize={fontSize}>{count}</CountCount>
    </CountView>
  );
}

export default Count;
