import React from 'react';

import IconTextButton from './IconTextButton';
import { MapPin } from '#materials/icons';

interface FulfilmentButtonProps {
  name : string;
  label? : string;
  href? : string;
  onClick? : () => void;
  disabled? : boolean;
  icon? : React.ReactNode;
  fullButton? : boolean;
  compact? : boolean;
}

function FulfilmentButton({
  name,
  label,
  href,
  disabled,
  onClick,
  icon,
  fullButton = true,
  compact = false,
} : FulfilmentButtonProps) {
  return (
    <IconTextButton
      text={name}
      label={label ?? name}
      icon={icon ?? <MapPin />}
      href={href}
      onClick={onClick}
      disabled={disabled}
      useButton={fullButton}
      size={compact ? 'compact' : 'header'}
    />
  );
}

export default FulfilmentButton;
