
export interface Window {
  id? : number;
  start : Date;
  duration : number;
  division : number | null;
  fromIteration : number;
  toIteration : number | null;
  timeSlotId : number;
}

export interface TimeSlotCache {
  minStep? : number;
  iterations? : {
    [iteration : number] : number;
  },
  divisions? : {
    [iteration : number] : {
      [division : number] : number;
    }
  }
}

export interface TimeSlot {
  id? : number;
  name : string;
  start : Date;
  duration : number;
  division : number | null;
  scale : number;
  period : number;
  nextIteration? : number;
  nextWindow? : Date;
  windows : { [id : number] : Window }
  cache? : TimeSlotCache;
}

export interface Schedule {
  id? : number;
  name : string;
  timeSlots : {
    [id : number] : TimeSlot;
  };
  serviceIds : number[];
}

export interface ScheduleSelection {
  timeSlot : TimeSlot;
  iteration : number;
  division : number;
}

function isTimeSlotCache(cache : any): cache is TimeSlotCache {
  return (
    (cache === undefined || typeof cache === 'object') &&
    (cache.minStep === undefined || typeof cache.minStep === 'number') &&
    (cache.iterations === undefined || (
      typeof cache.iterations === 'object' &&
      Object.keys(cache.iterations).every((iteration : any) => {
        return (typeof iteration === 'string' &&
          !isNaN(parseInt(iteration)) &&
          typeof cache.iterations[iteration] === 'number');
      }))) &&
    (cache.divisions === undefined || (
      typeof cache.divisions === 'object' &&
      Object.keys(cache.divisions).every((iteration : any) => {
        return (typeof iteration === 'string' &&
          !isNaN(parseInt(iteration)) &&
          typeof cache.divisions[iteration] === 'object' &&
          Object.keys(cache.divisions[iteration]).every((division : any) => {
            return (typeof division === 'string' &&
              !isNaN(parseInt(division)) &&
              typeof cache.divisions[iteration][division] === 'number');
          }));
      })
    ))
  );
}

export function isWindow(window : any): window is Window {
  return (
    typeof window === 'object' &&
    (window.id === undefined || typeof window.id === 'number') &&
    window.start instanceof Date &&
    typeof window.duration === 'number' &&
    (window.division === null || typeof window.division === 'number') &&
    typeof window.fromIteration === 'number' &&
    (window.toIteration === null ||
      typeof window.toIteration === 'number') &&
    typeof window.timeSlotId === 'number' &&
    (window.cache === undefined || isTimeSlotCache(window.cache))
  );
}

export function isTimeSlot(timeSlot : any): timeSlot is TimeSlot {
  return (
    typeof timeSlot === 'object' &&
    typeof timeSlot.id === 'number' &&
    typeof timeSlot.name === 'string' &&
    timeSlot.start instanceof Date &&
    typeof timeSlot.duration === 'number' &&
    (timeSlot.division === null || typeof timeSlot.division === 'number') &&
    typeof timeSlot.scale === 'number' &&
    typeof timeSlot.period === 'number' &&
    (timeSlot.nextIteration === undefined ||
      typeof timeSlot.nextIteration === 'number') &&
    (timeSlot.nextWindow === undefined ||
      timeSlot.nextWindow instanceof Date) &&
    (typeof timeSlot.windows === 'object' &&
      Object.keys(timeSlot.windows).every((id : any) =>
        typeof id === 'string' && !isNaN(parseInt(id))) &&
      Object.values(timeSlot.windows).every(isWindow))
  )
}

export function isSchedule(schedule : any): schedule is Schedule {
  return (
    typeof schedule === 'object' &&
    typeof schedule.id === 'number' &&
    typeof schedule.name === 'string' &&
    typeof schedule.timeSlots === 'object' &&
    Array.isArray(schedule.serviceIds) &&
    schedule.serviceIds.every((id : any) => typeof id === 'number')
  )
}
