import React from 'react';
import styled from '@emotion/styled'

import { Theme } from '#types';

import Body from '#materials/Body';
import Heading from '#materials/Heading';

interface Style {
  theme? : Theme;
}

interface CategoryHeaderTitleProps extends Style {
  isChild : boolean;
}

const CategoryHeaderView = styled.div<Style>`
  margin: 0 0 3rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 0 ${(props) => props.theme.layout.marginMobile};
  }
`;


const CategoryHeaderTitle = styled(Heading)<CategoryHeaderTitleProps>`
  margin: 0 0 0 -0.1rem;
  font-size: ${(props) =>
    props.theme.fonts.sizes[props.isChild ? 'xBig' : 'xxBig']};
  font-family: 'MRKTBOX Font';
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) =>
      props.theme.fonts.sizes[props.isChild ? 'big' : 'xBig']};
  }
`;

const CategoryHeaderSubtitle = styled(Body)`
  margin: 1rem 0 0;
  white-space: pre-line;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0.5rem 0 0;
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`;

interface CategoryHeaderProps {
  title : string;
  subtitle? : string;
  focusable? : boolean;
  id? : string;
  isChild : boolean;
}

function CategoryHeader({
  title,
  subtitle,
  focusable = true,
  id,
  isChild,
} : CategoryHeaderProps) {
  return (
    <CategoryHeaderView>
      <CategoryHeaderTitle
        id={id}
        as="p"
        isChild={isChild}
        tabIndex={focusable ? 0 : undefined}
      >
        {title}
      </CategoryHeaderTitle>
      { subtitle &&
        <CategoryHeaderSubtitle as="p">
          { subtitle }
        </CategoryHeaderSubtitle>
      }
    </CategoryHeaderView>
  )
}

export default CategoryHeader;
