import React from 'react';
import styled from '@emotion/styled';

import { DraftOrder } from '#mrktbox/types';
import { useTaxes, useAdjustments } from '#mrktbox';

import { Theme } from '#types';

import CheckSummaryItem from '#components/checkout/CheckSummaryItem';
import CheckSummaryTotal from '#components/checkout/CheckSummaryTotal';

interface CheckSummaryViewStyle {
  updating? : boolean;
  theme? : Theme;
}

const CheckSummaryView = styled.div<CheckSummaryViewStyle>`
  position: relative;
  z-index: 2;
  width: 100%;
  // height: 100%;
  padding: 1rem 0 0;
  border-width: 0;
  border-style: solid;
  border-color: ${(props) => props.theme.border.colour};
  border-top-width: ${(props) => props.theme.border.width};
  margin: 2.5rem 0 0;

  & > li {
    opacity: ${(props) => (props.updating ? '0.5' : '1')};
  }
`;

interface CheckSummaryProps {
  order : DraftOrder;
  updating?: boolean;
  showTenders?: boolean;
}

function CheckSummary({
  order,
  updating = false,
  showTenders = true,
} : CheckSummaryProps) {
  const { taxes: allTaxes } = useTaxes();
  const { adjustments: allAdjustments } = useAdjustments();

  const subtotal = order.totals.find((t) => t.key === 'subtotal');
  const adjustments = order.totals.filter((t) => t.adjustmentId
    || t.appliedAdjustmentIds?.length);
  const taxes = order.totals.filter((t) => t.taxId);
  const total = order.totals.find((t) => t.key === 'total');
  const paid = order.totals.find((t) => t.key === 'paid');
  const balance = order.totals.find((t) => t.key === 'balance');

  return (
    <CheckSummaryView updating={updating}>
      { subtotal && (
        <CheckSummaryItem
          label="Subtotal"
          value={subtotal?.total}
          isBold={true}
        />
      )}
      { adjustments.map((a) => {
        const adjustment = (a.adjustmentId && allAdjustments)
          ? allAdjustments[a.adjustmentId]
          : null;
        return (<CheckSummaryItem
          key={a.key}
          label={adjustment
            ? adjustment.name
            : (a.label ?? (a.total.amount > 0 ? 'Fee' : 'Deal'))}
          value={a.total}
        />)
      }) }
      { taxes.map((t) => {
        const tax = (t.taxId && allTaxes) ? allTaxes[t.taxId] : null;
        return (<CheckSummaryItem
          key={t.key}
          label={tax ? tax.name : 'Tax'}
          value={t.total}
        />)
      }) }
      { total && (
        <CheckSummaryTotal
          label="Total"
          value={total?.total}
        />
      )}
      { (showTenders && paid) && (
        <CheckSummaryItem
          label="Amount Paid"
          value={paid.total}
          isBold={true}
        />
      ) }
      { (showTenders && balance) && (
        <CheckSummaryItem
          label="Balance Due"
          value={balance.total}
          isBold={true}
        />
      ) }
    </CheckSummaryView>
  );
}

export default CheckSummary;
