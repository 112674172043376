import config from '../config.json';

function useConfig() {
  return {
    brand : config.brand,
    settings : config.settings,
    content : config.content,
    theme : config.theme,
  };
}

export default useConfig;
