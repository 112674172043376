import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { PaymentToken, CreditCard, Subtotal } from '#mrktbox/types';
import { useCards } from '#mrktbox';
import { listRecords } from '#mrktbox/utils';

import CreditCards from '#materials/icons/CreditCards';

import CreditCardForm from '#components/creditCards/CreditCardForm';
import CheckoutSection from '#components/checkout/CheckoutSection';
import CheckoutButton from '#components/checkout/CheckoutButton';

const CheckoutCreditCardsView = styled.div``

interface OrderCardsProps {
  selected? : CreditCard | null;
  total? : Subtotal;
  newCard? : boolean;
  setSelected? : (card : CreditCard | null) => void;
  setNewCard? : (newCard : boolean) => void;
  onSubmit? : (token : string) => boolean | Promise<boolean>;
  disabled? : boolean;
}

function OrderCards({
  selected,
  total,
  newCard = false,
  disabled = false,
  setSelected,
  setNewCard,
  onSubmit,
} : OrderCardsProps) {
  const { creditCards } = useCards();

  const onSelect = useCallback((card : CreditCard) => () => {
    if (setSelected) setSelected(
      selected?.id === card?.id
        ? null
        : card
    );
  }, [selected, setSelected]);

  const handlePay = useCallback(async (token : PaymentToken) => {
    const success = !!((await onSubmit?.(token.token)) ?? true);
    if (success) setNewCard?.(false);
    return success;
  }, [onSubmit, setNewCard]);

  return (
    <CheckoutSection title="Select a Payment Method">
      <CheckoutCreditCardsView>
        { newCard
          ? (
            <CreditCardForm
              onSaved={handlePay}
              onCancel={() => setNewCard?.(false)}
              total={total}
            />
          ) : (
            <>
              { listRecords(creditCards).map((card) => {
                let brandKey : 'amex' | 'mastercard' | 'visa' | undefined;
                let title = 'Credit Card';
                switch (card.brand) {
                  case 'AMERICAN_EXPRESS':
                    brandKey = 'amex';
                    title = 'American Express';
                    break;
                  case 'MASTERCARD':
                    brandKey = 'mastercard';
                    title = 'Mastercard';
                    break;
                  case 'VISA':
                    brandKey = 'visa';
                    title = 'Visa';
                    break;
                }

                return (
                  <CheckoutButton
                    key={card.id}
                    icon={(<CreditCards brand={brandKey} />)}
                    title={title}
                    subtitle={`**** **** **** ${card.last4}`}
                    onPress={onSelect(card)}
                    disabled={disabled}
                    isApplied={card.id === selected?.id}
                  />
                )
              }) }
              <CheckoutButton
                title="Input Card Details"
                applyText="New"
                disabled={disabled}
                onPress={() => setNewCard?.(true)}
              />
            </>
          )
        }
      </CheckoutCreditCardsView>
    </CheckoutSection>
  );
}

export default OrderCards;
