import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { CreditCard, ProjectedOrder } from '#mrktbox/types';
import {
  useNavigation,
  useOrders,
  useOptions,
  useSubscriptions,
  useNotes,
} from '#mrktbox';
import {
  formats,
  formatCurrency,
  formatDateTime,
  listRecords,
} from '#mrktbox/utils';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';
import useCustomer from '#hooks/useCustomer';

import Text from '#materials/Text';
import Preface from '#materials/Preface';
import { Refresh } from '#materials/icons';
import ButtonStyled from '#materials/ButtonStyled';
import ButtonLink from '#materials/ButtonLink';
import IconButton from '#materials/IconButton';
import FormSection from '#materials/FormSection';
import TextArea from '#materials/TextArea';
import { AlertCircle, CheckCircle } from '#materials/icons';

import Headline from '#components/page/Headline';
import CartSummaryItem from '#components/cart/CartSummaryItem';
import CheckSummary from '#components/checkout/CheckSummary';

import OrderSection from '#components/orders/OrderSection';
import OrderType from '#components/orders/OrderType';
import OrderAddress from '#components/orders/OrderAddress';
import OrderRequestedAt from '#components/orders/OrderRequestedAt';
import OrderCards from '#components/orders/OrderCards';

interface Style { theme? : Theme; }
interface OrderViewStyle extends Style { wide? : boolean; }
interface AlertStyle extends Style { error? : boolean; }

const OrderView = styled.div<OrderViewStyle>`
  max-width: ${(props) => props.wide ? '108rem' : '54rem'};
  margin: 4rem auto;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 3rem auto;
  }
`;

const OrderHeader = styled.div<Style>`
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: left;
  }

  & > span {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.xSmall};
    }
  }
`;

const OrderTitle = styled(Headline)`
  margin: 0.5rem 0;
  font-size: ${(props) => props.theme.fonts.sizes.h1};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.h3};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.h4};
  }
`;

const Alert = styled.p<AlertStyle>`
  display: flex;
  margin: 2rem 0 0;
  gap: 0.5rem;
  align-items: top;
  justify-content: center;

  ${(props) => props.error && `
    color: ${props.theme.colours.error};
  `}

  text-align: left;

  & > svg {
    flex-shrink: 0;
  }

  & > span {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    line-height: 1.4;
  }
`;

const OrderButtons = styled.div<Style>`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: flex-start;
    margin: 2.5rem 0 3rem;
  }

  button + button {
    margin-left: 1rem;
  }
`;

const SplitView = styled.div<Style>`
  display: flex;
  max-width: 100%;
  margin: auto;
  justify-content: center;
  flex-align: stretch;

  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    width: 54rem;
    flex-direction: column;
  }
`;

const NoteButtons = styled.p<Style>`
  display: flex;
  gap: 1rem;
  padding: 0 1rem 0;

  button {
    margin-right: 2rem;
    white-space: nowrap;
  }
`;

interface RecieptStyle extends Style { pad? : boolean; }

const Reciept = styled.div<RecieptStyle>`
  max-width: 54rem;
  flex: 1;
  padding: 0 ${(props) => props.pad ? '2rem' : '0'};

  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    padding: 0;
  }
`;

const Ruler = styled.div<Style>`
  display: block;
  width: 0;
  margin: 2rem 0 0;
  border-left: 1px solid ${(props) => props.theme.border.colour};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const OrderDetails = styled.div`
  margin: 4rem 0 0;
`;

const OrderItems = styled.div`
  margin: 3rem 0 0;
`;

const TimeRemaining = styled(Text)<Style>`
  display: block;
  margin: ${(props) => props.theme.layout.paddingSmall} 0 0;
  font-family: ${(props) => props.theme.fonts.preface.family};
  font-weight: ${(props) => props.theme.fonts.preface.weight};
  letter-spacing: ${(props) => props.theme.fonts.preface.letterSpacing};
  line-height: ${(props) => props.theme.fonts.preface.lineHeight};
`;

const OrderStatusContainer = styled.div<Style>`
  display: flex;
  gap: 2rem;
  align-items: center;
  max-width: 54rem;
  margin: 2rem auto;
  padding: 0 1rem;
  background-color: ${(props) => props.theme.buttons.colours.primary.bgColour};
  border-radius: 10px;
`;

const OrderStatusLeft = styled.div<Style>`
  flex: 1 0 auto;
  width : 20px;
  margin: 0;
  padding: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

const OrderStatus = styled(Text)<Style>`
  flex: 1 0 auto;
  width : 20px;
  margin: 0;
  padding: 1rem;
  white-space: nowrap;
  text-align: center;
  color: ${(props) => props.theme.buttons.colours.primary.colour};
`;

const OrderRefresh = styled.div<Style>`
  display: flex;
  width : 20px;
  margin: 0 0 0 auto;
  flex: 1 0 auto;
  justify-content: end;
`;

const CheckoutButtons = styled.div`
  margin: 3rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
`;

function formatRemaining(remaining : number | null) {
  if (remaining === null || remaining < 0) return '';
  return remaining < 60000
    ? `${Math.ceil(remaining / 1000)}s Remaining`
    : (remaining < 3600000
      ? `${Math.ceil(remaining / 60000)}min Remaining`
      : ``);
}

function calculateRemaining(cutoff : Date | null) {
  return !!cutoff ? (cutoff.getTime() - new Date().getTime()) : null;
}

interface OrderProps {
  order: ProjectedOrder;
}

function Order({
  order,
} : OrderProps) {
  const { navigate } = useNavigation();
  const { allProducts } = useCatalogue();
  const {
    waiting,
    isCurrentOrder,
    isOrderStocked,
    isOrderAvailable,
    isOrderOpen,
    calculateCutoff,
    getLineItems,
    editOrder,
    payOrder,
    hasCardOnFile,
  } = useRequests();

  const { customer } = useCustomer();
  const { refreshOrder } = useOrders();
  const { validateSelections } = useOptions();
  const { generateOrder } = useSubscriptions();
  const {
    createNote,
    updateNote,
    doesProductRequireNote,
    getAddressNotes,
    getOrderNotes,
    getLineItemNotes,
    generateDefaultNote,
  } = useNotes();

  const items = useMemo(() => getLineItems(order), [order, getLineItems]);
  const cutoff = useMemo(
    () => calculateCutoff(order),
    [order, calculateCutoff],
  );

  const [remaining, setRemaining] = useState(calculateRemaining(cutoff));
  const [card, setCard] = useState<CreditCard | null>(null);
  const [newCard, setNewCard] = useState(false);
  const [note, setNote] = useState(
    (order.order ? (getOrderNotes(order?.order)[0]?.content ?? '') : '')
      ?? (order.address
        ? (getAddressNotes(order.address)[0]?.content ?? '')
        : '')
  );
  const [refreshing, setRefreshing] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [editNote, setEditNote] = useState(false);

  const handleEdit = useCallback(() => {
    editOrder(order);
    navigate('/?openCart=true');
  }, [order, navigate, editOrder]);

  const resetNote = useCallback(() => {
    setNote(
      order.order?.id
        ? (getOrderNotes(order.order.id)[0]?.content ?? '')
        : (order.address
          ? (getAddressNotes(order.address)[0]?.content ?? '')
          : ''),
    );
  }, [order, getOrderNotes, getAddressNotes]);

  const saveNote = useCallback(async () => {
    if (!note || !customer?.id) return;

    let targetOrder = order.order;
    if (!order.order) {
      const orders = await generateOrder(order);
      targetOrder = listRecords(orders)[0];
    }
    if (!targetOrder?.id) return;

    const currentNote = getOrderNotes(targetOrder.id)[0] ?? null;
    await (currentNote
      ? updateNote({
        ...currentNote,
        content: note,
      })
      : createNote({
        ...generateDefaultNote({ customerId: customer?.id }),
        content: note,
        orderId: targetOrder?.id,
      }));
    setEditNote(false);
  }, [
    order,
    note,
    customer,
    generateOrder,
    createNote,
    updateNote,
    getOrderNotes,
    generateDefaultNote,
  ]);

  const removeNote = useCallback(async () => {
    if (!note || !customer?.id || !order.order?.id) return;
    const currentNote = getOrderNotes(order.order.id)[0] ?? null;
    if (!currentNote) return;

    await updateNote({
      ...currentNote,
      content: '',
    });
    setNote('');
    setEditNote(false);
  }, [order, note, customer, updateNote, getOrderNotes]);

  const handlePay = useCallback(async (token? : string) => {
    if (!token && !card) return false;
    setProcessing(true);

    const success = await payOrder({
      order,
      ...(token ? { token } : (card && { card })),
    });

    if (note && customer?.id && order.order?.id) {
      const currentNote = getOrderNotes(order.order.id)[0] ?? null;
      await (currentNote
        ? updateNote({
          ...currentNote,
          content: note,
        })
        : createNote({
          ...generateDefaultNote({ customerId: customer?.id }),
          content: note,
          orderId: order.order?.id,
        }));
    }
    setProcessing(false);
    return success;
  }, [
    order,
    card,
    note,
    customer,
    payOrder,
    createNote,
    updateNote,
    getOrderNotes,
    generateDefaultNote,
  ]);

  const handleRefresh = useCallback(async () => {
    if (!order.order?.id) return;
    setRefreshing(true);
    await refreshOrder(order.order?.id)
    setRefreshing(false);
  }, [refreshOrder, order.order?.id])

  const customisationValid = useMemo(() => {
    if (!order.time || !allProducts) return true;
    for (const item of items) {
      const product = allProducts[item.productId] ?? null;
      if (!product) continue;

      const selections = Object.values(order.selections)
        .filter((s) => s.lineItemId === item.id);
      if (!validateSelections(product, selections, order.time).valid) {
        return false;
      }

      const requiresNote = doesProductRequireNote(product);
      if (requiresNote && !getLineItemNotes(item).some((n) => !!n.content)) {
        return false;
      }
    }
    return true;
  }, [
    order,
    allProducts,
    items,
    validateSelections,
    doesProductRequireNote,
    getLineItemNotes,
  ]);

  useEffect(() => {
    const pastInterval = setInterval(
      () => setRemaining(calculateRemaining(cutoff)),
      1000,
    );
    return () => clearInterval(pastInterval);
  }, [cutoff]);

  useEffect(() => {
    const refreshInterval = setInterval(() => handleRefresh(), 120000);
    return () => clearInterval(refreshInterval);
  }, [order, handleRefresh]);

  const orderTitle = order.address
    ? 'Delivery Order'
    : (order.location
      ? `Pickup Order from ${order.location?.name}`
      : 'Draft Order');

  const canEdit = isOrderOpen(order);

  const total = order.totals.find((t) => t.key === 'total');
  const checkout = canEdit
    && order.complete
    && order.serviceChannel?.requireCheckout;

  const orderStatus = order.status === 'pending' || order.status === 'confirmed'
    ? 'Pending'
    : order.status === 'inProgress'
      ? 'In Progress'
      : (order.status === 'ready'
        ? 'Ready'
        : '');

  const showStatus = (
    ((order.serviceChannel?.requireCheckout && order.paid)
      || (!order.serviceChannel?.requireCheckout && hasCardOnFile()))
    && order.status !== 'fulfilled'
    && order.status !== 'cancelled'
  );

  const available = isOrderAvailable(order);
  const stocked = isOrderStocked(order);
  const pastCutoff = !order.paid && (remaining !== null && remaining <= 0);
  const remainingText = formatRemaining(remaining);

  const automatic = !order.serviceChannel?.requireCheckout
    && order.complete
    && (hasCardOnFile() || order.paid);
  const needsCard = !order.serviceChannel?.requireCheckout
    && order.complete
    && (!hasCardOnFile() && !order.paid);
  const error = !available || !stocked || !customisationValid || needsCard;
  const invalid = error || pastCutoff;

  const buttonText = available
    ? 'Submit' + (total ? ` - ${formatCurrency(total?.total)}` : '')
    : 'Unavailable Items';

  const noteChange = note !== (order.order?.id
    ? (getOrderNotes(order.order.id)[0]?.content ?? '')
    : (order.address ? getAddressNotes(order.address)[0]?.content ?? '' : ''));

  const prompt = useMemo(() => {
    if (!available) return (
      <span>
        { `One or more items in this order are currently unavailable for
          ${order?.serviceChannel?.name ?? 'this order type'}.` }
      </span>
    );
    if (!stocked) return (
      <span>
        { `One or more items in this order are currently out of stock.
          Remove out of stock items to procceed.` }
      </span>
    );
    if (!customisationValid) return (
      <span>
        { `One or more items in this order require additional customisation
          to continue.` }
      </span>
    );
    if (needsCard) return (
      <span>
        { `You currently do not have a card on file. Please ` }
        <ButtonLink
          href='/credit-cards/'
          colour='error'
        >
          add a card on file
        </ButtonLink>
        { ` to procceed.` }
      </span>
    );
    if (error) return (
      <span>
        { `Opps! Something went wrong. Please contact us for assistance.` }
      </span>
    );
    if (automatic) {
      let appendType = '';
      const channelName = order.serviceChannel?.name ?? 'Orders';
      if (channelName.toLowerCase().endsWith('order')) appendType = 's';
      else if (!channelName.toLowerCase().endsWith('orders'))
        appendType = ' orders';

      return (
        <span>
          { `Your order has been recieved. ${channelName + appendType}`
            + ` are confirmed and proccessed automatically. You may edit or
            cancel this order freely until `
            + (cutoff
              ? formatDateTime(cutoff, formats.easy)
              : 'the cutoff time')
            + `.` }
        </span>
      );
    }
  }, [
    available,
    stocked,
    customisationValid,
    automatic,
    needsCard,
    error,
    order,
    cutoff,
  ]);

  return (
    <OrderView wide={checkout}>
      <OrderHeader>
        <Preface size="small" color="tertiary">
          { order.order ? `Order #${order.order.id}` : 'Order Details' }
        </Preface>
        <OrderTitle as={'h1'}>{ orderTitle }</OrderTitle>
        { showStatus
          ? (
            <OrderStatusContainer>
              <OrderStatusLeft/>
              <OrderStatus size="big">
                { orderStatus || 'Pending' }
              </OrderStatus>
              <OrderRefresh>
                <IconButton
                  onClick={handleRefresh}
                  disabled={refreshing}
                  filled
                  margin='0.5rem 0'
                >
                  <Refresh />
                </IconButton>
              </OrderRefresh>
            </OrderStatusContainer>
          ) : (order.status !== 'fulfilled' && (
            <>
              <Preface size="small" color="tertiary">
                { !pastCutoff
                  ? (cutoff
                    ? `Order Cutoff: ${formatDateTime(cutoff, formats.easy)}`
                    : '')
                  : 'Order Cutoff Passed'
                }
              </Preface>
              { (cutoff && !pastCutoff && remainingText) && (
                <TimeRemaining
                  size="small"
                  color="tertiary"
                  style={{}}
                >
                  ({ remainingText })
                </TimeRemaining>
              ) }
            </>
        )) }
        { (error || automatic) && (
          <Alert error={error}>
            { !error
              ? (<CheckCircle size={16} />)
              : (<AlertCircle size={16} />)
            }
            { prompt }
          </Alert>
        ) }
        <OrderButtons>
          { canEdit && (
            <ButtonStyled onClick={handleEdit}>
              { (isCurrentOrder(order) && !error)
                ? 'Continue Ordering'
                : 'Edit Order' }
            </ButtonStyled>
          ) }
        </OrderButtons>
      </OrderHeader>
      <SplitView>
        <Reciept pad={true}>
          <OrderDetails>
            { order.serviceChannel && (
              <OrderSection label="Order Type">
                <OrderType serviceChannel={order.serviceChannel} />
              </OrderSection>
            ) }
            { order.time && (
              <OrderSection label="Requested Time">
                <OrderRequestedAt time={order.time} />
              </OrderSection>
            ) }
            { order.address && (
              <OrderSection label="Delivery Address">
                <OrderAddress address={order.address} />
              </OrderSection>
            ) }
            { !order.address && order.location?.address && (
              <OrderSection label="Pickup Location">
                <OrderAddress address={order.location.address} />
              </OrderSection>
            ) }
            { order.complete && (
              <OrderSection noTitle>
                <TextArea
                  label="Order Note"
                  name="note"
                  value={note}
                  onChange={setNote}
                  disabled={waiting || !(checkout || editNote)}
                />
                { (
                  !order.serviceChannel?.requireCheckout
                    || checkout
                    || order.paid
                ) && (
                  <NoteButtons>
                    <ButtonLink
                      onClick={() => {
                        setEditNote(!editNote);
                        resetNote();
                      }}
                      disabled={waiting}
                    >
                      { editNote ? 'cancel' : 'edit' }
                    </ButtonLink>
                    { (editNote && noteChange) && (
                      <ButtonLink
                        onClick={saveNote}
                        disabled={waiting}
                      >
                        save
                      </ButtonLink>
                    ) }
                    { (!editNote && !!note) && (
                      <ButtonLink
                        onClick={removeNote}
                        disabled={waiting}
                      >
                        remove
                      </ButtonLink>
                    ) }
                  </NoteButtons>
                ) }
              </OrderSection>
            ) }
          </OrderDetails>
          <FormSection title="Order Summary & Receipt">
            { items.length > 0 && (
              <OrderItems>
                { items.map((item, index) => {
                  return (
                    <CartSummaryItem
                      key={`${item.id}-${index}`}
                      order={order}
                      lineItem={item}
                    />
                  )
                })}
              </OrderItems>
            )}
            <CheckSummary order={order} showTenders={true} />
          </FormSection>
        </Reciept>
        { checkout && (
          <>
            <Ruler />
            <Reciept pad>
              <OrderCards
                selected={card}
                total={total}
                newCard={newCard}
                setSelected={setCard}
                setNewCard={setNewCard}
                onSubmit={handlePay}
                disabled={processing || invalid || waiting}
              />
              { !newCard && (
                <CheckoutButtons>
                  <ButtonStyled
                    size="big"
                    disabled={!card || processing || invalid || waiting}
                    onClick={handlePay}
                  >
                    { buttonText }
                  </ButtonStyled>
                </CheckoutButtons>
              ) }
            </Reciept>
          </>
        ) }
      </SplitView>
    </OrderView>
  );
}

export default Order;
