import { useCallback } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import { X } from '#materials/icons';

interface Style {
  theme ?: Theme;
}

const ModalCloseX = styled.button<Style>`
  position: absolute;
  z-index: 2;
  top: 7px;
  right: 7px;
  display: inline;
  font-size: inherit;
  color: ${(props) => props.theme.links.dark.colour};

  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.links.dark.hover};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      color: ${(props) => props.theme.links.dark.colour};
    }
  }

  &:disabled {
    color: ${(props) => props.theme.links.dark.colour};
    opacity: 0.5;
  }
`;

interface ModalCloseProps {
  onClick ?: () => void;
}

const ModalClose = ({ onClick } : ModalCloseProps) => {

  const handleClose = useCallback(() => {
    if (onClick) onClick();
  }, [onClick])

  return (
    <ModalCloseX
      id="modal-close"
      onClick={handleClose}
      aria-label="Close dialog"
    >
      <X size={20} />
    </ModalCloseX>
  );
}

export default ModalClose;
