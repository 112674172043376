import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import Preface from '#materials/Preface';

interface Style { theme? : Theme; }
interface TagViewStyle extends Style {
  round? : boolean;
  useBgColours : boolean;
  bgColour? : keyof Theme['bgColours'] & keyof Theme['colours'];
}

const TagView = styled('span')<TagViewStyle>`
  display: inline-block;
  padding: ${(props) => props.round
    ? '0.5rem 0.5rem 0.5rem'
    : '0.5rem 1rem 0.5rem'};
  border-radius: ${(props) => props.round ? '50%' : '1.5rem'};
  line-height: 0;
  color: ${(props) => props.theme.colours.light};
  background-color: ${(props) => props.bgColour
      ? (props.useBgColours
        ? props.theme.bgColours[props.bgColour]
        : props.theme.colours[props.bgColour])
      : (props.useBgColours
        ? props.theme.bgColours.light
        : props.theme.colours.light)
  };
`;

const TagContainer = styled('span')`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  line-height: 0;

  span {
    display: inline-block;
  }
`;

interface TagIconStyle extends Style {
  colour? : 'error' | 'success';
}

const TagIcon = styled.span<TagIconStyle>`
  position: relative;
  width: 1.2rem;
  height: 1.2rem;
  padding: 0;
  flex-shrink: 0;
  color: ${(props) => props.colour
    ? props.theme[props.colour].colour
    : props.theme.colours.dark};
`;

const TagText = styled(Preface)<TagIconStyle>`
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  color: ${(props) => props.colour
    ? props.theme[props.colour].colour
    : props.theme.colours.dark};
  line-height: 1.2;
`;

interface TagProps {
  text? : string;
  icon? : React.ReactNode;
  colour? : 'error' | 'success';
  bgColour? : keyof Theme['bgColours'] & keyof Theme['colours'];
  useBgColours? : boolean;
}

const Tag = ({
  text,
  icon,
  colour,
  bgColour,
  useBgColours = true ,
} : TagProps) => {
  return (
    <TagView
      round={!text}
      bgColour={bgColour}
      useBgColours={useBgColours}
    >
      <TagContainer>
        {icon && <TagIcon colour={colour}>{ icon }</TagIcon>}
        { !!text && (<TagText colour={colour}>{ text }</TagText>) }
      </TagContainer>
    </TagView>
  );
}

export default Tag;
