import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';

import { Address, Customer } from '#mrktbox/types';
import { useCustomers, useNotes } from '#mrktbox';

import Box from '#materials/Box';
import Row from '#materials/Row';
import ButtonLink from '#materials/ButtonLink';

import OrderAddress  from '#components/addresses/OrderAddress';

const ButtonView = styled.p`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  * {
    margin: 0 1.5rem 0 0;
    white-space: nowrap;
  }
`;

interface AddressCardProps {
  address : Address;
  customer : Customer;
}

function AddressCard({
  address,
  customer,
} : AddressCardProps) {
  const {
    createNote,
    updateNote,
    getAddressNotes,
    generateDefaultNote,
  } = useNotes();
  const { removeAddressFromCustomer } = useCustomers();

  const [note, setNote] = useState(getAddressNotes(address)[0]?.content ?? '');
  const [busy, setBusy] = useState(false);
  const [editNote, setEditNote] = useState(false);

  const handleEditNote = useCallback(() => { setEditNote(true); }, []);
  const handleCancelEditNote = useCallback(() => {
    setEditNote(false);
    setNote(getAddressNotes(address)[0]?.content ?? '');
  }, [address, getAddressNotes]);

  const handleSaveNote = useCallback(async () => {
    if (!customer.id || !address.id) return;

    const currentNote = getAddressNotes(address)[0] ?? null;
    if (note === (currentNote?.content ?? '')) {
      setEditNote(false);
      return;
    };

    setBusy(true);
    const newNote = currentNote
      ? await updateNote({
        ...currentNote,
        content : note,
      })
      : await createNote({
        ...generateDefaultNote({ customerId : customer.id }),
        content : note,
        addressId : address.id,
      });
    setBusy(false);
    if (!newNote) return;

    setNote(newNote.content);
    setEditNote(false);
  }, [
    address,
    customer,
    note,
    createNote,
    updateNote,
    getAddressNotes,
    generateDefaultNote,
  ]);

  const handleRemoveNote = useCallback(async () => {
    const currentNote = getAddressNotes(address)[0] ?? null;
    if (currentNote) {
      setBusy(true);
      const removed = await updateNote({
        ...currentNote,
        content: '',
      });
      setBusy(false);
      if (!removed) return;
    }

    setNote('');
    setEditNote(false);
  }, [address, updateNote, getAddressNotes]);

  const handleRemoveAddress = useCallback(async () => {
    setBusy(true);
    await removeAddressFromCustomer(
      customer,
      address,
    );
    setBusy(false);
  }, [customer, address, removeAddressFromCustomer]);

  const addressNote = getAddressNotes(address)[0] ?? null;
  const hasNote = !!addressNote?.content;

  return (
    <Box>
      <Row
        key={address.id}
        content={
          <OrderAddress
            address={address}
            note={(note || editNote) ? note : undefined}
            setNote={setNote}
            isCard={true}
            editing={editNote}
          >
            <ButtonView>
              { !editNote && (
                <ButtonLink onClick={handleEditNote} disabled={busy}>
                  { hasNote ? 'edit note' : 'add note' }
                </ButtonLink>
              ) }
              { editNote &&
                <ButtonLink onClick={handleCancelEditNote} disabled={busy}>
                  cancel
                </ButtonLink>
              }
              { (editNote && ((note ?? '') !== (addressNote?.content ?? ''))) &&
                <ButtonLink onClick={handleSaveNote} disabled={busy}>
                  save
                </ButtonLink>
              }
              { (hasNote && !editNote) &&
                <ButtonLink onClick={handleRemoveNote} disabled={busy}>
                  remove note
                </ButtonLink>
              }
              <ButtonLink onClick={handleRemoveAddress} disabled={busy}>
                remove address
              </ButtonLink>
            </ButtonView>
          </OrderAddress>
        }
      />
    </Box>
  );
}

export default AddressCard;
