import React from 'react';
import { isBrowser } from 'react-device-detect';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';

import { Theme } from '#types';

import ButtonStyled from '#materials/ButtonStyled';

interface ButtonIconViewStyle {
  theme? : Theme;
  disabled? : boolean;
  colour? : 'header' | 'primary' | 'secondary' | 'light' | 'link';
  size? : 'header' | 'default' | 'small' | 'big' | 'compact';
}

const ButtonIconView = styled.button<ButtonIconViewStyle>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-align: center;
  width: ${(props) => ['header', 'small', 'compact'].includes(props.size ?? '')
  ? '1.2rem'
  : '1.4rem' };
  height: ${(props) => ['header', 'small', 'compact'].includes(props.size ?? '')
    ? '1.2rem'
    : '1.4rem' };
  border: 0;
  margin: 0;
  color: ${(props) => props.color || props.theme.links.primary.colour};
  transition: ${(props) => props.theme.links.transition};
  opacity: ${(props) => (props.disabled ? '0.75' : '1.0')};

  &:hover {
    color: ${(props) => props.theme.links.primary.hover};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      color: ${(props) => props.theme.buttons.colours.header.colour};
    }
  }
`;

const ButtonLinkView = styled(Link)<ButtonIconViewStyle>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-align: center;
  width: ${(props) => ['header', 'small', 'compact'].includes(props.size ?? '')
    ? '1.2rem'
    : '1.4rem' };
  height: ${(props) => ['header', 'small', 'compact'].includes(props.size ?? '')
    ? '1.2rem'
    : '1.4rem' };
  border: 0;
  margin: 0;
  color: ${(props) => props.color || props.theme.links.primary.colour};
  transition: ${(props) => props.theme.links.transition};
  opacity: ${(props) => (props.disabled ? '0.75' : '1.0')};

  ${(props) => props.disabled && `
    pointer-events: none;
  `}

  &:hover {
    color: ${(props) => props.theme.links.primary.hover};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      color: ${(props) => props.theme.buttons.colours.header.colour};
    }
  }
`;

interface ButtonIconProps {
  label? : string;
  href? : string;
  disabled? : boolean;
  onClick? : () => void;
  colour? : 'header' | 'primary' | 'secondary' | 'light' | 'link';
  size? : 'header' | 'default' | 'small' | 'big' | 'compact';
  children? : React.ReactNode;
}

const ButtonIconLink = ({
  label,
  href,
  disabled,
  onClick,
  colour,
  size,
  children,
} : ButtonIconProps) => {
  const handleClick = (evt : React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (onClick) onClick()
  }

  return (
    <ButtonLinkView
      to={href ?? '#'}
      aria-label={label}
      disabled={disabled}
      onClick={(evt : React.MouseEvent<HTMLAnchorElement>) => handleClick(evt)}
      colour={colour}
      size={size}
    >
      <span>{children}</span>
    </ButtonLinkView>
  )
}

const ButtonIcon = ({
  label,
  href,
  disabled,
  onClick,
  colour,
  size,
  children,
} : ButtonIconProps) => {
  const handleClick = (evt : React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (onClick) onClick()
  }

  return (
    href
      ? (<ButtonIconLink
        label={label}
        href={href}
        disabled={disabled}
        onClick={onClick}
        colour={colour}
        size={size}
      >
        { children }
      </ButtonIconLink>)
      : (<ButtonIconView
        type="button"
        aria-label={label}
        disabled={disabled}
        onClick={(evt) => handleClick(evt)}
        colour={colour}
        size={size}
      >
        <span>{ children }</span>
      </ButtonIconView>)
  )
}

interface IconTextButtonProps {
  text? : string;
  label? : string;
  icon? : React.ReactNode;
  href? : string;
  onClick? : () => void;
  disabled? : boolean;
  colour? : 'header' | 'primary' | 'secondary' | 'light' | 'link';
  size? : 'header' | 'default' | 'small' | 'big' | 'compact';
  useButton? : boolean;
  style? : object;
}

const IconTextButton = ({
  text,
  label,
  icon,
  href,
  disabled,
  onClick,
  colour = 'link',
  size = 'header',
  useButton = false,
} : IconTextButtonProps) => {
  return ((isBrowser || useButton)
    ? (<ButtonStyled
      label={label}
      href={href}
      disabled={disabled}
      onClick={onClick}
      icon={icon}
      colour={colour}
      size={size}
    >
      {text}
    </ButtonStyled>)
    : (<ButtonIcon
      label={label || text}
      href={href}
      disabled={disabled}
      onClick={onClick}
      colour={colour}
      size={size}
    >
      {icon}
    </ButtonIcon>)
  );
}

export default IconTextButton;
