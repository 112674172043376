import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { Outlet } from '#mrktbox';

import { ModalProvider } from '#context/ModalContext';
import { SidebarProvider } from '#context/SidebarContext';

import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';

import Modal from '#components/modal/Modal';
import Sidebar from '#components/sidebar/Sidebar';
import LoginRouter from '#components/auth/LoginRouter';
import CatalogueHeader from '#components/catalogue/CatalogueHeader';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

function CatalogueLayout() {
  const {
    load : loadCatalogue,
    loaded : catalogueLoaded,
  } = useCatalogue();
  const { load : loadRequests } = useRequests();

  useEffect(() => {
    loadCatalogue();
    if (catalogueLoaded) loadRequests();
  }, [catalogueLoaded, loadCatalogue, loadRequests]);

  return (
    <>
      <Modal />
      <Sidebar />
      <LoginRouter />
      <CatalogueHeader />
      { catalogueLoaded
        ? (<Outlet />)
        : (<Overlay />)
      }
    </>
  );
}

function Catalogue() {
  return (
    <ModalProvider>
      <SidebarProvider>
        <CatalogueLayout />
      </SidebarProvider>
    </ModalProvider>
  );
}

export default Catalogue;
