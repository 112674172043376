import { useCallback, useContext } from 'react';

import {
  Address,
  Assembly,
  Collection,
  Customer,
  Currency,
  Location,
  Product,
  ServiceChannel,
  TimeSlot,
  LineItem,
  Fulfilment,
  Selection,
  DraftCustomOrder,
} from '#mrktbox/clerk/types';

import OptionsContext from '#mrktbox/clerk/context/OptionsContext';

import useProducts from '#mrktbox/clerk/hooks/useProducts';
import useTags, { generateDefaultTag } from '#mrktbox/clerk/hooks/useTags';
import useOrders, { draftEmptyOrder } from '#mrktbox/clerk/hooks/useOrders';

import { listRecords } from '#mrktbox/clerk/utils/data';

export function generateDefaultAssembly() : Assembly {
  return {
    name : '',
    productIds : [],
    collectionIds : [],
    complimentary : false,
    cumulative : false,
  };
}

export function generateDefaultCollection() : Collection {
  return {
    ...generateDefaultTag(),
    min : 0,
    max : 0,
    starting : null,
    ending : null,
    tagIds : [],
    defaults : [],
  };
}

export function draftEmptyCustomOrder() : DraftCustomOrder {
  return {
    ...draftEmptyOrder(),
    selections : {},
  };
}

function useOptions() {
  const {
    products,
    loaded : productsLoaded,
    load : loadProducts,
  } = useProducts();
  const {
    tags,
    loaded : tagsLoaded,
    load : loadTags,
    addProductToTag,
    removeProductFromTag,
    getTagProducts,
  } = useTags();
  const {
    lineItems : allLineItems,
    loaded : ordersLoaded,
    load : loadOrders,
    findOrder,
    createDefaultOrder,
  } = useOrders();

  const contextHooks = useContext(OptionsContext);

  const customProducts = contextHooks.customProducts;
  const assembies = contextHooks.assemblies;
  const collections = contextHooks.collections;
  const selections = contextHooks.selections;
  const load = contextHooks.load;
  const createAssembly = contextHooks.createAssembly;
  const createCollection = contextHooks.createCollection;
  const refreshCollection = contextHooks.refreshCollection;
  const addAssemblyToProduct = contextHooks.addAssemblyToProduct;
  const addCollectionToAssembly = contextHooks.addCollectionToAssembly;
  const customiseOrder = contextHooks.customiseOrder;
  const bulkCreateSelections = contextHooks.bulkCreateSelections;
  const bulkUpdateSelections = contextHooks.bulkUpdateSelections;

  const createProductAssembly = useCallback(async (
    product : Product,
    assembly : Assembly,
  ) => {
    const newAssembly = await createAssembly(assembly);
    if (!newAssembly) return null;
    return addAssemblyToProduct(product, newAssembly);
  }, [createAssembly, addAssemblyToProduct]);

  const createAssemblyCollection = useCallback(async (
    assembly : Assembly,
    collection : Collection,
  ) => {
    const newCollection = await createCollection(collection);
    if (!newCollection) return null;
    return addCollectionToAssembly(assembly, newCollection);
  }, [createCollection, addCollectionToAssembly]);

  const addProductToCollection = useCallback(async (
    collection : Collection,
    product : Product,
  ) => {
    const newTag = await addProductToTag(collection, product);
    if (!newTag || !collection.id) return null;
    return refreshCollection(collection.id);
  }, [addProductToTag, refreshCollection]);

  const bulkUpdateCustomisedLineItems = useCallback(async (
    lineItems : LineItem[],
    itemSelections : Selection[],
  ) => {
    const removedSelections = listRecords(selections).filter(
      selection => lineItems.some(item => item.id === selection.lineItemId)
        && !itemSelections.some(s => s.id === selection.id)
    );

    const newSelections = await bulkUpdateSelections(
      itemSelections,
      {
        lineItems,
        deleteSelections : removedSelections,
      },
    );

    return newSelections;
  }, [
    selections,
    bulkUpdateSelections,
  ]);

  const removeProductFromCollection = useCallback(async (
    collection : Collection,
    product : Product,
  ) => {
    const newTag = await removeProductFromTag(collection, product);
    if (!newTag || !collection.id) return null;
    return refreshCollection(collection.id);
  }, [removeProductFromTag, refreshCollection]);

  const getProductAssemblies = useCallback((product : Product) => {
    if (!assembies) return [];
    if (customProducts && product.id && customProducts[product.id]) {
      return customProducts[product.id]?.assemblyIds.map((i) => assembies[i])
        .filter((a) => !!a) as Assembly[];
    }
    return listRecords(assembies).filter(
      assembly => product.id && assembly.productIds.includes(product.id)
    )
  }, [customProducts, assembies]);

  const getAssemblyCollections = useCallback((assembly : Assembly) => {
    const now = new Date();
    return collections
      ? listRecords(collections).filter(collection =>
        collection.ending === null
         || collection.ending > now
      ).filter(
        collection => collection.id &&
          assembly.collectionIds.includes(collection.id),
      ).sort((a, b) => {
        if (a.starting === null) return -1;
        if (b.starting === null) return 1;
        return a.starting.getTime() - b.starting.getTime();
      })
      : [];
  }, [collections]);

  const getCollectionWindows = useCallback((
    assembly : Assembly,
    collection : Collection,
  ) => {
    const allCollections = getAssemblyCollections(assembly)
      .filter(col => col.id !== collection.id)
      .filter(col =>
        (col.starting?.getTime() ?? 0 !== collection.starting?.getTime() ?? 0)
          || (col.id ?? Infinity) > (collection.id ?? Infinity)
      )
      .filter(col => (col.starting?.getTime() ?? 0)
        >= (collection.starting?.getTime() ?? 0))
      .sort((a, b) => {
        if (a.starting === null) return 1;
        if (b.starting === null) return -1;
        return a.starting.getTime() - b.starting.getTime();
      }
    )

    const windows = [] as { start : Date | null, end : Date | null }[];
    let current = (collection.starting ?? new Date(0)) as Date | null;
    for (const col of allCollections) {
      if (collection.ending !== null
        && current
        && ((current.getTime() ?? 0) >= collection.ending.getTime())
      ) continue;

      if ((col.starting?.getTime() ?? 0) > (current?.getTime() ?? 0)) {
        windows.push({
          start : current,
          end : col.starting,
        });
      }

      current = col.ending;
      if (!current) break;
    }

    if (
      current
        && (current.getTime() < (collection.ending?.getTime() ?? Infinity))
    ) {
      windows.push({ start : current, end : collection.ending });
    }

    return windows;
  }, [getAssemblyCollections]);

  const getCollectionTags = useCallback((collection : Collection) => {
    return tags
    ? listRecords(tags).filter(
      tag => tag.id && collection.tagIds.includes(tag.id),
      )
      : [];
    }, [tags]);

  const getCollectionProducts = useCallback((
    collection : Collection,
    options? : { includeTags? : boolean },
  ) => {
    options = {
      includeTags : true,
      ...options,
    }

    const collectionProducts = getTagProducts(collection);
    if (options?.includeTags) {
      const collectionTags = getCollectionTags(collection);
      const tagProducts = collectionTags
        .map(tag => getTagProducts(tag)).flat()
        .reduce((acc, val) => {
          if (
            acc.some(p => p.id === val.id)
              || collectionProducts.some(p => p.id === val.id)
          ) return acc;
          return [...acc, val];
        }, [] as Product[]);
      collectionProducts.push(...tagProducts);
    }

    return collectionProducts;
  }, [getCollectionTags, getTagProducts]);

  const getCollectionDefaults = useCallback((collection : Collection) => {
    return collection.defaults.map(
      ({ productId, quantity }) => {
        const product = products && products[productId];
        return product ? {
          product,
          quantity,
        } : null;
      }
    ).filter((p) => !!p);
  }, [products]);

  const getProductDefaultCount = useCallback((
    collection : Collection,
    product : Product,
  ) => {
    return collection.defaults.find(
      ({ productId }) => productId === product.id
    )?.quantity ?? 0;
  }, []);

  const getTotalDefaultCount = useCallback((collection : Collection) => {
    return collection.defaults.reduce((sum, { quantity }) => sum + quantity, 0);
  }, []);

  const getCollection = useCallback((assembly : Assembly, date : Date) => {
    const collections = getAssemblyCollections(assembly).sort(
      (a, b) => {
        if (a.starting === null) return 1;
        if (b.starting === null) return -1;
        return b.starting.getTime() - a.starting.getTime();
      }
    );
    return collections.find((col : Collection) => (
      ((col.starting === null) ||
        (col.starting.getTime() <= date.getTime())) &&
      ((col.ending === null) ||
        (col.ending.getTime() >= date.getTime()))
    ));
  }, [getAssemblyCollections]);

  const getAssemblyProducts = useCallback(
    (assembly : Assembly, date : Date) => {
      const collection = getCollection(assembly, date);
      return collection ? getCollectionProducts(collection) : [];
    },
    [getCollection, getCollectionProducts],
  );

  const moveAssemblyToTop = useCallback(async (
    product : Product,
    assembly : Assembly,
  ) => {
    const productAssemblies = getProductAssemblies(product);
    const first = productAssemblies[0];
    if (first.id === assembly.id) return;

    return await addAssemblyToProduct(product, assembly, { before : first });
  }, [getProductAssemblies, addAssemblyToProduct]);

  const moveAssemblyToBottom = useCallback(async (
    product : Product,
    assembly : Assembly,
  ) => {
    const productAssemblies = getProductAssemblies(product);
    const last = productAssemblies[productAssemblies.length - 1];
    if (last.id === assembly.id) return;

    return await addAssemblyToProduct(product, assembly);
  }, [getProductAssemblies, addAssemblyToProduct]);

  const moveAssemblyUp = useCallback(async (
    product : Product,
    assembly : Assembly,
  ) => {
    const productAssemblies = getProductAssemblies(product);
    const index = productAssemblies.findIndex(a => a.id === assembly.id);
    if ([0, -1].includes(index)) return;

    const before = productAssemblies[index - 1];
    return await addAssemblyToProduct(product, assembly, { before });
  }, [getProductAssemblies, addAssemblyToProduct]);

  const moveAssemblyDown = useCallback(async (
    product : Product,
    assembly : Assembly,
  ) => {
    const productAssemblies = getProductAssemblies(product);
    const index = productAssemblies.findIndex(a => a.id === assembly.id);
    if (index === -1 || index === productAssemblies.length - 1) return;

    const before = productAssemblies[index + 2];
    return await addAssemblyToProduct(product, assembly, { before });
  }, [getProductAssemblies, addAssemblyToProduct]);

  const getAssemblyCounts = useCallback(
    (assembly : Assembly, date : Date) => {
      const collection = getCollection(assembly, date);
      return collection ? {
        min : collection.min,
        max : collection.max,
      } : {
        min : 0,
        max : 0,
      };
    },
    [getCollection],
  );

  const isProductCustomisable = useCallback((
    product : Product,
    date : Date,
  ) => {
    const assemblies = getProductAssemblies(product);
    return assemblies.some(
      assembly => getAssemblyProducts(assembly, date).length > 0
    );
  }, [getProductAssemblies, getAssemblyProducts]);

  const validateSelection = useCallback((
    product : Product,
    selection : Selection,
    date : Date,
  ) => {
    let error = '' as '' | 'invalidAssembly' | 'invalidProduct';

    const assemblies = getProductAssemblies(product);
    let assembly = assemblies.find(a => a.id === selection.assemblyId) ?? null;

    if (assembly) {
      const products = getAssemblyProducts(assembly, date);
      if (!products.some(p => p.id === selection.productId)) {
        error = 'invalidProduct';
      }
    } else {
      assembly = assembies?.[selection.assemblyId] ?? null;
      error = 'invalidAssembly';
    }

    return {
      valid : error === '',
      assembly : assembly,
      error,
    };
  }, [assembies, getProductAssemblies, getAssemblyProducts]);

  const validateSelections = useCallback((
    product : Product,
    selections : Selection[],
    date : Date,
  ) => {
    const errors = {} as { [id : number] : {
      key : 'invalidAssembly'
        | 'invalidProduct'
        | 'tooFew'
        | 'tooMany',
      assembly : Assembly,
      product? : Product,
    } };

    const assemblies = getProductAssemblies(product);

    for (const assembly of assemblies) {
      const assemblyId = assembly.id;
      if (!assemblyId) continue;

      const { min, max } = getAssemblyCounts(assembly, date);
      const selected = selections.filter(
        selection => selection.assemblyId === assemblyId
      );

      const count = selected.reduce((sum, s) => sum + s.quantity, 0);
      if (!!max && (count > max)) {
        errors[assemblyId] = {
          key : 'tooMany',
          assembly,
        };
        continue;
      }

      if (count < min) errors[assemblyId] = {
        key : 'tooFew',
        assembly,
      };
    }

    for (const selection of selections) {
      const { valid, assembly, error } = validateSelection(
        product,
        selection,
        date,
      );
      if (!valid && assembly && error) errors[selection.assemblyId] = {
        key : error,
        assembly : assembly,
        product : products?.[selection.productId] ?? undefined,
      };
    }

    return {
      valid : Object.keys(errors).length === 0,
      errors : errors,
    };
  }, [products, validateSelection, getProductAssemblies, getAssemblyCounts]);

  const calculateSelectionPrice = useCallback(
    (selection : Selection) : Currency | null => {
      const assembly = assembies && assembies[selection.assemblyId];
      const product = products && products[selection.productId];
      if (!assembly || !product) return null;

      if (assembly.complimentary) return {
        amount : 0,
        currencyCode : product.price.currencyCode,
        increment : product?.price?.increment,
        calculatedValue : 0,
      };

      return {
        ...product.price,
        amount : product.price.amount * selection.quantity,
        calculatedValue : product.price.amount
          * product.price.increment
          * selection.quantity,
      };
    },
    [assembies, products],
  );

  const generateDefaultOrder = useCallback(() => {
    return {
      ...createDefaultOrder(),
      selections : [],
    } as DraftCustomOrder;
  }, [createDefaultOrder]);

  const findCustomOrder = useCallback(async ({
    address,
    customer,
    serviceChannel,
    location,
    timeSlot,
    iteration,
    division,
  } : {
    address : Address | null,
    customer : Customer | null,
    serviceChannel : ServiceChannel | null,
    location : Location | null,
    timeSlot : TimeSlot | null,
    iteration : number,
    division : number,
  }) => {
    const order = await findOrder({
      address,
      customer,
      serviceChannel,
      location,
      timeSlot,
      iteration,
      division,
    });
    if (!order) return null;
    return customiseOrder(order);
  }, [findOrder, customiseOrder]);

  const getLineItemFulfilment = useCallback((
    lineItem : LineItem,
    order : DraftCustomOrder,
  ) => {
    if (!order.order) return null;
    return listRecords(order.order?.fulfilments).find((f) => (
      f.lineItemId === lineItem.id
        && f.requestedProductId === lineItem.productId
        && !listRecords(order.selections).some(
          (s) => (f.id && s.fulfilmentIds.includes(f.id))
        )
    )) ?? null;
  }, []);

  const getSelectionFulfilment = useCallback((
    selection : Selection,
    order : DraftCustomOrder,
  ) => {
    if (!order.order) return null;

    const lineItem = allLineItems?.[selection.lineItemId];
    return listRecords(order.order?.fulfilments).find((f) => {
      if (f.lineItemId !== selection.lineItemId) return false;

      if (f.id && selection.fulfilmentIds.includes(f.id)) return true;
      if (listRecords(order.selections).some(
        (s) => (f.id && s.fulfilmentIds.includes(f.id))
      )) return false;

      if (lineItem?.productId === f.requestedProductId) return false;

      const preceedingSelections = listRecords(order.selections).filter(
        (s) => s.lineItemId === f.lineItemId
          && (s.id ?? Infinity) < (selection.id ?? Infinity)
      );
      if (preceedingSelections.some(
        (s) => s.productId === f.requestedProductId
      )) return false;

      return f.requestedProductId === selection.productId;
    }) ?? null;
  }, [allLineItems]);

  const listOrphanedFulfilments = useCallback((
    order : DraftCustomOrder,
  ) : {
    fulfilments : Fulfilment[],
    lineItems : LineItem[],
  } => {
    const orderRecord = order.order;
    if (!orderRecord) return ({ fulfilments : [], lineItems : [] });

    const fulfilments = Object.values(orderRecord.fulfilments).filter(
      (fulfilment) => (
        !Object.values(order.lineItems).some(
          (lineItem) => lineItem.id === fulfilment.lineItemId
            && lineItem.productId === fulfilment.requestedProductId
        )
          && !Object.values(order.selections).some(
            (selection) => selection.lineItemId === fulfilment.lineItemId
              && selection.productId === fulfilment.requestedProductId
          )
      )
    );

    const missingItems = fulfilments.map((fulfilment) => {
      const lineItem = fulfilment.lineItemId
        ? allLineItems?.[fulfilment.lineItemId]
        : undefined;
      return {
        ...lineItem ?? orderRecord,
        id : lineItem?.id,
        productId : fulfilment.fulfilledProductId
          ?? fulfilment.requestedProductId,
        quantity : fulfilment.fulfilledQty ?? fulfilment.requestedQty,
        price : fulfilment.price,
        guestCode : '',
      };
    });

    return {
      fulfilments,
      lineItems : missingItems,
    };
  }, [allLineItems]);

  const canEditItem = useCallback((
    order : DraftCustomOrder,
    lineItem : LineItem,
  ) => {
    return (
      !order.order
        || !Object.values(order.order.fulfilments).some(
          (fulfilment) => fulfilment.lineItemId === lineItem.id
        )
    );
  }, []);

  const loadOptions = useCallback(() => {
    load();
    loadProducts();
    loadTags();
    loadOrders();
  }, [load, loadProducts, loadTags, loadOrders]);

  return {
    ...contextHooks,
    loaded : contextHooks.assembliesLoaded
      && contextHooks.collectionsLoaded
      && contextHooks.selectionsLoaded
      && productsLoaded
      && tagsLoaded
      && ordersLoaded,
    load : loadOptions,
    createProductAssembly,
    moveAssemblyToTop,
    moveAssemblyToBottom,
    moveAssemblyUp,
    moveAssemblyDown,
    createAssemblyCollection,
    addProductToCollection,
    removeProductFromCollection,
    getProductAssemblies,
    getAssemblyCollections,
    getCollection,
    getCollectionWindows,
    getCollectionProducts,
    getCollectionTags,
    getCollectionDefaults,
    getProductDefaultCount,
    getTotalDefaultCount,
    getAssemblyProducts,
    getAssemblyCounts,
    findCustomOrder,
    isProductCustomisable,
    validateSelection,
    validateSelections,
    calculateSelectionPrice,
    bulkCreateCustomisedLineItems : bulkCreateSelections,
    bulkUpdateCustomisedLineItems,
    createDefaultOrder : generateDefaultOrder,
    getLineItemFulfilment,
    getSelectionFulfilment,
    listOrphanedFulfilments,
    canEditItem,
    generateDefaultAssembly,
    generateDefaultCollection,
    draftEmptyOrder : draftEmptyCustomOrder,
  }
}

export default useOptions;
