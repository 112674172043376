import styled from '@emotion/styled'

import { Theme } from '#types'

interface IconButtonStyle {
  theme? : Theme;
  margin? : string;
  filled? : boolean;
}

const IconButton = styled.button<IconButtonStyle>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin: ${(props) => props.margin || '0'};
  padding: ${(props) => props.filled ? '1rem' : '0'};
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.color || (props.filled
    ? props.theme.buttons.colours.primary.colour
    : props.theme.buttons.colours.header.colour)};

  background-color: ${(props) => props.filled
    ? props.theme.buttons.colours.primary.bgColour
    : 'transparent'};

  border-radius: 50%;

  &:hover {
    color: ${(props) => props.color || (props.filled
      ? props.theme.buttons.colours.primary.bgColour
      : props.theme.buttons.colours.headerHover.colour)};
    background-color: ${(props) => props.filled
      ? props.theme.bgColours.primary
      : props.theme.buttons.colours.headerHover.bgColour};
    ${(props) => props.filled && `
      border-width: 1px;
      border-style: solid;
      border-color: props.theme.buttons.colours.primary.bgColour;
    `}
  }
`;

export default IconButton;
