import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }
interface ResponsiveStyle extends Style { responsive? : boolean; }

const OverlayMask = styled.div<Style>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.buttons.colours.cart.bgColour};
  opacity: 0.9;
  z-index: 2;
`;

const OverlayView = styled.div<ResponsiveStyle>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.layout.paddingSmall};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;

  ${(props) => props.responsive && `
    @media (max-width: ${props.theme.breakpoints.mobile}) {
      gap: ${props.theme.layout.paddingSmall};
    }
  `}

  * {
    font-family: 'MRKTBOX Font';
    font-size: ${({ theme }) => theme.fonts.sizes.medium};
  }

  button {
    padding: ${({ theme }) => theme.layout.paddingSmall}
      0
      calc(${({ theme }) => theme.layout.paddingSmall} * 0.8);
    white-space: nowrap;
    height: ${({ theme }) => theme.buttons.sizes.default.height};
    width: 80%;

    ${(props) => props.responsive && `
      @media (max-width: ${props.theme.breakpoints.mobile}) {
        font-size: ${props.theme.fonts.sizes.small};
        width: 90%;
        height: ${props.theme.buttons.sizes.small.height};
      }
    `}
  }
`;

const Instructions = styled.div<ResponsiveStyle>`
  margin: ${({ theme }) => theme.layout.paddingSmall}
    ${({ theme }) => theme.layout.paddingSmall}
    0;
  padding: ${({ theme }) => theme.layout.paddingSmall}
    ${({ theme }) => theme.layout.paddingSmall};
  font-family: 'MRKTBOX Font';
  font-size: ${({ theme }) => theme.fonts.sizes.big};
  background-color: ${({ theme }) => theme.bgColours.primary};
  width: 100%;

  span {
    white-space: pre-wrap;
  }

  ${(props) => props.responsive && `
    @media (max-width: ${props.theme.breakpoints.mobile}) {
      padding: calc(${props.theme.layout.paddingSmall} * 0.5)
        ${props.theme.layout.paddingSmall}
    }
  `}
`;

interface ButtonOverlayProps {
  text : string;
  responsive? : boolean;
  children? : React.ReactNode;
}

function ButtonOverlay({
  text,
  responsive = true,
  children,
} : ButtonOverlayProps) {
  return (
    <>
      <OverlayMask />
      <OverlayView responsive={responsive}>
        <Instructions responsive={responsive}>
          <span>{ text }</span>
        </Instructions>
        { children }
      </OverlayView>
    </>
  );
}

export default ButtonOverlay;
