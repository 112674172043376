import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Product, LineItem, Subscription } from '#mrktbox/types';
import { useNavigation, useSubscriptions, useNotes } from '#mrktbox';
import { formatCurrency, formatDateTime, formats } from '#mrktbox/utils';

import { Theme } from '#types';

import useCustomer from '#hooks/useCustomer';
import useRequests from '#hooks/useRequests';

import useModal from '#hooks/useModal';
import useCatalogue from '#hooks/useCatalogue';

import ButtonStyled from '#materials/ButtonStyled';
import Heading from '#materials/Heading';
import { Minus, Plus, Refresh } from '#materials/icons';

import SubscriptionChange from '#components/orders/SubscriptionChange';

interface Style {
  theme? : Theme;
}

const ProductFooterView = styled.div<Style>`
  label: ProductItemFooter;
  flex: 0 0 auto;
  position: relative;
  padding: ${(props) => props.theme.item.desktop.padding};
  background-color: ${(props) => props.theme.bgColours.primary};
  box-shadow: 0 -15px 30px ${(props) => props.theme.overlay.primary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: ${(props) => props.theme.item.mobile.padding};
  }
`;

const ProductFooterDefault = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProductFooterDefaultCircle = styled.div<Style>`
  flex: 0 0 5rem;
  width: 5rem;
  height: 5rem;
  margin-right: ${(props) => props.theme.item.desktop.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: ${(props) => props.theme.item.mobile.padding};
    flex: 0 0 4rem;
    width: 4rem;
    height: 4rem;
  }

  a {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 2.5rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      border-radius: 2rem;
    }
  }

  button {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 2.5rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      border-radius: 2rem;
    }
  }
`;

const ProductFooterDefaultQuantity = styled.div<Style>`
  min-width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-width: 4rem;
    height: 4rem;
  }
`;

const ProductFooterDefaultCount = styled(Heading)`
  display: block;
`;

const ProductFooterDefaultButton = styled.div<Style>`
  flex: 1 1 auto;

  button {
    width: 100%;
    padding: 0;
    height: 5rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      height: 4rem;
    }
  }
`;

const ProductFooterDefaultPrice = styled.span<Style>`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }

  span {
    padding: 0 0.5rem;
  }
`;

const RefreshIcon = styled.span`
  height: 1.5rem;
  margin-right: 1rem;
`;

interface ProductFooterProps {
  product : Product;
  selections : { [id : number] : Product[]; };
  quantity : number;
  setQuantity : (quantity: number) => void;
  note? : string;
  lineItem? : LineItem | null;
  period? : number;
}

function ProductFooter({
  product,
  selections,
  quantity,
  note,
  period,
  lineItem,
  setQuantity,
} : ProductFooterProps) {
  const { navigate, generatePath } = useNavigation();
  const { openModal } = useModal();
  const {
    findLineItemSubscription,
  } = useSubscriptions();
  const {
    createNote,
    updateNote,
    deleteNote,
    generateDefaultNote,
    getLineItemNotes,
    doesProductRequireNote,
  } = useNotes();
  const { customer } = useCustomer();
  const {
    last,
    isProductStocked,
    validateSelected,
    calculatePrice,
  } = useCatalogue();
  const {
    currentOrder,
    addItem,
    updateItem,
    isOrderOpen,
    evaluateReccurence,
    scrollToLineItem,
  } = useRequests();

  const incrementQuantity = useCallback(() => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
  }, [quantity, setQuantity]);

  const decrementQuantity = useCallback(() => {
    const newQuantity = quantity - 1;
    if (newQuantity > 0){
      setQuantity(newQuantity);
    }
  }, [quantity, setQuantity]);

  const finaliseItem = useCallback(async (
    success : boolean,
    newItem : LineItem | null,
  ) => {
    if (note && newItem?.id) {
      const existingNotes = lineItem ? getLineItemNotes(lineItem) : [];
      existingNotes.length
        ? updateNote({
          ...existingNotes[0],
          content : note,
        })
        : createNote({
          ...generateDefaultNote({ customerId : customer?.id }),
          lineItemId : newItem.id,
          content : note,
        });
    }

    if (!note && newItem) {
      const existingNotes = getLineItemNotes(newItem);
      existingNotes.forEach((n) => deleteNote(n));
    }

    if (success) {
      setTimeout(() => newItem?.id && scrollToLineItem(newItem.id), 100);
      navigate(generatePath(last, { openCart : true }));
    }
    return success;
  }, [
    note,
    lineItem,
    scrollToLineItem,
    createNote,
    updateNote,
    deleteNote,
    getLineItemNotes,
    generateDefaultNote,
    customer,
    generatePath,
    navigate,
    last,
  ]);

  const handleUpdateSubscription = useCallback(async (
    target : 'this' | 'future',
  ) => {
    if (!currentOrder || !lineItem) return;
    const response = await updateItem(
      lineItem,
      { quantity },
      selections,
      { target }
    );
    return finaliseItem(!!response?.success, response?.lineItem ?? null);
  }, [
    currentOrder,
    lineItem,
    selections,
    quantity,
    finaliseItem,
    updateItem,
  ]);

  const handleAddSubscription = useCallback(async (
    subscription? : Subscription | null,
  ) => {
    if (!lineItem || !currentOrder) return null;
    const recurrence = subscription ? evaluateReccurence(subscription) : null;

    if (!recurrence || (period !== recurrence)) {
      const response = await updateItem(
        lineItem,
        { quantity },
        selections,
        {
          period,
          target : !recurrence ? 'this' : 'future'
        },
      );
      finaliseItem(!!response?.success, response?.lineItem ?? null);
    }

    openModal(
      <SubscriptionChange
        title="Update subscription, or just this order?"
        prompt={`This is a ${product.name} subscription.\n Would you like to`
          + ' apply changes to this order only'
          + (currentOrder.time
            ? ` (${formatDateTime(currentOrder.time, formats.easy)})`
            : '')
          + ', or all future orders?'}
        selectTarget={handleUpdateSubscription}
      />,
    );
    return null;
  }, [
    lineItem,
    product,
    selections,
    quantity,
    period,
    finaliseItem,
    currentOrder,
    handleUpdateSubscription,
    evaluateReccurence,
    updateItem,
    openModal,
  ]);

  const handleAddToCart = useCallback(async () => {
    const subscription = lineItem && currentOrder
      ? findLineItemSubscription(lineItem, currentOrder):
      null;
    const response = lineItem
      ? ((subscription || period)
        ? await handleAddSubscription(subscription)
        : (currentOrder
          ? await updateItem(lineItem, { quantity }, selections)
          : null))
      : await addItem({
        product,
        selections,
        quantity,
        period,
        forceSplit : !!note,
      });

    finaliseItem(!!response?.success, response?.lineItem ?? null);
  }, [
    product,
    selections,
    quantity,
    period,
    lineItem,
    note,
    finaliseItem,
    handleAddSubscription,
    currentOrder,
    addItem,
    updateItem,
    findLineItemSubscription,
  ]);

  const orderOpen = isOrderOpen();
  const outOfStock = !isProductStocked(product);
  const requiresNote = doesProductRequireNote(product);
  const disableProduct = !validateSelected(product, selections)
    || !orderOpen
    || outOfStock
    || (requiresNote && !note);

  return (
    <ProductFooterView>
      <ProductFooterDefault>
        <ProductFooterDefaultCircle style={{ margin: 0 }}>
          <ButtonStyled
            onClick={decrementQuantity}
            disabled={(quantity <= 1) || !orderOpen}
            size="big"
            colour="primary"
          >
            <Minus size={25} />
          </ButtonStyled>
        </ProductFooterDefaultCircle>
        <ProductFooterDefaultQuantity>
          <ProductFooterDefaultCount size="xBig">
            { quantity }
          </ProductFooterDefaultCount>
        </ProductFooterDefaultQuantity>
        <ProductFooterDefaultCircle>
          <ButtonStyled
            onClick={incrementQuantity}
            disabled={!orderOpen}
            size="big"
            colour="primary"
          >
            <Plus size={25} />
          </ButtonStyled>
        </ProductFooterDefaultCircle>
        <ProductFooterDefaultButton>
          <ButtonStyled
            onClick={handleAddToCart}
            disabled={disableProduct}
            size="big"
          >
            { !!period && (
              <RefreshIcon>
                <Refresh size={14} />
              </RefreshIcon>
            ) }
            { outOfStock
                ? 'Out of Stock'
                : ((requiresNote && !note)
                  ? 'Note Required'
                  : (lineItem
                    ? 'Update Order'
                    : period ? 'Subscribe' : 'Add to Order'))
            }
            { (!lineItem && !outOfStock && (!requiresNote || note)) && (
              <ProductFooterDefaultPrice>
                <span>&mdash;</span>
                { formatCurrency(calculatePrice({
                  product,
                  selections,
                  quantity,
                })) }
                { !!period && <span>/ Order</span> }
              </ProductFooterDefaultPrice>
            ) }
          </ButtonStyled>
        </ProductFooterDefaultButton>
      </ProductFooterDefault>
    </ProductFooterView>
  );
}

export default ProductFooter;
