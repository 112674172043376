import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import { Minus, Plus } from '#materials/icons'

interface Style { theme? : Theme; }
interface QuantityViewStyle extends Style { bgColour? : string; }

const QuantityView = styled.div<QuantityViewStyle>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 9.2rem;

  label {
    display: block;
  }

  button {
    transition: none;
    &:disabled {
      opacity: 0.25;
    }
  }
`;

const QuantityInput = styled.input<Style>`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1.2rem;
  padding: 0;
  border: 0;
  line-height: 1;
  text-align: center;
  color: ${(props) => props.theme.colours.light};
  background-color: ${(props) => props.theme.bgColours.dark};
  border-color: ${(props) => props.theme.bgColours.dark};
  padding-top: ${(props) => props.theme.counts.quantity.paddingTop};
  padding-bottom: ${(props) => props.theme.counts.quantity.paddingBottom};
  font-family: ${(props) => props.theme.counts.quantity.family};
  font-weight: ${(props) => props.theme.counts.quantity.weight};
  font-size: ${(props) => props.theme.counts.quantity.fontSize};
  -webkit-font-smoothing: ${(props) =>
    props.theme.counts.quantity.fontSmoothing};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.counts.quantity.fontSizeMobile};
  }

  &:active,
  &:focus,
  &:disabled,
  &:read-only {
    color: ${(props) => props.theme.buttons.colours.primary.colour};
    background-color: ${(props) =>
      props.theme.buttons.colours.primary.bgColour
    };
    border-color: ${(props) =>
      props.theme.buttons.colours.primary.borderColour
    };
  }
`;

const QuantityIncrement = styled.button<Style>`
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.8rem;
  color: ${(props) => props.theme.colours.primary};
`;

interface QuantityProps {
  id : string;
  label : string;
  count : number;
  setCount : (count : number) => void;
  increment : () => void;
  decrement : () => void;
  incrementDisabled? : boolean;
  decrementDisabled? : boolean;
}

function Quantity({
  id,
  label,
  count,
  setCount,
  increment,
  decrement,
  incrementDisabled,
  decrementDisabled,
} : QuantityProps) {

  const [qty, setQty] = useState(count);

  const handleIncrement = useCallback(
    (evt : React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault();
      increment();
    },
    [increment],
  );

  const handleDecrement = useCallback(
    (evt : React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault();
      decrement();
    },
    [decrement],
  );

  const handleSetQuantity = useCallback(
    (evt : React.ChangeEvent<HTMLInputElement>) => {
      evt.preventDefault();
      setQty(parseInt(evt.target.value));
    },
    [],
  );

  const handleBlur = useCallback(
    (evt : React.FocusEvent<HTMLInputElement>) => {
      evt.preventDefault();
      setCount(qty);
    },
    [qty, setCount],
  );

  useEffect(() => { setQty(count); }, [count]);

  return (
    <QuantityView bgColour="secondary">
      <QuantityIncrement
        style={{ marginLeft: '0.2rem' }}
        onClick={handleDecrement}
        disabled={decrementDisabled}
        aria-label="Decrease quantity"
      >
        <Minus strokeWidth={2} />
      </QuantityIncrement>
      <label htmlFor={label}>
        <QuantityInput
          id={id}
          type="number"
          value={qty}
          onChange={handleSetQuantity}
          disabled={incrementDisabled && decrementDisabled}
          aria-label={label}
          onBlur={handleBlur}
        />
      </label>
      <QuantityIncrement
        style={{ marginRight: '0.2rem' }}
        onClick={handleIncrement}
        disabled={incrementDisabled}
        aria-label="Increase quantity"
      >
        <Plus strokeWidth={2} />
      </QuantityIncrement>
    </QuantityView>
  );
}

export default Quantity;
