import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }

const CartItemCountView = styled.div<Style>`
  position: absolute;
  z-index: 3;
  top: -1rem;
  right: -0.9rem;
  min-width: 2.2rem;
  height: 2.2rem;
  border-radius: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: ${(props) => props.theme.counts.alerts.borderWidth};
  padding-top: ${(props) => props.theme.counts.alerts.paddingTop};
  padding-bottom: ${(props) => props.theme.counts.alerts.paddingBottom};
  color: ${(props) => props.theme.counts.alerts.colour};
  background-color: ${(props) => props.theme.counts.alerts.bgColour};
  border-color: ${(props) => props.theme.counts.alerts.borderColour};

  span {
    display: block;
    line-height: 0;
    font-family: ${(props) => props.theme.counts.alerts.family};
    font-weight: ${(props) => props.theme.counts.alerts.weight};
    font-size: ${(props) => props.theme.counts.alerts.fontSizeMobile};
    -webkit-font-smoothing: ${(props) =>
      props.theme.counts.alerts.fontSmoothing};
  }
`;

interface CartItemCountProps {
  count: number;
}

function CartItemCount({ count } : CartItemCountProps) {
  if (!count) return null;
  return (
    <CartItemCountView>
      <span>{count}</span>
    </CartItemCountView>
  );
}

export default CartItemCount;
