import React from 'react';
import styled from '@emotion/styled';

const Ingredients = styled.p`
  margin: 0 0 1rem;
`;

interface ProductIngredientsProps {
  ingredients: string[];
}

function MenuItemIngredients({ ingredients } : ProductIngredientsProps) {
  return (
    <Ingredients>{
      ingredients.map((ing, i) => {
        switch (i) {
          case 0:
            return ing.charAt(0).toUpperCase() + ing.slice(1);
          case ingredients.length - 1:
            return ' and ' + ing + '.';
          default:
            return ing;
        }
      }).join(', ')
    }</Ingredients>
  );
}

export default MenuItemIngredients;
