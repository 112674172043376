import styled from '@emotion/styled';

import { Theme } from '#types';

interface MessageStyle {
  theme? : Theme;
  colour? : keyof Theme['messages'];
  size? : keyof Theme['fonts']['sizes'];
}

const Message = styled.span<MessageStyle>`
  display: inline-block;
  ${({ theme, colour = 'success', size = 'main' }) => `
    padding: ${size === 'small' ? '0.6em 1.2rem' : '1em 1em'};
    border-radius: ${theme.border.radiusSmall};
    font-size: ${theme.fonts.sizes[size]};
    line-height: ${theme.lineHeight};
    color: ${theme.messages[colour].colour};
    background-color: ${theme.messages[colour].bgColour};
  `}
`;

export default Message;
