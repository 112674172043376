import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import Transition, { TransitionGroup } from '#materials/Transition';

interface Style {
  theme? : Theme;
}

const FormErrorWrapper = styled.div`
  transition: height 0.25s ease;
`;

const FormErrorView = styled.span<Style>`
  outline: 0;
  display: inline-block;
  width: 100%;
  padding: ${(props) =>
    props.theme.inputs.bottomBorderOnly
      ? props.theme.inputs.paddingVertical
      : props.theme.inputs.padding};
  margin: ${(props) => props.theme.inputs.paddingVertical}
    -${(props) => props.theme.border.radiusSmall} 0;
  font-family: ${(props) => props.theme.inputs.family};
  font-weight: ${(props) => props.theme.inputs.weight};
  font-size: ${(props) => props.theme.fonts.sizes.small};
  letter-spacing: ${(props) => props.theme.inputs.letterSpacing};
  text-transform: ${(props) => props.theme.inputs.textTransform};
  -webkit-font-smoothing: ${(props) => props.theme.inputs.fontSmoothing};
  line-height: ${(props) => props.theme.inputs.lineHeight};
  border-radius: ${(props) => props.theme.border.radiusSmall};
  color: ${(props) => props.theme.colours.light};
  background-color: ${(props) => props.theme.bgColours.error};
`

interface FormErrorProps {
  errMsg? : string;
  style? : Object;
}

const FormError = ({ errMsg, style } : FormErrorProps) => {
  return (
    <FormErrorWrapper style={{
      height: errMsg ? '60px' : 0,
    }}>
      <TransitionGroup component={undefined}>
        { errMsg
          ?
          (
            <Transition
              key="form-error"
              classNames="reveal"
              timeout={{ enter: 250, exit: 250 }}
            >
              <FormErrorView style={style}>
                <p>{ errMsg }</p>
              </FormErrorView>
            </Transition>
          )
          : undefined
        }
      </TransitionGroup>
    </FormErrorWrapper>
  )
}

export default FormError;
