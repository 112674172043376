import React from 'react';
import styled from '@emotion/styled';

import { Currency } from '#mrktbox/types';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

interface CheckSummaryItemViewStyle {
  isBold? : boolean;
  theme? : Theme;
}

const CheckSummaryItemView = styled.li<CheckSummaryItemViewStyle>`
  width: 100%;
  margin: 1rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  color: ${(props) =>
    props.theme.colours[props.isBold ? 'primary' : 'secondary']};
  ${(props) =>
    props.isBold ? `font-weight: ${props.theme.boldWeight};` : null}

  span {
    display: block;
  }
`;

interface CheckSummaryItemProps {
  label : string;
  value : Currency;
  isBold? : boolean;
}

function CheckSummaryItem({
  label,
  value,
  isBold = false
} : CheckSummaryItemProps) {
  return (
    <CheckSummaryItemView isBold={isBold}>
      <span>{ label }</span>
      <span>{ formatCurrency(value) }</span>
    </CheckSummaryItemView>
  )
}

export default CheckSummaryItem;
