import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';

import * as Sentry from '@sentry/react';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

import ButtonStyled from '#materials/ButtonStyled';
import ButtonLink from '#materials/ButtonLink';
import Content from '#components/page/Content';
import Main from '#components/page/Main';
import PageContainer from '#components/page/PageContainer';
import PageContent from '#components/page/PageContent';
import PageTitle from '#components/page/PageTitle';
import Header from '#components/header/Header';
import HeaderLogo from '#components/header/HeaderLogo';

interface Style {
  theme : Theme;
}

const ErrorReportView = styled.div<Style>`
  margin: ${(props) => props.theme.layout.padding} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.paddingMobile} 0;
  }
`

const ErrorReportDetails = styled.div<Style>`
  padding: 2rem;
  overflow-x: scroll;
  color: ${(props) => props.theme.error.colour};
  background-color: ${(props) => props.theme.error.bgColour};
  border-radius: ${(props) => props.theme.border.radius};
  margin: ${(props) => props.theme.layout.padding} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.paddingMobile} 0;
  }

  pre {
    margin: 0;
    font-family: Consolas, Menlo, Courier, monospace;
    line-height: 1.8;
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

interface ErrorReportProps {
  error? : Error;
  errorInfo? : React.ErrorInfo;
  eventId? : string;
}

function ErrorReport({
  error,
  errorInfo,
  eventId,
} : ErrorReportProps) {
  const { brand, content, theme } = useConfig();

  const handleReset = useCallback(() => {
    Sentry.flush(2000).then(() => {
      Sentry.close(2000).then(() => {
        localStorage.clear();
        window.location.reload();
      });
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>
          { content.error.title} | {brand.title }
        </title>
      </Helmet>
      <Content hasRouter={false}>
        <Header title={<HeaderLogo />} />
        <Main>
          <PageContainer theme={theme} style={{ maxWidth: '76.8rem' }}>
            <PageTitle {...content.error} />
            <PageContent theme={theme}>
              <p>
                <ButtonStyled onClick={handleReset}>Start Over</ButtonStyled>
              </p>
              <ErrorReportView theme={theme}>
                { eventId && (
                  <p>
                    <ButtonLink
                      onClick={() => Sentry.showReportDialog({ eventId })}
                    >
                      Click here to report this issue
                    </ButtonLink>
                  </p>
                ) }
                <ErrorReportDetails theme={theme}>
                  <pre>
                    { error ? error.toString() : '' }
                    { errorInfo && errorInfo?.componentStack?.toString() }
                  </pre>
                </ErrorReportDetails>
              </ErrorReportView>
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default ErrorReport;
