import React from 'react';
import styled from '@emotion/styled';

import { Currency } from '#mrktbox/types';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

interface Style { theme? : Theme; }

const CheckSummaryTotalView = styled.li<Style>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem 0 0;
  border-width: 0;
  border-style: solid;
  border-color: ${(props) => props.theme.border.colour};
  border-top-width: ${(props) => props.theme.border.width};
  margin: 2rem 0;
  font-weight: ${(props) => props.theme.boldWeight};
  font-size: ${(props) => props.theme.fonts.sizes.big};
  color: ${(props) => props.theme.colours.primary};

  span {
    display: block;
  }
`;

interface CheckSummaryTotalProps {
  label : string;
  value : Currency;
}

function CheckSummaryTotal({ label, value } : CheckSummaryTotalProps) {
  return (
    <CheckSummaryTotalView>
      <span>{ label }</span>
      <span>{ formatCurrency(value) }</span>
    </CheckSummaryTotalView>
  );
}

export default CheckSummaryTotal;
