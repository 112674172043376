import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled'

import { Product } from '#mrktbox/types';

import { Theme } from '#types';

import useRequests from '#hooks/useRequests';

import Transition, { TransitionGroup } from '#materials/Transition';
import { ChevronDown, ChevronUp, Refresh } from '#materials/icons';
import ButtonStyled from '#materials/ButtonStyled';

import ProductIngredients from '#components/products/ProductIngredients';
import ProductNote from '#components/products/ProductNote';

import { capitalize } from '#utils/format';

interface Style {
  theme? : Theme;
}

const ProductAccordionView = styled.div<Style>`
  padding: ${(props) => props.theme.item.desktop.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: ${(props) => props.theme.item.mobile.padding};
  }
`;

const ProductAccordionContainer = styled.div<Style>`
  border-bottom: ${(props) => props.theme.border.width} solid
    ${(props) => props.theme.border.colour};
`;

const ProductAccordionRow = styled.div<Style>`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${(props) => props.theme.border.width} solid
    ${(props) => props.theme.border.colour};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 3.6rem;
  }
`;

interface ProductAccordionRowButtonProps {
  name: string;
  open: string;
  setOpen: any;
  children: any;
}

function ProductAccordionRowButton({
  name,
  open,
  setOpen,
  children,
} : ProductAccordionRowButtonProps){
  const isOpen = name === open;
  const onClick = () => { setOpen(isOpen ? undefined : name) }
  return (
    <ProductAccordionRow as="button" onClick={onClick}>
      { children }
    </ProductAccordionRow>
  );
}

const ProductAccordionLabel = styled.div<Style>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-items: center;
  gap: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }

  span {
    display: block;
  }

  svg {
    position: relative;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      top: -0.1rem;
    }
  }
`;

const ProductAccordionToggleView = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ProductAccordionToggleIcon = styled.div`
  width: 2.4rem;
  padding: 0 0 0 0.5rem;
  margin: 0.2rem 0 0 0;
`;

const ProductAccordionButtonList = styled.div<Style>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0 1.5rem;

  button {
    width: 40rem;
    max-width: 90%;
    height: 4rem;
    font-size: ${(props) => props.theme.fonts.sizes.medium};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0.5rem 0 1rem;

    button {
      font-size: ${(props) => props.theme.fonts.sizes.small};
      height: 3rem;
    }
  }
`;

interface ProductAccordionToggleProps {
  isOpen: boolean;
  children?: any;
}

function ProductAccordionToggle({
  isOpen,
  children,
} : ProductAccordionToggleProps){
  return (
    <ProductAccordionToggleView>
      { children }
      <ProductAccordionToggleIcon>
        { isOpen ? <ChevronUp /> : <ChevronDown /> }
      </ProductAccordionToggleIcon>
    </ProductAccordionToggleView>
  )
}

const ProductAccordionSectionView = styled.div`
  margin: 0 0 0;
`;

interface ProductAccordionSectionProps {
  isOpen : boolean;
  style? : any;
  children : React.ReactNode;
}

function ProductAccordionSection({
  isOpen = false,
  style,
  children,
} : ProductAccordionSectionProps){
  return (
    <TransitionGroup component={undefined}>
      { isOpen
        ? (
          <Transition
            classNames="reveal"
            timeout={{ enter : 250, exit : 250 }}
          >
            <ProductAccordionSectionView style={style}>
              { children }
            </ProductAccordionSectionView>
          </Transition>
        )
        : undefined
      }
    </TransitionGroup>
  )
}

interface ProductAccordionProps {
  product : Product,
  period? : number,
  setPeriod? : (period: number) => void,
  note?: string;
  setNote?: (note : string) => void;
}

function ProductAccordion({
  product,
  note,
  period = 0,
  setNote,
  setPeriod,
} : ProductAccordionProps){
  const { formatRecurrence } = useRequests();
  const [open, setOpen] = useState<string>('');

  const handlePeriod = useCallback((i : number) => {
    setPeriod?.(i);
    setOpen('');
  }, [setPeriod]);

  const ingredients = product.ingredientsList;

  return (
    <ProductAccordionView>
      <ProductAccordionContainer>
        { !!(ingredients && ingredients.length) && (
          <>
            <ProductAccordionRowButton
              name="INGREDIENTS"
              open={open}
              setOpen={setOpen}
            >
              <ProductAccordionLabel>Ingredients</ProductAccordionLabel>
              <ProductAccordionToggle isOpen={open === 'INGREDIENTS'} />
            </ProductAccordionRowButton>
            <ProductAccordionSection isOpen={open === 'INGREDIENTS'}>
              <ProductIngredients ingredients={ingredients} />
            </ProductAccordionSection>
          </>
        )}
        { (note !== undefined) && (
          <>
            <ProductAccordionRowButton
              name="NOTE"
              open={open}
              setOpen={setOpen}
            >
              <ProductAccordionLabel>Note</ProductAccordionLabel>
              <ProductAccordionToggle isOpen={open === 'NOTE'} />
            </ProductAccordionRowButton>
            <ProductAccordionSection isOpen={open === 'NOTE'}>
              <ProductNote note={note} setNote={setNote} />
            </ProductAccordionSection>
          </>
        ) }
        { (!!period || setPeriod) && (
          <>
            <ProductAccordionRowButton
              name="PERIOD"
              open={open}
              setOpen={setOpen}
            >
              <ProductAccordionLabel>
                { !!period && <Refresh size={12}/> }
                { !period
                  ? 'Subscribe'
                  : capitalize(formatRecurrence(period))
                }
              </ProductAccordionLabel>
              <ProductAccordionToggle isOpen={open === 'PERIOD'} />
            </ProductAccordionRowButton>
            <ProductAccordionSection isOpen={open === 'PERIOD'}>
              <ProductAccordionButtonList>
                <ButtonStyled
                  onClick={() => handlePeriod(0)}
                  disabled={period === 0}
                  size='small'
                >
                  One-Time
                </ButtonStyled>
                { [1, 2, 3, 4].map((i) => (
                  <ButtonStyled
                    key={i}
                    onClick={() => handlePeriod(i)}
                    disabled={period === i}
                    size='small'
                  >
                    { capitalize(formatRecurrence(i)) }
                  </ButtonStyled>
                )) }
              </ProductAccordionButtonList>
            </ProductAccordionSection>
          </>
        ) }
      </ProductAccordionContainer>
    </ProductAccordionView>
  );
}

export default ProductAccordion;
