import { useContext } from 'react';

import AreaContext from '#mrktbox/clerk/context/AreaContext';

import useAddressesAPI from '#mrktbox/clerk/hooks/api/useAddressesAPI';

function useAddresses() {
  const {
    areas,
    loaded : areasLoaded,
    load : loadAreas,
    createAddress,
    retrieveAddresses,
    retrieveAddress,
    deleteAddress,
    refreshAreas,
    refreshArea,
    createArea,
    retrieveAreas,
    retrieveArea,
    updateArea,
    deleteArea,
  } = useContext(AreaContext);

  const { searchAddresses } = useAddressesAPI();

  return {
    areas,
    areasLoaded,
    createAddress,
    getAddresses : retrieveAddresses,
    retrieveAddresses,
    retrieveAddress,
    deleteAddress,
    searchAddresses,
    loadAreas,
    refreshAreas,
    refreshArea,
    createArea,
    retrieveAreas,
    retrieveArea,
    updateArea,
    deleteArea,
  };
}

export default useAddresses;
