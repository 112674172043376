import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';

import { BrowserRouter } from '#mrktbox';

import useConfig from '#hooks/useConfig';
import useChat from '#hooks/useChat';

import GlobalStyles from 'GlobalStyles';
import ErrorBoundary from 'ErrorBoundary';
import AppRoutes from 'AppRoutes';
import Providers from 'Providers';
import { checkCache } from 'version';

const ROBOTS = process.env.REACT_APP_ROBOTS || 'noindex, nofollow';

export const AppView = styled('div')`
  label: AppView;

  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

function App() {
  const { brand, theme } = useConfig();
  useChat();

  useEffect(() => { checkCache(); }, []);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AppView id="app">
          <Helmet>
            <title>MRKTBOX</title>
            <meta name="description" content={brand.description} />
            <meta name="robots" content={ROBOTS} />
            <link href={theme.fonts.body.url} rel="stylesheet" />
            { (
              theme.fonts.headings.url &&
              theme.fonts.body.url !== theme.fonts.headings.url
            ) && (
              <link href={theme.fonts.headings.url} rel="stylesheet" />
            ) }
          </Helmet>
          <ErrorBoundary>
            <BrowserRouter>
              <Providers>
                <AppRoutes />
              </Providers>
            </BrowserRouter>
          </ErrorBoundary>
        </AppView>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
