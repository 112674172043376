import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { LineItem } from '#mrktbox/types';
import { useOptions, useNotes } from '#mrktbox';
import { formats, formatDateTime } from '#mrktbox/utils';

import { Theme } from '#types';

import useModal from '#hooks/useModal';
import useSidebar from '#hooks/useSidebar';
import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';
import useLogin from '#hooks/useLogin';

import ButtonStyled from '#materials/ButtonStyled';
import ButtonLink from '#materials/ButtonLink';
import IconTextButton from '#materials/IconTextButton';
import TimeButton from '#materials/TimeButton';
import FulfilmentButton from '#materials/FulfilmentButton';
import ServiceButton from '#materials/ServiceButton';
import { AlertCircle, CheckCircle } from '#materials/icons';

import SidebarHeader from '#components/sidebar/SidebarHeader';
import SidebarFooter from '#components/sidebar/SidebarFooter';
import Login from '#components/auth/Login';
import OrderTime from '#components/orders/OrderTime';
import CartItem from '#components/cart/CartItem';
import Totals from '#components/cart/Totals';
import Subtotal from '#components/cart/Subtotal';
import Adjustment from '#components/cart/Adjustment';
import Tax from '#components/cart/Tax';

import { formatAddress } from '#mrktbox/utils';

interface Style { theme? : Theme; }
interface OverlayStyle extends Style { show? : boolean; }
interface AlertStyle extends Style {
  noPad? : boolean;
  error? : boolean;
}

const SidebarOverlay = styled.div<OverlayStyle>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  width: ${(props) => props.theme.breakpoints.mobile};
  height: 100%;
  z-index: 1000;
  opacity: 0.2;
  background-color: ${(props) => props.theme.colours.dark};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const Alert = styled.p<AlertStyle>`
  display: flex;
  width: 100%;
  margin: 0.5rem 0 0;
  ${(props) => !props.noPad && 'padding: 0;'}
  align-items: top;
  justify-content: left;
  gap: 0.5rem;

  ${ (props) => props.error && `
    color: ${props.theme.colours.error};
  ` }

  & > svg {
    flex-shrink: 0;
  }

  & > span {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    line-height: 1.4;
  }
`;

const OrderButton = styled.p<Style>`
  margin: 0 0 1rem;
  font-size: ${(props) => props.theme.fonts.sizes.small};
`;

const SidebarCartView = styled.div`
  position: relative;
  height: 0;
  flex-grow: 1;
  flex-shrink: 1;
`;

const SidebarCart = styled.div<Style>`
  width: ${(props) => props.theme.breakpoints.mobile};
  height: 100%;
  overflow-y: auto;
  padding: 0 0 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CartInsetShadowTop = styled.div<OverlayStyle>`
  position: absolute;
  width: 100%;
  height: 1rem;
  z-index: 100;

  opacity: ${(props) => (props.show ? 1 : 0)};
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0)
  );

  transition: opacity 0.5s;
`;

const CartInsetShadowBottom = styled.div<OverlayStyle>`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1rem;
  z-index: 100;

  opacity: ${(props) => (props.show ? 1 : 0)};
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0)
  );

  transition: opacity 0.5s;
`;

const SidebarCartContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0 0 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const SidebarCartItems = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 0 2rem;
`;

function Cart() {
  const { openModal } = useModal();
  const { closeSidebar } = useSidebar();
  const { validateSelections } = useOptions();
  const { doesProductRequireNote, getLineItemNotes } = useNotes();
  const { signing, user } = useLogin();
  const { allProducts } = useCatalogue();
  const {
    waiting,
    serviceChannel,
    address,
    location,
    time,
    cutoff,
    currentOrder,
    canUpdateOrder,
    canUpdateItem,
    hasCardOnFile,
    getUpcomingOrders,
    isOrderStocked,
    isOrderAvailable,
    getLineItems,
    getSubtotal,
    getAdjustments,
    getTaxes,
    getTotal,
    newOrder,
    generateOrderUrl,
  } = useRequests();

  const lineItems = useMemo(() => getLineItems(), [getLineItems]);
  const subtotal = useMemo(() => getSubtotal(), [getSubtotal]);
  const adjustments = useMemo(() => getAdjustments(), [getAdjustments]);
  const taxes = useMemo(() => getTaxes(), [getTaxes]);
  const total = useMemo(() => getTotal(), [getTotal]);
  const [validTime, setValidTime] = useState(false);
  const [busy, setBusy] = useState<{[id : number] : Boolean}>({});
  const [insetTop, setInsetTop] = useState(false);
  const [insetBottom, setInsetBottom] = useState(false);

  const setItemBusy = useCallback((item : LineItem, b : Boolean) => {
    const itemId = item.id;
    if (!itemId) return;
    if (busy[itemId] === b) return;
    setBusy((prev) => ({ ...prev, [itemId]: b }));
  }, [busy]);

  const handleSelectTime = useCallback(() => {
    openModal((<OrderTime />));
  }, [openModal]);

  const handleContinue = useCallback(() => {
    if (!serviceChannel || (!address && !location)) return;
    if (time === null || !validTime) handleSelectTime();
    else if (!user) openModal((<Login />));
  }, [
    validTime,
    handleSelectTime,
    serviceChannel,
    address,
    location,
    time,
    user,
    openModal,
  ]);

  const handleNewOrder = useCallback(() => {
    newOrder();
    closeSidebar();
  }, [newOrder, closeSidebar]);

  const handleScroll = useCallback(() => {
    const scrollContainer = document.getElementById('cart-view');
    const scrollContent = document.getElementById('cart-contents');
    if (!scrollContainer || !scrollContent) return;

    const containerHeight = scrollContainer.clientHeight ?? 0;
    const contentHeight = scrollContent.clientHeight ?? 0;
    const scrollable = contentHeight > containerHeight;

    if (!scrollable) {
      setInsetTop(false);
      setInsetBottom(false);
      return;
    }

    setInsetTop(scrollContainer.scrollTop > 0);
    setInsetBottom(
      scrollContainer.scrollTop + containerHeight < contentHeight,
    );
  }, []);

  const canUpdate = useMemo(() => (
    canUpdateOrder()
      && lineItems.every((item) => canUpdateItem(item))
  ), [lineItems, canUpdateOrder, canUpdateItem]);

  const customisationValid = useMemo(() => {
    if (!currentOrder || !allProducts) return true;

    const time = currentOrder.time ?? new Date();
    for (const lineItem of getLineItems(currentOrder)) {
      const product = allProducts[lineItem.productId] ?? null;
      if (!product) continue;

      const selections = Object.values(currentOrder.selections)
        .filter((s) => s.lineItemId === lineItem.id);
      if (!validateSelections(product, selections, time).valid) return false

      const requiresNote = doesProductRequireNote(product);
      if (requiresNote && !getLineItemNotes(lineItem).some((n) => n.content)) {
        return false;
      }
    }
    return true;
  }, [
    currentOrder,
    allProducts,
    getLineItems,
    validateSelections,
    doesProductRequireNote,
    getLineItemNotes,
  ]);

  useEffect(() => {
    const cutoffInterval = setInterval(() => {
      setValidTime(!cutoff || new Date().getTime() < cutoff.getTime());
    }, 100);
    return () => clearInterval(cutoffInterval);
  }, [time, cutoff]);

  useEffect(() => {
    const scrollInterval = setInterval(handleScroll, 500);
    return () => clearInterval(scrollInterval);
  }, [insetTop, insetBottom, handleScroll]);

  const upcomingOrderCount = getUpcomingOrders().filter(
    (order) => currentOrder
      && (
        order.customer?.id !== currentOrder.customer?.id
          || order.serviceChannel?.id !== currentOrder.serviceChannel?.id
          || order.address?.id !== currentOrder.address?.id
          || order.location?.id !== currentOrder.location?.id
          || order.time?.getTime() !== currentOrder.time?.getTime()
      ),
  ).length;

  const title = currentOrder?.address
    ? `Delivery Order`
    : (currentOrder?.location
      ? `Pickup Order`
      : 'Your Current Order');
  const preface = currentOrder?.order?.id
    ? `Order #${currentOrder.order.id}`
    : '';

  const available = isOrderAvailable();
  const stocked = isOrderStocked();
  const error = !available || !stocked || !customisationValid;
  const started = serviceChannel || address || location || time;

  const needsChannel = !serviceChannel;
  const needsAddress = (!needsChannel) && (!address && !location);
  const needsTime = (!needsChannel && !needsAddress)
    && (time === null || !validTime);
  const needsUser = (!needsChannel && !needsAddress && !needsTime) && !user;
  const needsCard = !serviceChannel?.requireCheckout && !hasCardOnFile();
  const needsItems = !lineItems || !lineItems.length;
  const complete = !needsChannel && !needsAddress && !needsTime && !needsUser;
  const paid = currentOrder?.order
      ? !!Object.values(currentOrder.order.payments).length
      : false;

  const errorLable = (!available || !stocked)
    ? 'Unavailable Items'
    : (!customisationValid)
      ? 'Invalid Items'
      : '';

  const continueLabel = needsChannel
    ? 'Select Order Type'
    : needsAddress
      ? 'Select Delivery or Pickup'
      : needsTime
        ? 'Select a Time'
        : needsUser
          ? 'Sign In'
          : needsCard
            ? 'Add Payment Method'
            : (!serviceChannel.requireCheckout || paid)
              ? 'View Order'
              : 'Checkout';

  const continueHref = needsChannel
    ? '/order-types/'
    : needsAddress
      ? '/locations/'
      : (needsTime || needsUser)
        ? '#'
        : needsCard
          ? '/credit-cards/'
          : currentOrder
            ? generateOrderUrl(currentOrder, true)
            : '#';

  const isBusy = Object.values(busy).some((b) => b);

  const prompt = useMemo(() => {
    if (!available) return (
      <span>
        { `One or more items in this order are currently unavailable for
          ${currentOrder?.serviceChannel?.name ?? 'this order type'}
          . Remove unavailable items or ` }
        <ButtonLink
          href='/order-types/'
          colour='error'
        >
          try another order type
        </ButtonLink>
        { ` to procceed.` }
      </span>
    );
    if (!stocked) return (
      <span>
        { `One or more items in this order are currently out of stock.
          Remove out of stock items to procceed.` }
      </span>
    );
    if (!customisationValid) return (
      <span>
        { `One or more items in this order require additional customisation
          to continue.` }
      </span>
    );
    if (error) return (
      <span>
        { `Opps! Something went wrong. Please contact us for assistance.` }
      </span>
    );
    if (needsUser) return (
      <span>
        { `To proceed with your order, please sign in or create an account.` }
      </span>
    );
    if (currentOrder?.complete && needsCard) return (
      <span>
        { `To proceed with your order, please add a payment method.` }
      </span>
    );
    if (!serviceChannel?.requireCheckout && complete && !needsItems) {
      let appendType = '';
      if (serviceChannel.name.toLowerCase().endsWith('order')) appendType = 's';
      else if (!serviceChannel.name.toLowerCase().endsWith('orders'))
        appendType = ' orders';

      return (
        <span>
          { `Your order has been recieved. ${serviceChannel.name + appendType}`
            + ` are confirmed and proccessed automatically. You may edit or
            cancel this order freely until `
            + (cutoff
              ? formatDateTime(cutoff, formats.easy)
              : 'the cutoff time')
            + `.` }
        </span>
      );
    }
  }, [
    available,
    stocked,
    customisationValid,
    error,
    needsUser,
    currentOrder,
    needsCard,
    needsItems,
    complete,
    serviceChannel,
    cutoff,
  ]);

  const showPrompt = error
    || needsUser
    || (currentOrder?.complete && needsCard)
    || (!serviceChannel?.requireCheckout
      && complete
      && !needsItems);

  return (
    <>
      <SidebarOverlay show={waiting} />
      <SidebarHeader title={waiting ? 'Updating...' : title} preface={preface}>
        { !!upcomingOrderCount && (
          <OrderButton>
            { `You have ${upcomingOrderCount} other ` }
            <ButtonLink href='/orders/'>
              { ` upcoming order${upcomingOrderCount > 1 ? 's' : ''}` }
            </ButtonLink>
          </OrderButton>
        ) }
        { !!serviceChannel && (
          <ServiceButton
            serviceChannel={serviceChannel}
            href='/order-types/'
            disabled={waiting || !canUpdate}
            compact
          />
        ) }
        { !!(address || location) && (
          <FulfilmentButton
            name={(address ? formatAddress(address) : location?.name) ?? ''}
            href='/locations/'
            disabled={waiting || !canUpdate}
            compact
          />
        ) }
        { (time !== null) && (
          <TimeButton
            datetime={time}
            onClick={handleSelectTime}
            disabled={waiting || !canUpdate}
            compact
          />
        ) }
        { !!cutoff && (
          <IconTextButton
            colour="header"
            text={
              !canUpdate
                ? 'This order has been confirmed and cannot be reshceduled'
                : (validTime
                  ? (complete
                    ? 'Add, edit, or remove items until '
                    : 'Order before '
                  ) + formatDateTime(cutoff, formats.easy)
                  : 'The cutoff time for this order has passed')
            }
          />
        ) }
        { needsItems && (
          <Alert noPad>
            <span>
              { 'This order is empty. Start adding items' }
              { (needsChannel || needsAddress || needsTime)
                ? ', or'
                : ' to proceed.'}
              { needsChannel && ' select an order type' }
              { needsAddress && ' select a delivery or pickup location' }
              { needsTime && ' select a time' }
              { (needsChannel || needsAddress || needsTime)
                ? ' to schedule your order.'
                : '.'}
            </span>
          </Alert>
        ) }
      </SidebarHeader>
      <SidebarCartView>
        <CartInsetShadowTop show={insetTop} />
        <CartInsetShadowBottom show={insetBottom} />
        <SidebarCart id={'cart-view'} onScroll={handleScroll}>
          <SidebarCartContainer id={'cart-contents'}>
            <SidebarCartItems>
              { lineItems && Object.values(lineItems).map((item, i) => (
                !!item && (
                  <CartItem
                    key={`${item?.id ?? item?.productId ?? 'item'}-${i}`}
                    item={item}
                    order={currentOrder}
                    isFulfilment={
                      currentOrder
                        ? !Object.keys(currentOrder?.lineItems)
                          .includes(`${item.id}`)
                        : false
                    }
                    setBusy={(b) => setItemBusy(item, b)}
                  />
                )
              )) }
            </SidebarCartItems>
          </SidebarCartContainer>
        </SidebarCart>
      </SidebarCartView>
      { !!total && (
        <Totals total={total?.total}>
          { !!subtotal && (
            <Subtotal label="Subtotal" subtotal={subtotal.total} bold />
          ) }
          { adjustments && adjustments.map((adjustment) => (
            <Adjustment key={adjustment.adjustmentId} total={adjustment} />
          )) }
          { taxes && taxes.map((tax) => (
            <Tax key={tax.taxId} total={tax} />
          )) }
        </Totals>
      ) }
      <SidebarFooter>
        { showPrompt && (
          <Alert error={error}>
            { complete
              ? (<CheckCircle size={16} />)
              : (<AlertCircle size={16} />)
            }
            { prompt }
          </Alert>
        ) }
        { (!complete || !needsItems) && (
          <ButtonStyled
            size="big"
            href={continueHref}
            onClick={handleContinue}
            disabled={error
              || waiting
              || isBusy
              || signing
            }
            colour={ error ? 'error' : 'primary' }
            fullwidth
          >
            { error
              ? errorLable
              : (isBusy ? 'Confirm Subscription' : continueLabel)
            }
          </ButtonStyled>
        ) }
        { (started && (!serviceChannel?.requireCheckout || canUpdate)) && (
          <ButtonStyled
            size="big"
            colour="secondary"
            onClick={handleNewOrder}
            fullwidth
          >
            New Order
          </ButtonStyled>
        ) }
      </SidebarFooter>
    </>
  );
}

export default Cart;
