import React, { useEffect } from 'react';
import { useParams, useSearchParams } from '#mrktbox';

import useChat from '#hooks/useChat';

import ProductInfo from '#slices/ProductInfo';

function ProductPage() {
  const urlParams = useParams<{ productId? : string; }>();
  const [searchParams] = useSearchParams();
  const { setBottom } = useChat();

  const parsedId = parseInt(urlParams.productId || '0');
  const id = isNaN(parsedId) ? urlParams.productId : parsedId;

  const itemId = parseInt(searchParams.get('lineItemId') ?? '');
  const lineItemId = isNaN(itemId) ? undefined : itemId;

  useEffect(() => {
    setBottom(80)
    return () => { setBottom(20) }
  });

  return (
    <>
      { id &&
        <ProductInfo productId={id} lineItemId={lineItemId} />
      }
    </>
  );
}

export default ProductPage;
