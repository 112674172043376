import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useFocus from '#hooks/useFocus';
import useSidebar from '#hooks/useSidebar';

import Transition, { TransitionGroup } from '#materials/Transition';

import SidebarOverlay from '#components/sidebar/SidebarOverlay';
import SidebarClose from '#components/sidebar/SidebarClose';

interface Style { theme? : Theme; }

const SidebarView = styled.aside<Style>`
  position: fixed;
  z-index: 17;
  top: 0;
  bottom: 0;
  right: 0;
  max-width: ${(props) => props.theme.breakpoints.mobile};
  background-color: ${(props) => props.theme.bgColours.primary};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }

  > div {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

function Sidebar() {
  const { open, content, closeSidebar } = useSidebar();
  const sidebar = useRef<HTMLDivElement>(null)

  const { takeFocus, returnFocus } = useFocus({
    container : sidebar,
    onEscape : closeSidebar,
    focusInput : false,
  });

  return (
    <>
      <SidebarOverlay />
      <TransitionGroup component={null}>
        {open ? (
          <Transition
            key="sidebar"
            classNames="sidebar"
            timeout={{ enter: 250, exit: 250 }}
            onEntered={takeFocus}
            onExited={returnFocus}
            nodeRef={sidebar}
          >
            <SidebarView ref={sidebar}>
              <div>
                <SidebarClose />
                { content }
              </div>
            </SidebarView>
          </Transition>
        ) : null}
      </TransitionGroup>
    </>
  );
}

export default Sidebar;
