import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import Text from '#materials/Text';
import Preface from '#materials/Preface';

interface Style { theme? : Theme; }

const CheckoutSectionView = styled('div')<Style>`
  margin: 4rem 0 0;

  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 0;
  }

  & > p {
    margin: 0.5em 0 0;
    @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
      font-size: ${(props) => props.theme.fonts.sizes.small};
    }
  }
`;

const CheckoutSectionPreface = styled(Preface)`
  font-size: ${(props) => props.theme.fonts.sizes.small};
  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`;

interface CheckoutSectionProps {
  title? : string;
  subtitle? : string;
  children? : React.ReactNode;
  style? : React.CSSProperties;
}

const CheckoutSection = ({
  title,
  subtitle,
  children,
  style,
} : CheckoutSectionProps) => {
  return (
    <CheckoutSectionView style={style}>
      { title ? (
        <CheckoutSectionPreface as="div" color="tertiary">
          { title }
        </CheckoutSectionPreface>
      ) : null }
      {subtitle ? (
        <Text as="p" size="small">
          { subtitle }
        </Text>
      ) : null}
      { children }
    </CheckoutSectionView>
  );
}

export default CheckoutSection;
