import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style {
  theme? : Theme;
}

export const FormWrapper = styled('div')<Style>`
  max-width: ${(props) =>
    props.theme.cards.form.bgColor === 'transparent' ? '54rem' : '60rem'};
  border-style: solid;
  border-width: ${(props) => props.theme.cards.form.borderWidth};
  border-color: ${(props) => props.theme.cards.form.borderColor};
  border-radius: ${(props) => props.theme.cards.form.borderRadius};
  background-color: ${(props) => props.theme.cards.form.bgColor};
  box-shadow: ${(props) => props.theme.cards.form.boxShadow};
  padding: 4.5rem ${(props) => props.theme.layout.padding};
  padding: ${(props) =>
    props.theme.cards.form.bgColor === 'transparent'
      ? '0'
      : props.theme.layout.padding};
  margin: 0 auto ${(props) => props.theme.layout.margin};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border: 0;
    box-shadow: none;
    padding: ${(props) =>
      props.theme.cards.form.bgColor === 'transparent'
        ? '0'
        : props.theme.layout.paddingMobile};
    margin: 0 auto ${(props) => props.theme.layout.marginMobile};
  }
`;

interface FormSubmitStyle { compact? : boolean }

export const FormSubmit = styled('div')<FormSubmitStyle>`
  display: flex;
  width: fit-content;
  ${(props) => props.compact
    ? 'margin: 0 auto;'
    : 'margin: 3rem auto 0;'
  }
  text-align: center;

  button + button {
    margin: 0 0 0 1rem;
  }
`;

const InputView = styled.div`
  width: 100%;
  margin: 2rem 0 0;

  button + button {
    margin-left: 1rem;
  }
`;

interface FormProps {
  error? : React.ReactNode;
  buttons? : React.ReactNode;
  compact? : boolean;
  onSubmit? : () => void;
  children? : React.ReactNode;
}

function Form({
  error,
  buttons,
  compact = false,
  onSubmit,
  children,
} : FormProps) {
  const handleSubmit = useCallback((e : React.FormEvent<HTMLFormElement>) => {
    if (onSubmit) onSubmit();
    e.preventDefault();
  }, [onSubmit]);

  return (
    <form onSubmit={handleSubmit}>
      { error }
      <InputView>
        { children }
      </InputView>
      <FormSubmit compact={compact} >
        { buttons }
      </FormSubmit>
    </form>
  );
}

export default Form;
