import React, { createContext, useCallback } from 'react';

import { Service, Schedule, TimeSlot, Window } from '#mrktbox/clerk/types';
import { serializeIndex, deserializeIndex } from '#mrktbox/clerk/utils/data';
import {
  serializeDateTime,
  deserializeDateTime,
} from '#mrktbox/clerk/utils/date';

import useData, {
  DataIndex,
  useLoad,
  useRefreshIndex,
  useRefresh,
  useRetrieveIndex,
  useRetrieve,
  useChange,
  useDelete,
  useRelate,
} from '#mrktbox/clerk/hooks/useData';
import useTimeSlotsAPI from '#mrktbox/clerk/hooks/api/useTimeSlotsAPI';

export type ScheduleIndex = DataIndex<Schedule>;
export type TimeSlotIndex = DataIndex<TimeSlot>;

const MAX_AGE = 1000 * 60 * 60;

function serializeWindow(window : Window | null) {
  if (!window) return null;
  return { ...window, start : serializeDateTime(window.start) }
}

function serializeTimeSlot(timeSlot : TimeSlot | null) {
  if (!timeSlot) return null;
  return {
    ...timeSlot,
    start : serializeDateTime(timeSlot.start),
    nextWindow : serializeDateTime(timeSlot.nextWindow),
    windows : serializeIndex(timeSlot.windows, serializeWindow),
  }
}

function serializeSchedule(schedule : Schedule) {
  return {
    ...schedule,
    timeSlots : serializeIndex(schedule.timeSlots, serializeTimeSlot),
  }
}

function deserializeWindow(window : any | null) : Window | null {
  if (!window) return null;
  return { ...window, start : deserializeDateTime(window.start) }
}

function deserializeTimeSlot(timeSlot : any | null) : TimeSlot | null {
  if (!timeSlot) return null;
  return {
    ...timeSlot,
    start : deserializeDateTime(timeSlot.start),
    nextWindow : deserializeDateTime(timeSlot.nextWindow),
    windows : deserializeIndex(timeSlot.windows, deserializeWindow),
  }
}

function deserializeSchedule(schedule : any) : Schedule {
  return {
    ...schedule,
    timeSlots : deserializeIndex(schedule.timeSlots, deserializeTimeSlot),
  }
}

const ScheduleContext = createContext({
  schedules: null as DataIndex<Schedule> | null,
  timeSlots: null as DataIndex<TimeSlot> | null,
  loaded: false,
  load: () => {},
  createSchedule: async (schedule : Schedule) => null as Schedule | null,
  refreshSchedules: async () => null as ScheduleIndex | null,
  refreshSchedule: async (id : number) => null as Schedule | null,
  retrieveSchedules: async () => null as ScheduleIndex | null,
  retrieveSchedule: async (id : number) => null as Schedule | null,
  updateSchedule: async (schedule : Schedule) => null as Schedule | null,
  deleteSchedule: async (schedule : Schedule) => null as boolean | null,
  addScheduleToService: async (
    schedule : Schedule,
    service : Service,
  ) => null as boolean | null,
  removeScheduleFromService: async (
    schedule : Schedule,
    service : Service,
  ) => null as boolean | null,
  createTimeSlot: async (timeSlot : TimeSlot) => null as TimeSlot | null,
  refreshTimeSlots: async () => null as TimeSlotIndex | null,
  refreshTimeSlot: async (id : number) => null as TimeSlot | null,
  retrieveTimeSlots: async () => null as TimeSlotIndex | null,
  retrieveTimeSlot: async (id : number) => null as TimeSlot | null,
  updateTimeSlot: async (timeSlot : TimeSlot) => null as TimeSlot | null,
  deleteTimeSlot: async (timeSlot : TimeSlot) => null as boolean | null,
  addTimeSlotToSchedule: async (
    schedule : Schedule,
    timeSlot : TimeSlot,
  ) => null as boolean | null,
  removeTimeSlotFromSchedule: async (
    schedule : Schedule,
    timeSlot : TimeSlot,
  ) => null as boolean | null,
  createWindow: async (window : Window) => null as Window | null,
});

interface ScheduleProviderProps {
  children : React.ReactNode;
}

export function ScheduleProvider({
  children,
} : ScheduleProviderProps) {
  const {
    createTimeSlot,
    retrieveTimeSlots,
    retrieveTimeSlot,
    updateTimeSlot,
    deleteTimeSlot,
    createWindow,
    addTimeSlotToSchedule,
    removeTimeSlotFromSchedule,
    createSchedule,
    retrieveSchedules,
    retrieveSchedule,
    updateSchedule,
    deleteSchedule,
    addScheduleToService,
    removeScheduleFromService,
  } = useTimeSlotsAPI();

  const {
    data : schedules,
    lastUpdated : lastUpdatedSchedules,
    dispatch : dispatchSchedules,
  } = useData<Schedule>({
    storageKey : 'schedules',
    serializer : serializeSchedule,
    deserializer : deserializeSchedule,
  });

  const newShedule = useChange({
    dispatch : dispatchSchedules,
    change : createSchedule,
  });
  const refreshSchedules = useRefreshIndex({
    dispatch : dispatchSchedules,
    retrieve : retrieveSchedules,
  });
  const refreshSchedule = useRefresh({
    dispatch : dispatchSchedules,
    retrieve : retrieveSchedule,
  });
  const getSchedules = useRetrieveIndex({
    data : schedules,
    timestamp : lastUpdatedSchedules,
    maxAge : MAX_AGE,
    refresh : refreshSchedules,
  });
  const getSchedule = useRetrieve({
    data : schedules,
    timestamp : lastUpdatedSchedules,
    maxAge : MAX_AGE,
    refresh : refreshSchedule,
  });
  const amendSchedule = useChange({
    dispatch : dispatchSchedules,
    change : updateSchedule,
  });
  const removeSchedule = useDelete({
    dispatch : dispatchSchedules,
    delete : deleteSchedule,
  });

  const addService = useRelate({
    dispatch : dispatchSchedules,
    relate : addScheduleToService,
    callback : refreshSchedules,
  });
  const removeService = useRelate({
    dispatch : dispatchSchedules,
    relate : removeScheduleFromService,
    callback : refreshSchedules,
  });

  const {
    data : timeSlots,
    dispatch : dispatchTimeSlots,
    lastUpdated : lastUpdatedTimeSlots,
  } = useData<TimeSlot>({
    storageKey : 'timeSlots',
    serializer : serializeTimeSlot,
    deserializer : deserializeTimeSlot,
  });

  const newTimeSlot = useChange({
    dispatch : dispatchTimeSlots,
    change : createTimeSlot,
  });
  const refreshTimeSlots = useRefreshIndex({
    dispatch : dispatchTimeSlots,
    retrieve : retrieveTimeSlots,
  });
  const refreshTimeSlot = useRefresh({
    dispatch : dispatchTimeSlots,
    retrieve : retrieveTimeSlot,
  });
  const getTimeSlots = useRetrieveIndex({
    data : timeSlots,
    timestamp : lastUpdatedTimeSlots,
    maxAge : MAX_AGE,
    refresh : refreshTimeSlots,
  });
  const getTimeSlot = useRetrieve({
    data : timeSlots,
    timestamp : lastUpdatedTimeSlots,
    maxAge : MAX_AGE,
    refresh : refreshTimeSlot,
  });
  const amendTimeSlot = useChange({
    dispatch : dispatchTimeSlots,
    change : updateTimeSlot,
  });
  const removeTimeSlot = useDelete({
    dispatch : dispatchTimeSlots,
    delete : deleteTimeSlot,
  });

  const disptachWindow = useCallback(
    (action : { data : DataIndex<Window> }) => {
      if (!action.data) return;
      Object.values(action.data).forEach((window) => {
        if (window?.timeSlotId) {
          refreshSchedule(window.timeSlotId);
          if (!schedules) return;

          Object.values(schedules).forEach((schedule) => {
            if (!schedule?.id) return;
            const timeSlotIds = Object.keys(schedule.timeSlots);
            if (timeSlotIds.includes(`${window.timeSlotId}`)) {
              refreshSchedule(schedule.id);
            }
          });
        }
      });
    },
    [schedules, refreshSchedule],
  );

  const addTimeSlot = useRelate({
    dispatch : dispatchSchedules,
    relate : addTimeSlotToSchedule,
    callback : refreshSchedules,
  });
  const dropTimeSlot = useRelate({
    dispatch : dispatchSchedules,
    relate : removeTimeSlotFromSchedule,
    callback : refreshSchedules,
  });
  const addWindow = useChange({
    dispatch : disptachWindow,
    change : createWindow,
  });

  const { loaded: schedulesLoaded, load: loadSchedules } = useLoad({
    data : schedules,
    loader : refreshSchedules,
  });
  const { loaded: timeSlotsLoaded, load: loadTimeSlots } = useLoad({
    data : timeSlots,
    loader : refreshTimeSlots,
  });

  const load = useCallback(() => {
    loadSchedules();
    loadTimeSlots();
  }, [loadSchedules, loadTimeSlots]);

  const context = {
    schedules,
    timeSlots,
    loaded : schedulesLoaded && timeSlotsLoaded,
    load,
    createSchedule : newShedule,
    refreshSchedules,
    refreshSchedule,
    retrieveSchedules : getSchedules,
    retrieveSchedule : getSchedule,
    updateSchedule : amendSchedule,
    deleteSchedule : removeSchedule,
    addScheduleToService : addService,
    removeScheduleFromService : removeService,
    createTimeSlot : newTimeSlot,
    refreshTimeSlots,
    refreshTimeSlot,
    retrieveTimeSlots : getTimeSlots,
    retrieveTimeSlot : getTimeSlot,
    updateTimeSlot : amendTimeSlot,
    deleteTimeSlot : removeTimeSlot,
    addTimeSlotToSchedule : addTimeSlot,
    removeTimeSlotFromSchedule : dropTimeSlot,
    createWindow : addWindow,
  }

  return (
    <ScheduleContext.Provider value={context}>
      { children }
    </ScheduleContext.Provider>
  );
}

export default ScheduleContext;
