import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { useAuth, useNavigation } from '#mrktbox';

import { Theme } from '#types';

import useModal from '#hooks/useModal';
import useSidebar from '#hooks/useSidebar';

import {
  CreditCard,
  Info,
  LogOut,
  MapPin,
  PlusCircle,
  ShoppingBag,
  UserCircle,
} from '#materials/icons';

import Login from '#components/auth/Login';

const HELP_LINK = process.env.REACT_APP_HELP_LINK;

interface Style {
  theme? : Theme;
}

const NavView = styled.nav<Style>`
  position: fixed;
  z-index: 102;
  top: 0;
  bottom: 0;
  right: 0;
  width: 28rem;
  max-width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${(props) => props.theme.bgColours.primary};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const NavContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const NavItems = styled.nav`
  width: 100%;
  padding: 2rem 0 2rem;
`;

const NavItem = styled.button<Style>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  line-height: 0;
  padding: 1rem 1rem 1rem 3rem;
  color: ${(props) => props.theme.links.dark.colour};

  &:hover {
    color: ${(props) => props.theme.links.dark.hover};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      color: ${(props) => props.theme.links.dark.colour};
    }
  }
`;

const NavIcon = styled.span`
  display: block;
  line-height: 0;
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 1.7rem 0 0;
  line-height: 0;
`;

const NavTitle = styled.span<Style>`
  display: block;
  flex-grow: 1;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  line-height: ${(props) => props.theme.fonts.body.lineHeight};
`;

function Nav() {
  const { navigate } = useNavigation();
  const { openModal } = useModal();
  const { closeSidebar } = useSidebar();
  const { checkAuth, logout } = useAuth();

  const closeGo = useCallback((evt : any, path : string) => {
    evt.target.blur();
    evt.preventDefault();
    evt.stopPropagation();
    closeSidebar();
    if (path.startsWith('http')) {
      window.open(path, '_blank');
      return;
    }

    navigate(path);
  }, [navigate, closeSidebar])

  const handleLogin = useCallback(() => {
    openModal(<Login />);
    closeSidebar();
  }, [openModal, closeSidebar]);

  const handleLogout = useCallback(() => {
    navigate('/', );
    closeSidebar();
    logout();
  }, [logout, closeSidebar, navigate]);

  const isAuthenticated = checkAuth({ redirect: null });

  const navButtons = isAuthenticated ? [
    {
      icon: <UserCircle />,
      title: 'Account',
      path: '/profile/',
    },
    {
      icon: <ShoppingBag />,
      title: 'Orders',
      path: '/orders/',
    },
    {
      icon: <MapPin />,
      title: 'Addresses',
      path: '/addresses/',
    },
    {
      icon: <CreditCard />,
      title: 'Credit Cards',
      path: '/credit-cards',
    },
  ] : [
    {
      icon: <PlusCircle />,
      title: 'Sign Up',
      path: '/signup/',
    },
  ];

  if (HELP_LINK) {
    navButtons.push({
      icon: <Info />,
      title: 'Help',
      path: HELP_LINK,
    });
  }

  return (
    <NavView>
      <NavContainer>
        <NavItems>
          { navButtons.map((i) => (
            <NavItem key={i.path} onClick={(evt) => closeGo(evt, i.path)}>
              <NavIcon>{i.icon}</NavIcon>
              <NavTitle>{i.title}</NavTitle>
            </NavItem>
          )) }
        </NavItems>
          { isAuthenticated
            ? <NavItem onClick={handleLogout}>
                <NavIcon><LogOut /></NavIcon>
                <NavTitle>Sign Out</NavTitle>
              </NavItem>
            : <NavItem onClick={handleLogin}>
                <NavIcon><UserCircle /></NavIcon>
                <NavTitle>Log In</NavTitle>
              </NavItem>
          }
      </NavContainer>
    </NavView>
  );
}

export default Nav;
