import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import Transition, { TransitionGroup } from '#materials/Transition';

const ModalOverlayView = styled('div')<{ theme? : Theme }>`
  position: fixed;
  z-index: 108;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.overlay.dark};
`

interface ModalOverlayProps {
  show: boolean;
}

function ModalOverlay({ show } : ModalOverlayProps) {
  return (<TransitionGroup component={null}>
    {show ? (
      <Transition
        key="overlay"
        classNames="md"
        timeout={{ enter: 250, exit: 250 }}
      >
        <ModalOverlayView />
      </Transition>
    ) : null}
  </TransitionGroup>);
}

export default ModalOverlay;
