import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { useCards } from '#mrktbox';

import { Theme } from '#types';

import Row from '#materials/Row';
import Box from '#materials/Box';
import CreditCards from '#materials/icons/CreditCards';

import { CreditCard } from '#mrktbox/types';

import ButtonLink from '#materials/ButtonLink';

interface Style { theme? : Theme; }

const CardNumber = styled.p<Style>`
  font-family: ${(props) => props.theme.fonts.mono.family};
`;

interface CreditCardDetailsProps {
  creditCards : CreditCard[];
  buttonTitle? : string;
}

function CreditCardDetails({ creditCards } : CreditCardDetailsProps) {
  const { deleteCreditCard } = useCards();

  const handleDeleteCreditCard = useCallback(
    (creditCard : CreditCard) => async () => {
      await deleteCreditCard(creditCard);
    },
    [deleteCreditCard],
  );

  return !!creditCards && (
    creditCards.map((creditCard) => {
      let iconKey : 'amex' | 'mastercard' | 'visa' | undefined;
      switch (creditCard.brand) {
        case 'AMERICAN_EXPRESS':
          iconKey = 'amex';
          break;
        case 'VISA':
          iconKey = 'visa';
          break;
        case 'MASTERCARD':
          iconKey = 'mastercard';
          break;
      }

      return (
        <Box key={creditCard.id}>
          <Row
            key={`credit-card${creditCard.id}`}
            icon={(<CreditCards brand={iconKey} />)}
            content={(
              <>
                <p className="title">{creditCard.brand}</p>
                <CardNumber>**** **** **** {creditCard.last4}</CardNumber>
                <p>
                  <ButtonLink
                    onClick={handleDeleteCreditCard(creditCard)}
                  >
                    remove
                  </ButtonLink>
                </p>
              </>
            )}
          />
        </Box>
      );
    })
  );
}

export default CreditCardDetails;
