import React from 'react';
import styled from '@emotion/styled';

import { Product, Assembly } from '#mrktbox/types';
import { Theme } from '#types';

import ProductOptionsNavScroll
  from '#components/products/ProductOptionsNavScroll';

interface Style { theme? : Theme; }
interface ProductOptionsNavViewStyle extends Style {
  stickyTop? : number;
}

const ProductOptionsNavView = styled.div<ProductOptionsNavViewStyle>`
  label: ProductOptionsNavView;
  position: sticky;
  z-index: 8;
  top: ${(props) => (props.stickyTop ?? 0) - 2}px;
  height: fit-content;
  width: 100%;
  margin: 1rem 0 0 0;

  border-color: ${(props) => props.theme.border.colour};
`;

const ProductOptionsNavInner = styled.div<Style>`
  width: 100%;
  position: relative;
  border-style: solid;
  border-color: ${(props) => props.theme.border.colour};
  border-width: 0;
  border-bottom-width: ${(props) => props.theme.border.width};
  background-color: ${(props) => props.theme.bgColours.primary};
`;

const ProductOptionsNavContainer = styled.div<Style>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: end;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface ProductOptionsNavProps {
  assemblies : Assembly[];
  selections : { [id : number] : Product[] }
  scrollOffset : number;
  scrollRef? : React.RefObject<HTMLDivElement>;
}

function ProductOptionsNav({
  assemblies,
  selections,
  scrollOffset,
  scrollRef,
} : ProductOptionsNavProps) {

  return (
    <ProductOptionsNavView stickyTop={scrollOffset}>
      <ProductOptionsNavInner>
        <ProductOptionsNavContainer>
          <ProductOptionsNavScroll
            assemblies={assemblies}
            selections={selections}
            scrollOffset={scrollOffset}
            scrollRef={scrollRef}
          />
        </ProductOptionsNavContainer>
      </ProductOptionsNavInner>
    </ProductOptionsNavView>
  );
}

export default ProductOptionsNav;
