import React, { useEffect, useRef } from 'react';

import { Outlet, useIntegrations, useCustomers, useNotes } from '#mrktbox';

import { ModalProvider } from '#context/ModalContext';
import { SidebarProvider } from '#context/SidebarContext';

import useRequests from '#hooks/useRequests';

import Modal from '#components/modal/Modal';
import Sidebar from '#components/sidebar/Sidebar';
import ProfileHeader from '#components/profile/ProfileHeader';

function Catalogue() {
  const { load : loadIntegrations } = useIntegrations();
  const { load : loadCustomers } = useCustomers();
  const { load : loadNotes } = useNotes();
  const { refresh, load : loadRequests } = useRequests();

  const intializer = useRef(false);

  useEffect(() => {
    loadIntegrations();
    loadCustomers();
    loadRequests();
    loadNotes();
  }, [loadIntegrations, loadCustomers, loadRequests]);

  useEffect(() => {
    if (intializer.current) return;
    intializer.current = true;
    refresh();
  }, [refresh]);

  return (
    <>
      <ModalProvider>
        <SidebarProvider>
          <>
            <Modal />
            <Sidebar />
            <ProfileHeader />
            <Outlet />
          </>
        </SidebarProvider>
      </ModalProvider>
    </>
  );
}

export default Catalogue;
