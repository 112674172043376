import React from 'react';
import styled from '@emotion/styled';

import { ProjectedOrder } from '@mrktbox/clerk/types';

import { Theme } from '#types';

import OrderCard from '#components/orders/OrderCard';

interface Style { theme? : Theme; }

const OrdersListView = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem -1rem;
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.25s forwards;
`;

const OrdersListItem = styled.div<Style>`
  width: 36rem;
  max-width: 100%;
  padding: 0 1rem;
  margin: 0 0 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

interface OrdersListProps {
  orders : ProjectedOrder[];
  pageCount? : number,
}

function OrdersList({
  orders,
  pageCount = 0,
} : OrdersListProps) {

  return (
    <>
      <OrdersListView>
        { orders.slice(0, pageCount || orders.length).map((order, index) => {
          return (
            <OrdersListItem key={order.order?.id || `-${index}`}>
              <OrderCard order={order} />
            </OrdersListItem>
          )
        }) }
      </OrdersListView>
    </>
  );
}

export default OrdersList;
