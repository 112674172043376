import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';

import {
  useProducts,
  useServices,
  useScheduling,
  useOrders,
  useOptions,
  useSubscriptions,
} from '#mrktbox';
import {
  DataIndex,
  Address,
  Customer,
  CreditCard,
  ServiceChannel,
  Service,
  Location,
  Window,
  TimeSlot,
  Schedule,
  ScheduleSelection,
  Product,
  LineItem,
  Selection,
  Subscription,
  DraftOrder,
  ProjectedOrder,
  isTimeSlot,
  isAddress,
  isServiceChannel,
  isLocation,
} from '#mrktbox/types';
import {
  listRecords,
  serializeIndex,
  deserializeIndex,
  serializeDateTime,
  deserializeDateTime,
} from '#mrktbox/utils';

import useCustomer from '#hooks/useCustomer';

const WEEKS = 4;

interface TimeOption {
  start : Date;
  end : Date;
  cutoff? : Date;
}

type setConfig<T> = (
  config : T,
  options? : {
    moveItems? : boolean,
    target? : 'this' | 'future',
  },
) => Promise<boolean>;

function serializeWindow(window : Window | null) {
  if (!window) return null;
  return { ...window, start : serializeDateTime(window.start) }
}

export function serializeTimeSlot(timeSlot : TimeSlot | null) {
  if (!timeSlot) return null;
  return {
    ...timeSlot,
    start : serializeDateTime(timeSlot.start),
    nextWindow : serializeDateTime(timeSlot.nextWindow),
    windows : serializeIndex(timeSlot.windows, serializeWindow),
  }
}

function deserializeWindow(window : any | null) : Window | null {
  if (!window) return null;
  return { ...window, start : deserializeDateTime(window.start) }
}

export function deserializeTimeSlot(timeSlot : any | null) : TimeSlot | null {
  if (!timeSlot) return null;
  return {
    ...timeSlot,
    start : deserializeDateTime(timeSlot.start),
    nextWindow : deserializeDateTime(timeSlot.nextWindow),
    windows : deserializeIndex(timeSlot.windows, deserializeWindow),
  }
}

function calculateLeadTime(
  services : Service[],
  options? : { optimistic? : boolean },
) {
  const optimistic = options?.optimistic ?? true;
  return services.reduce((acc, service) => {
    const leadtime = optimistic
      ? (service.cutoffTime ?? service.leadTime ?? 0)
      : (service.leadTime ?? service.cutoffTime ?? 0);
    return acc === null
      ? leadtime
      : (leadtime ? Math.min(acc ?? 0, leadtime) : acc)
  }, null as number | null) ?? 0;
}

function calculateCutoff(order : DraftOrder | null) {
  if (!order?.time || !order.service) return null;
  return new Date(order.time.getTime() - calculateLeadTime([order.service]));
}

function getStorage() : {
  address : Address | null,
  serviceChannel : ServiceChannel | null,
  location : Location | null,
  timeSlot : TimeSlot | null,
  timeSlotIteration : number,
  timeSlotDivision : number,
} {
  const storage = localStorage.getItem('currentOrder');
  if (!storage) return {
    address : null,
    serviceChannel : null,
    location : null,
    timeSlot : null,
    timeSlotIteration : 0,
    timeSlotDivision : 0,

  };

  const parsed = JSON.parse(storage);
  const address = parsed.address;
  const serviceChannel = parsed.serviceChannel;
  const location = parsed.location;
  const timeSlot = deserializeTimeSlot(parsed.timeSlot ?? null);
  const timeSlotIteration = parsed.timeSlotIteration ?? 0;
  const timeSlotDivision = parsed.timeSlotDivision ?? 0;
  return {
    address : (address && isAddress(address)) ? address : null,
    serviceChannel : (serviceChannel && isServiceChannel(serviceChannel))
      ? serviceChannel
      : null,
    location : (location && isLocation(location)) ? location : null,
    timeSlot : timeSlot,
    timeSlotIteration,
    timeSlotDivision,
  };
}

function setStorage(order : {
  address : Address | null,
  serviceChannel : ServiceChannel | null,
  location : Location | null,
  timeSlot : TimeSlot | null,
  timeSlotIteration : number,
  timeSlotDivision : number,
}) {
  localStorage.setItem('currentOrder', JSON.stringify({
    ...order,
    timeSlot : serializeTimeSlot(order.timeSlot),
  }));
}

const RequestContext = createContext({
  waiting : false as boolean,
  proposedChanges : false as boolean,
  address : null as Address | null,
  serviceChannel : null as ServiceChannel | null,
  location : null as Location | null,
  timeSlot : null as TimeSlot | null,
  timeSlotIteration : null as number | null,
  timeSlotDivision : null as number | null,
  time : null as Date | null,
  lineItems : null as DataIndex<LineItem> | null,
  orders : [] as ProjectedOrder[] | null,
  availableServices : [] as Service[],
  availableServiceChannels : [] as ServiceChannel[],
  availableLocations : [] as Location[],
  availableSchedules : [] as Schedule[],
  timeOptions : [] as TimeOption[],
  fixedWindows : false,
  cutoff : null as Date | null,
  currentOrder : null as ProjectedOrder | null,
  proposeTime : (config : {
    address? : Address | null,
    location? : Location | null,
    starting? : Date | null,
  }) => ({ time : null, selection : null }) as {
    time : Date | null,
    selection : ScheduleSelection | null,
  },
  updateOrder : ((config : {
    address? : Address | null,
    serviceChannel? : ServiceChannel | null,
    location? : Location | null,
    timeSlot? : TimeSlot | null,
    iteration? : number,
    division? : number,
  }) => {}) as setConfig<{
    address? : Address | null
    serviceChannel? : ServiceChannel | null
    location? : Location | null
    timeSlot? : TimeSlot | null
    iteration? : number
    division? : number
  }>,
  setAddress : ((address) => {}) as setConfig<Address | null>,
  setServiceChannel : ((channel) => {}) as setConfig<ServiceChannel | null>,
  setLocation : ((location) => {}) as setConfig<Location | null>,
  setTimeSlot : ((option) => {}) as setConfig<{
    timeSlot : TimeSlot | null,
    iteration? : number,
    division? : number,
  }>,
  resetProposedChanges : () => {},
  addItem : async ({
    product,
    selections,
    quantity,
    period,
    forceSplit,
  } : {
    product : Product,
    selections? : { [id : number] : Product[] },
    quantity : number,
    period? : number,
    forceSplit? : boolean,
  }) => null as {
    success : boolean,
    lineItem : LineItem | null,
    selections : Selection[] | null,
    subscription : Subscription | null,
  } | null,
  updateItem : async (
    item : LineItem,
    patch? : { quantity? : number },
    selections? : { [id : number] : Product[] },
    subscriptionOptions? : {
      period? : number,
      target? : 'this' | 'future' | 'following',
    },
  ) => null as {
    success : boolean,
    lineItem : LineItem | null,
    selections : Selection[] | null,
    subscription : Subscription | null,
  } | null,
  removeItem : async (
    item : LineItem,
    options? : {
      target? : 'this' | 'future',
      isFulfilment? : boolean,
    },
  ) => false as boolean,
  canUpdateOrder : (order? : DraftOrder) => false as boolean,
  canUpdateItem : (item : LineItem) => false as boolean,
  calculateCutoff : (order : DraftOrder | null) => null as Date | null,
  findOrder : (config : {
    address? : Address | null,
    serviceChannel? : ServiceChannel | null,
    location? : Location | null,
    timeSlot? : TimeSlot | null,
    iteration? : number,
    division? : number,
  }) => null as DraftOrder | null,
  getOrder : (config : {
    address? : Address | null,
    serviceChannel? : ServiceChannel | null,
    location? : Location | null,
    time? : Date | null,
  }) => null as DraftOrder | null,
  newOrder : () => {},
  editOrder : (order : DraftOrder) => {},
  claimItems : async (
    customer : Customer,
    options? : { token? : string },
  ) => {},
  payOrder : async ({ order, card, token } : {
    order : ProjectedOrder,
    card? : CreditCard,
    token? : string,
  }) => false as boolean,
  refresh : async () => {},
});

interface RequestProviderProps {
  children : React.ReactNode;
}

export function RequestProvider({ children } : RequestProviderProps) {
  const { retrieveProductsBulk } = useProducts();
  const { services, retrieveServiceChannels } = useServices();
  const {
    timeSlots,
    findNextIteration,
    checkSchedule,
    calculateTime,
    calculateDuration,
   } = useScheduling();
  const {
    lineItems,
    refreshLineItems,
    refreshOrders,
    deleteFulfilment,
    evaluateOptions,
    claimGuestItems,
    payOrders,
  } = useOrders();
  const {
    assemblies,
    refreshSelections,
    getProductAssemblies,
    getCollection,
    isProductCustomisable,
    getLineItemFulfilment,
  } = useOptions();
  const {
    projectedOrders : orders ,
    refreshSubscriptions,
    createLineItem,
    updateLineItem,
    deleteLineItem,
    updateOrder,
    draftEmptyOrder,
    isOrderRecurring,
    isLineItemRecurring,
    determineTrueIteration,
    generateOrder,
  } = useSubscriptions();

  const { customer } = useCustomer();

  const [waitingFor, waitFor] = useReducer((
    tokens : any[],
    payload : { token : any, remove? : boolean }
  ) => {
    if (!payload.remove) return [...tokens, payload.token];
    else return tokens.filter(p => p !== payload.token);
  }, []);
  const waiting = waitingFor.length > 0;

  const [loaded, setLoaded] = useState<boolean>(false);
  const [updateTime, setUpdateTime] = useState<boolean>(false);

  const [currentConfig, setCurrentConfig] = useState<{
    address : Address | null,
    serviceChannel : ServiceChannel | null,
    location : Location | null,
    timeSlot : TimeSlot | null,
    iteration : number,
    division : number,
    time : Date | null,
  }>({
    address : null,
    serviceChannel : null,
    location : null,
    timeSlot : null,
    iteration : 0,
    division : 0,
    time : null,
  });

  const [address, setAddress] = useState<Address | null>(null);
  const [channel, setChannel] = useState<ServiceChannel | null>(null);
  const [location, setLocation] = useState<Location | null>(null);
  const [timeSlot, setTimeSlot] = useState<TimeSlot | null>(null);
  const [iteration, setIteration] = useState<number>(0);
  const [division, setDivision] = useState<number>(0);
  const [time, setTime] = useState<Date | null>(null);

  const [availChannels, setAvailChannels] = useState<ServiceChannel[]>([]);
  const [availLocations, setAvailLocations] = useState<Location[]>([]);
  const [availSchedules, setAvailSchedules] = useState<Schedule[]>([]);
  const [availServices, setAvailServices] = useState<Service[]>([]);

  const [timeOptions, setTimeOptions] = useState<TimeOption[]>([]);
  const [fixedWindows, setFixedWindows] = useState(false);
  const [cutoff, setCutoff] = useState<Date | null>(null);

  const [currentOrder, setCurrentOrder] = useState<ProjectedOrder | null>(null);

  const release = useCallback(
    (token : any) => setTimeout(() => (
      waitFor({ token, remove : true })
    ), 100),
    [waitFor],
  );
  const wait = useCallback(() => {
    const token = {};
    waitFor({ token });
    setTimeout(() => release(token), 30000);
    return token;
  }, [waitFor, release]);

  const getOrderStorage = useCallback(() => {
    if (loaded) return;
    setLoaded(true);

    const storedOrder = getStorage();
    if (!storedOrder) return;

    setAddress(storedOrder.address);
    setChannel(storedOrder.serviceChannel);
    setLocation(storedOrder.location);
    setTimeSlot(storedOrder.timeSlot);
    setIteration(storedOrder.timeSlotIteration);
    setDivision(storedOrder.timeSlotDivision);

    const timeSlot = storedOrder.timeSlot ?? currentConfig.timeSlot;
    const iteration = storedOrder.timeSlotIteration ?? currentConfig.iteration;
    const division = storedOrder.timeSlotDivision ?? currentConfig.division;
    const time = (
      timeSlot
        && isTimeSlot(timeSlot)
        && (typeof iteration === 'number')
        && (typeof division === 'number')
    ) ? calculateTime(timeSlot, iteration, division) : null;

    setCurrentConfig({
      ...currentConfig,
      ...storedOrder,
      iteration : storedOrder.timeSlotIteration ?? currentConfig.iteration,
      division : storedOrder.timeSlotDivision ?? currentConfig.division,
      time,
    })
  }, [loaded, currentConfig, calculateTime]);

  const setOrderStorage = useCallback(() => {
    if (!loaded) return;
    setStorage({
      address : currentConfig.address,
      serviceChannel : currentConfig.serviceChannel,
      location : currentConfig.location,
      timeSlot : currentConfig.timeSlot,
      timeSlotIteration : currentConfig.iteration,
      timeSlotDivision : currentConfig.division,
    });
  }, [loaded, currentConfig]);

  const canUpdateOrder = useCallback((
    order : DraftOrder | null = currentOrder
  ) => {
    if (!order) return false;

    if (!order.serviceChannel?.requireCheckout) {
      const cutoffTime = calculateCutoff(order);
      if (!cutoffTime) return true
      return new Date() < cutoffTime;
    }

    return order.order
      ? !Object.keys(order.order.payments).length
      : true;
  }, [currentOrder]);

  const canUpdateItem = useCallback((item : LineItem) => {
    if (!canUpdateOrder()) return false;

    if (!item.id) return false;
    const itemIds = currentOrder
      ? Object.keys(currentOrder.lineItems).map(Number)
      : [];
    if (
      !itemIds.includes(item.id)
        && (!currentOrder?.order
          || Object.keys(currentOrder.order.fulfilments)
            .includes(item.id.toString()))
    ) return false;

    const fulfilment = currentOrder
      ? getLineItemFulfilment(item, currentOrder)
      : null;
    return (!fulfilment || fulfilment.status === 'pending');
  }, [currentOrder, canUpdateOrder, getLineItemFulfilment]);

  const refresh = useCallback(async () => {
    const lock = wait();
    await Promise.all([
      refreshOrders(),
      refreshLineItems(),
      refreshSelections(),
      refreshSubscriptions(),
    ]);
    release(lock);
  }, [
    wait,
    release,
    refreshOrders,
    refreshLineItems,
    refreshSelections,
    refreshSubscriptions,
  ]);

  const updateItem = useCallback(async (
    item : LineItem,
    selections? : { [id : number] : Product[] },
    subscriptionOptions? : {
      period? : number,
      target? : 'this' | 'future' | 'following',
    },
  ) => {
    if (subscriptionOptions?.period === 0) {
      subscriptionOptions = {
        period : 1,
        target : 'following',
      };
      item = { ...item, quantity : 0 };
    }

    const lineItemId = item.id;
    if (!currentOrder || !lineItemId) return null;
    const trueIteration = (
      isLineItemRecurring(item)
        && currentOrder.timeSlot
        && item.timeSlotId === currentOrder.timeSlot.id
    ) ? determineTrueIteration(
      item,
      currentOrder.timeSlot,
      currentOrder.timeSlotIteration,
    ) : null;

    const existingSelections = Object.values(currentOrder.selections).filter(
      sel => sel.lineItemId === item.id,
    )
    const keepSelections = selections === undefined
      ? existingSelections
      : existingSelections.reduce((acc, s) => {
        const selected = selections[s.assemblyId];
        const count = selected ? selected.reduce((c, p) => (
          c + (p.id === s.productId ? 1 : 0)
        ), 0) : 0;

        if (!count) return acc;
        if (count === s.quantity) return [...acc, s];
        return [
          ...acc,
          { ...s, quantity : count },
        ];
      }, [] as Selection[]);
    const newSelections = selections === undefined
      ? []
      : Object.entries(selections).reduce((acc, [i, products]) => {
        return [
          ...acc,
          ...products.reduce((a, p) => {
            const productId = p.id;
            if (!productId) return a;

            const existing = keepSelections.find(
              s => s.assemblyId === Number(i) && s.productId === productId,
            );
            if (existing) return a;

            const matching = a.find(s => s.productId === p.id);
            if (matching) {
              matching.quantity += 1;
              return a;
            }
            return [
              ...a,
              {
                lineItemId : lineItemId,
                productId : productId,
                assemblyId : Number(i),
                quantity : 1,
                fulfilmentIds : [],
              }
            ];
          }, [] as Selection[])
        ];
      }, [] as Selection[]);

    const lock = wait();
    const response = (await updateLineItem(
      currentOrder,
      {
        ...item,
        addressId : currentOrder.address?.id ?? null,
        customerId : customer?.id ?? null,
        serviceChannelId : currentOrder.serviceChannel?.id ?? null,
        locationId : currentOrder.location?.id ?? null,
        timeSlotId : currentOrder.timeSlot?.id ?? null,
      },
      [
        ...keepSelections,
        ...newSelections,
      ],
      subscriptionOptions
        ? {
          ...subscriptionOptions,
          startIteration : trueIteration
            ? (subscriptionOptions.target === 'following'
              ? trueIteration + 1
              : trueIteration)
            : undefined,
          endIteration : subscriptionOptions.target === 'this'
            ? trueIteration
            : null,
        } : (trueIteration ? {
          startIteration : trueIteration,
          endIteration : trueIteration,
        } : undefined),
    ));
    release(lock);
    return response;
  }, [
    customer,
    currentOrder,
    wait,
    release,
    updateLineItem,
    isLineItemRecurring,
    determineTrueIteration,
  ]);

  const amendItem = useCallback(async (
    item : LineItem,
    patch : { quantity? : number } = {},
    selections? : { [id : number] : Product[] },
    subscriptionOptions? : { period? : number },
  ) => {
    return await updateItem({
      ...item,
      ...patch,
    }, selections, subscriptionOptions);
  }, [updateItem]);

  const addItem = useCallback(async ({
    product,
    selections,
    quantity,
    period,
    forceSplit,
  } : {
    product : Product,
    selections? : { [id : number] : Product[] },
    quantity : number,
    period? : number,
    forceSplit? : boolean,
  }) => {
    if (!currentOrder) return null;
    if (!product.id) return null;

    const customisable = isProductCustomisable(product, time ?? new Date())
      && getProductAssemblies(product)
        .some((a) => {
          const collection = getCollection(a, time ?? new Date());
          return collection?.productIds.length && !(
            collection.min > 0
              && (collection.max === collection.min)
              && (collection.productIds.length === 1)
          );
        });
    if (!forceSplit && !customisable) {
      const matchingItems = Object.values(currentOrder.lineItems)
        .filter(item => item?.productId === product.id);
      const matchingSubscriptions = period
        ? Object.values(currentOrder.subscriptions)
          .filter(sub => matchingItems.some((i) => i.id === sub.lineItemId)
            && sub.period === period)
        : [];

      const matchingItem = matchingSubscriptions.length
        ? matchingItems.find(i => i.id === matchingSubscriptions[0].lineItemId)
        : matchingItems.find(i => !Object.values(currentOrder.subscriptions)
          .some(sub => sub.lineItemId === i.id))
      if (matchingItem) {
        return await amendItem(
          matchingItem,
          { quantity : matchingItem.quantity + quantity }
        );
      }
    }

    const selected = selections ? Object.entries(selections).reduce(
      (acc, [i, products]) => {
        const assembly = assemblies && assemblies[i];
        const assemblyId = assembly?.id;
        if (!assemblyId) return acc;

        products.forEach(product => {
          const existing = acc.find(sel => (
            (sel.assemblyId === assembly.id)
              && (sel.productId === product.id)
          ));
          if (existing) {
            existing.quantity += 1;
            return acc;
          } else {
            acc.push({
              lineItemId : -1,
              productId : product.id ?? NaN,
              assemblyId : assemblyId,
              quantity : 1,
              fulfilmentIds : [],
            });
          }
        });
        return acc;
      },
      [] as Selection[],
    ) : undefined;

    const lock = wait();
    const response = await createLineItem(
      currentOrder,
      {
        id : -1,
        addressId : currentOrder.address?.id ?? null,
        customerId : customer?.id ?? null,
        serviceChannelId : currentOrder.serviceChannel?.id ?? null,
        locationId : currentOrder.location?.id ?? null,
        timeSlotId : currentOrder.timeSlot?.id ?? null,
        productId : product.id,
        quantity,
        price : { ...product.price },
        timeSlotIteration : currentOrder.timeSlotIteration ?? 0,
        timeSlotDivision : currentOrder.timeSlotDivision ?? 0,
        guestCode : '',
      },
      selected,
      { period },
    );
    release(lock);

    return response;
  }, [
    customer,
    currentOrder,
    time,
    assemblies,
    wait,
    release,
    getProductAssemblies,
    getCollection,
    isProductCustomisable,
    createLineItem,
    amendItem,
  ]);

  const updateLineItems = useCallback(async (configs : {
    address? : Address | null,
    customer? : Customer | null,
    serviceChannel? : ServiceChannel | null,
    location? : Location | null,
    timeSlot? : TimeSlot | null,
    iteration? : number,
    division? : number,
  }, options : {
    target? : 'this' | 'future',
  }) => {
    if (!currentOrder) return false;

    const lock = wait();
    const response = await updateOrder(
      {
        ...currentOrder,
        ...configs,
        ...((configs.iteration !== undefined)
          && { timeSlotIteration : configs.iteration }),
        ...((configs.division !== undefined)
          && { timeSlotDivision : configs.division }),
      },
      (isOrderRecurring(currentOrder) && currentOrder.timeSlot) ? {
        target : options.target,
        targetTime : {
          timeSlot : currentOrder.timeSlot,
          iteration : currentOrder.timeSlotIteration,
          division : currentOrder.timeSlotDivision,
        }
      } : {},
    );
    release(lock);
    return response.success;
  }, [currentOrder, wait, release, updateOrder, isOrderRecurring]);

  const updateConfig = useCallback(<T extends any>(
    configure : (config : T) => {
      address? : Address | null,
      serviceChannel? : ServiceChannel | null,
      location? : Location | null,
      timeSlot? : TimeSlot | null,
      iteration? : number,
      division? : number,
    }
  ) => async (
    config : T,
    options : {
      moveItems? : boolean,
      target? : 'this' | 'future',
    } = {},
  ) => {
    const configs = configure(config);

    const newAddress = configs.address !== undefined
      ? configs.address
      : address;
    const newChannel = configs.serviceChannel !== undefined
      ? configs.serviceChannel
      : channel;
    const newLocation = configs.location !== undefined
      ? configs.location
      : location;
    const newTimeSlot = configs.timeSlot !== undefined
      ? configs.timeSlot
      : timeSlot;

    const currentComplete = !!currentConfig.serviceChannel
      && (!!currentConfig.address || !!currentConfig.location)
      && !!currentConfig.timeSlot
      && currentOrder?.order;
    const newComplete = !!newChannel
      && (!!newAddress || !!newLocation)
      && !!newTimeSlot;

    const proposedChange = (currentComplete && !newComplete)

    if (
      currentOrder
        && listRecords(currentOrder?.lineItems).length
        && options.moveItems
        && !proposedChange
    ) {
      if (!await updateLineItems({
        address : newAddress,
        serviceChannel : newChannel,
        location : newLocation,
        timeSlot : newTimeSlot,
        iteration : configs.iteration ?? currentConfig.iteration,
        division : configs.division ?? currentConfig.division,
      }, {
        target : options.target,
      })) return false;
    }

    const time = newTimeSlot ? calculateTime(
      newTimeSlot,
      configs.iteration ?? currentConfig.iteration,
      configs.division ?? currentConfig.division,
    ) ?? null : null;

    setAddress(newAddress);
    setChannel(newChannel);
    setLocation(newLocation);
    setTimeSlot(newTimeSlot);
    setIteration(configs.iteration ?? currentConfig.iteration);
    setDivision(configs.division ?? currentConfig.division);
    setTime(time);

    if (!proposedChange) setCurrentConfig({
      address : newAddress,
      serviceChannel : newChannel,
      location : newLocation,
      timeSlot : newTimeSlot,
      iteration : configs.iteration ?? currentConfig.iteration,
      division : configs.division ?? currentConfig.division,
      time,
    });

    return true;
  }, [
    address,
    channel,
    location,
    timeSlot,
    currentConfig,
    currentOrder,
    updateLineItems,
    calculateTime,
  ]);

  const updateOrderConfig = updateConfig((props : {
    address? : Address | null,
    serviceChannel? : ServiceChannel | null,
    location? : Location | null,
    timeSlot? : TimeSlot | null,
    iteration? : number,
    division? : number,
  }) => (props));

  const updateAddress = updateConfig((address : Address | null) => ({
    address,
    location : null,
    timeSlot : null,
    iteration : 0,
    division : 0,
  }));

  const updateServiceChannel = updateConfig(
    (channel : ServiceChannel | null) => ({
      address : null,
      serviceChannel : channel,
      location : null,
      timeSlot : null,
      iteration : 0,
      division : 0,
    })
  );

  const updateLocation = updateConfig((location : Location | null) => ({
    address : null,
    location,
    timeSlot : null,
    iteration : 0,
    division : 0,
  }));

  const updateTimeSlot = updateConfig((option : {
    timeSlot : TimeSlot | null,
    iteration? : number,
    division? : number,
  }) => ({
    timeSlot : option.timeSlot,
    iteration : option.iteration ?? 0,
    division : option.division ?? 0,
  }));

  const resetProposedChanges = useCallback(() => {
    setAddress(currentConfig.address);
    setChannel(currentConfig.serviceChannel);
    setLocation(currentConfig.location);
    setTimeSlot(currentConfig.timeSlot);
    setIteration(currentConfig.iteration);
    setDivision(currentConfig.division);
    setTime(currentConfig.time);
  }, [currentConfig]);

  const removeItem = useCallback(async (
    item : LineItem,
    options? : {
      target? : 'this' | 'future',
      isFulfilment? : boolean,
    },
  ) => {
    if (options?.isFulfilment || !canUpdateItem(item)) {
      const record = currentOrder?.order;
      const fulfilment = record
        ? Object.values(record.fulfilments).find(f => (
          !Object.values(currentOrder.lineItems).some(
            li => li.id === f.lineItemId
          )
            && f.requestedProductId === item.productId
            && f.requestedQty === item.quantity
        )) ?? null : null;

      if (fulfilment) {
        const lock = wait();
        const success = !!(await deleteFulfilment(fulfilment))?.fulfilment;
        release(lock);
        return success;
      }
      return false;
    }

    const recurring = isLineItemRecurring(item);
    if (recurring && !currentOrder) return false;
    const trueIteration = (
      isLineItemRecurring(item)
        && currentOrder
        && currentOrder.timeSlot
        && item.timeSlotId === currentOrder.timeSlot.id
    ) ? determineTrueIteration(
      item,
      currentOrder.timeSlot,
      currentOrder.timeSlotIteration,
    ) : null;

    const lock = wait();
    const success = (await deleteLineItem(item, options ? {
      startIteration : trueIteration ?? undefined,
      endIteration : options?.target === 'this'
        ? trueIteration
        : null,
    } : undefined)).success;
    release(lock);
    return success;
  }, [
    currentOrder,
    wait,
    release,
    determineTrueIteration,
    canUpdateItem,
    deleteLineItem,
    deleteFulfilment,
    isLineItemRecurring,
  ]);

  const checkServiceChannels = useCallback(async () => {
    const channels = await retrieveServiceChannels();
    if (!channels) return;
    const activeChannels = Object.values(channels)
    if (activeChannels.length === 1) setChannel(activeChannels[0]);
  }, [retrieveServiceChannels]);

  const calculateTimeSlotTimes = useCallback(
    (timeSlot : TimeSlot, iteration : number) => {
      const i = (findNextIteration(timeSlot, new Date()) ?? 0) + iteration;
      if (i < 0) return [];
      const duration = calculateDuration(timeSlot, i);

      if (!timeSlot.division) {
        const start = calculateTime(timeSlot, i, 0);
        const time = {
          start : start,
          end : duration
            ? new Date(start.getTime() + duration)
            : new Date(start.getTime() + 1),
        }
        return [time];
      }

      const divisions = timeSlot.division
        ? Math.floor(duration / timeSlot.division)
        : 1;

      const times = [] as TimeOption[];
      for (let j = 0; j < divisions; j += 1) {
        if (j > 288) {
          console.warn(`Too many divisions in time slot (${timeSlot.id})`);
          break;
        }

        const start = calculateTime(timeSlot, i, j);
        const time = {
          start : start,
          end : start,
        }
        if (!times.some(t => t.start.getTime() === time.start.getTime())) {
          times.push(time);
        }
      }
      return times;
    },
    [calculateTime, calculateDuration, findNextIteration],
  );

  const calculateScheduleTimes = useCallback((
    schedules : Schedule,
    iterations : number,
    overrides? : { services? : Service[] }
  ) => {
    const appServices = overrides?.services ?? availServices;

    const schedulesServices = schedules.serviceIds.map(id => services?.[id])
      .filter(s => s && appServices.some(a => a.id === s.id)) as Service[];
    const leadTime = calculateLeadTime(
      schedulesServices,
      { optimistic : false },
    );
    const cutoff = calculateLeadTime(
      schedulesServices,
      { optimistic : true },
    );

    const times = [] as TimeOption[];
    Object.values(schedules.timeSlots).forEach(timeSlot => {
      for (let i = -1; i < iterations; i += 1) {
        const slotTimes = calculateTimeSlotTimes(timeSlot, i);
        slotTimes.reduce((acc, time) => {
          if (!acc.some(t => t.start.getTime() === time.start.getTime())) {
            acc.push(time);
          }
          return acc;
        }, times);
      }
    });

    const lead = Date.now() + leadTime;
    const max = Date.now() + (WEEKS * 7 * 24 * 60 * 60 * 1000);
    return times
      .filter(time => ((time.start.getTime() > lead)
        && (time.start.getTime() < max)))
      .map(time => ({
        ...time,
        cutoff : new Date(time.start.getTime() - cutoff)
      }));
  }, [availServices, calculateTimeSlotTimes, services]);

  const calculateSchedules = useCallback(
    (schedules : Schedule[], iterations : number) => {
      const times = [] as TimeOption[];
      schedules.forEach(schedule => {
        const scheduleTimes = calculateScheduleTimes(
          schedule,
          iterations,
        );
        scheduleTimes.reduce((acc, time) => {
          if (!acc.some(t => t.start.getTime() === time.start.getTime())) {
            acc.push(time);
          }
          return acc;
        }, times);
      });
      return times;
    },
    [calculateScheduleTimes],
  );

  const proposeTime = useCallback(({
    address,
    location,
    starting,
  } : {
    address? : Address | null,
    location? : Location | null,
    starting? : Date | null,
  }) : { time : Date | null, selection : ScheduleSelection | null } => {
    if (!channel) return {
      time : null,
      selection : null,
    }

    const { services, schedules } = evaluateOptions({
      serviceChannel : channel,
      address,
      location,
    });
    const times = schedules
      .map((schedule) => calculateScheduleTimes(
        schedule,
        WEEKS + 2,
        { services },
      ))
      .flat()
      .sort((a, b) => a.start.getTime() - b.start.getTime());
    const time = times
      .find(t => !starting || t.start.getTime() >= starting.getTime())
    const selection = time?.start ? checkSchedule(schedules, time.start) : null;
    return {
      time : time?.start ?? null,
      selection
    };
  }, [channel, evaluateOptions, calculateScheduleTimes, checkSchedule]);

  const findOrder = useCallback((config : {
    address? : Address | null,
    serviceChannel? : ServiceChannel | null,
    location? : Location | null,
    timeSlot? : TimeSlot | null,
    iteration? : number,
    division? : number,
  }) => {
    const add = (config.address !== undefined)
      ? config.address
      : currentConfig.address;
    const chan = (config.serviceChannel !== undefined)
      ? config.serviceChannel
      : currentConfig.serviceChannel;
    const loc = (config.location !== undefined)
      ? config.location
      : currentConfig.location;
    const slot = (config.timeSlot !== undefined)
      ? config.timeSlot
      : currentConfig.timeSlot;
    const iter = (config.iteration !== undefined)
      ? config.iteration
      : currentConfig.iteration;
    const div = (config.division !== undefined)
      ? config.division
      : currentConfig.division;

    return orders?.find(order => (
      (order?.address?.id ?? null) === (add?.id ?? null) &&
      (order?.serviceChannel?.id ?? null) === (chan?.id ?? null) &&
      (order?.location?.id ?? null) === (loc?.id ?? null) &&
      (order?.timeSlot?.id ?? null) === (slot?.id ?? null) &&
      order?.timeSlotIteration === iter &&
      order?.timeSlotDivision === div
    )) ?? null;
  }, [currentConfig, orders]);

  const getOrder = useCallback((config : {
    address? : Address | null,
    serviceChannel? : ServiceChannel | null,
    location? : Location | null,
    time? : Date | null,
  }) => {
    if (!availSchedules) return null;
    const timeInfo = config.time !== undefined
      ? (config.time
        ? checkSchedule(Object.values(availSchedules), config.time)
        : null)
      : {
        timeSlot : undefined,
        iteration : undefined,
        division : undefined,
      }

    return findOrder({
      address : config.address,
      serviceChannel : config.serviceChannel,
      location : config.location,
      timeSlot : timeInfo?.timeSlot,
      iteration : timeInfo?.iteration,
      division : timeInfo?.division,
    });
  }, [findOrder, checkSchedule, availSchedules]);

  const newOrder = useCallback(() => {
    setChannel(null);
    setLocation(null);
    setAddress(null);
    setTimeSlot(null);
    setIteration(0);
    setDivision(0);
    setTime(null);
    setCurrentConfig({
      address : null,
      serviceChannel : null,
      location : null,
      timeSlot : null,
      iteration : 0,
      division : 0,
      time : null,
    });
  }, []);

  const editOrder = useCallback((order : DraftOrder) => {
    setChannel(order.serviceChannel);
    setLocation(order.location);
    setAddress(order.address);
    setTimeSlot(order.timeSlot);
    setIteration(order.timeSlotIteration);
    setDivision(order.timeSlotDivision);
    setTime(order.time);
    setCurrentConfig({
      address : order.address,
      serviceChannel : order.serviceChannel,
      location : order.location,
      timeSlot : order.timeSlot,
      iteration : order.timeSlotIteration,
      division : order.timeSlotDivision,
      time : order.time,
    });
  }, []);

  const claimItems = useCallback(async (
    customer : Customer,
    options? : { token? : string },
  ) => {
    const lock = wait();
    const items = await claimGuestItems(customer, { token : options?.token });
    if (items?.length) {
      const item = items[0];
      const timeSlot = item.timeSlotId
        ? timeSlots?.[item.timeSlotId] ?? null
        : null;
      updateTimeSlot({
        timeSlot : timeSlot,
        iteration : item.timeSlotIteration,
        division : item.timeSlotDivision,
      }, { moveItems : false });
    }
    release(lock);

    await refresh();
  }, [
    wait,
    release,
    claimGuestItems,
    timeSlots,
    updateTimeSlot,
    refresh,
  ]);

  const payOrder = useCallback(async ({ order, card, token } : {
    order : ProjectedOrder,
    card? : CreditCard,
    token? : string,
  }) => {
    const lock = wait();

    const newOrder = listRecords(await generateOrder(order))[0];
    if (!newOrder) return false;
    const success = !!(await payOrders([newOrder], { card, token }));
    release(lock);

    return success;
  }, [wait, release, generateOrder, payOrders]);

  const updateTimeOptions = useCallback(() => {
    const times = (calculateSchedules(
      Object.values(availSchedules),
      WEEKS,
    ));
    setTimeOptions(times);
    setFixedWindows(times.every(t => t.start.getTime() !== t.end.getTime()));
  }, [availSchedules, calculateSchedules]);

  useEffect(() => { getOrderStorage(); }, [getOrderStorage]);
  useEffect(() => { setOrderStorage(); }, [setOrderStorage]);
  useEffect(() => { checkServiceChannels(); }, [checkServiceChannels]);

  useEffect(() => { updateTimeOptions(); }, [updateTimeOptions]);

  useEffect(() => {
    if (!updateTime) return;
    setUpdateTime(false);
    updateTimeOptions();
  }, [updateTime, timeOptions, updateTimeOptions]);

  useEffect(() => {
    const updateInterval = setInterval(() => setUpdateTime(true), 60000);
    return () => clearInterval(updateInterval);
  }, []);

  useEffect(() => {
    if (!timeSlot) {
      setTime(null);
      return;
    };
    setTime(calculateTime(timeSlot, iteration, division ?? undefined) ?? null);
  }, [calculateTime, timeSlot, iteration, division]);

  useEffect(() => {
    const {
      serviceChannels : validChannels,
      locations : validLocations,
      schedules : validSchedules,
      services : validServices,
    } = evaluateOptions({
      serviceChannel : channel,
      address,
      location,
      timeSlot,
    });

    if (validChannels) setAvailChannels(validChannels);
    if (validLocations) setAvailLocations(validLocations);
    if (validSchedules) setAvailSchedules(validSchedules);
    if (validServices) setAvailServices(validServices);
  }, [evaluateOptions, address, channel, location, timeSlot]);

  useEffect(() => {
    if (!time) {
      setCutoff(null);
      return;
    }
    setCutoff(new Date(time.getTime() - calculateLeadTime(availServices)));
  }, [time, availServices]);

  useEffect(() => {
    const existing = findOrder(currentConfig);
    if (existing) setCurrentOrder(existing);
    else if (loaded && orders) setCurrentOrder({
      ...draftEmptyOrder(),
      customer,
      ...currentConfig,
      timeSlotIteration : currentConfig.iteration,
      timeSlotDivision : currentConfig.division,
    });
  }, [
    customer,
    currentConfig,
    orders,
    loaded,
    findOrder,
    draftEmptyOrder,
  ]);

  useEffect(() => {
    if (!lineItems) return;
    const productIds = listRecords(lineItems).map(li => li.productId);
    if (productIds.length) retrieveProductsBulk(productIds);
  }, [lineItems, retrieveProductsBulk]);

  useEffect(() => {
    if (!loaded || !currentOrder || canUpdateOrder()) return;
    newOrder();
  }, [currentOrder, loaded, canUpdateOrder, newOrder]);

  useEffect(() => {
    if (
      (waitingFor.length > 0)
        || (address?.id !== currentConfig.address?.id
          || channel?.id !== currentConfig.serviceChannel?.id
          || location?.id !== currentConfig.location?.id
          || timeSlot?.id !== currentConfig.timeSlot?.id
          || iteration !== currentConfig.iteration
          || division !== currentConfig.division)
    ) window.onbeforeunload = () => true;
    else window.onbeforeunload = null;
  }, [
    waitingFor,
    address,
    channel,
    location,
    timeSlot,
    iteration,
    division,
    currentConfig,
  ]);

  const proposedChanges = (
    address?.id !== currentConfig.address?.id
      || channel?.id !== currentConfig.serviceChannel?.id
      || location?.id !== currentConfig.location?.id
      || timeSlot?.id !== currentConfig.timeSlot?.id
      || iteration !== currentConfig.iteration
      || division !== currentConfig.division
  );

  const context = {
    waiting : waiting,
    proposedChanges,
    address,
    serviceChannel: channel,
    location,
    timeSlot,
    timeSlotIteration: iteration,
    timeSlotDivision: division,
    time,
    lineItems,
    orders,
    availableServices : availServices,
    availableServiceChannels : availChannels,
    availableLocations : availLocations,
    availableSchedules : availSchedules,
    timeOptions,
    fixedWindows,
    cutoff,
    currentOrder,
    proposeTime,
    updateOrder : updateOrderConfig,
    setAddress : updateAddress,
    setServiceChannel : updateServiceChannel,
    setLocation : updateLocation,
    setTimeSlot : updateTimeSlot,
    resetProposedChanges,
    addItem,
    updateItem : amendItem,
    removeItem,
    calculateCutoff,
    canUpdateOrder,
    canUpdateItem,
    findOrder,
    getOrder,
    newOrder,
    editOrder,
    claimItems,
    payOrder,
    refresh,
  };

  return (
    <RequestContext.Provider value={context}>
      { children }
    </RequestContext.Provider>
  );
}

export default RequestContext;
