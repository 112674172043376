import { useCallback } from 'react';

import { DataIndex, DataDispatch, Response } from '#mrktbox/clerk/types';

import useLoading from '#mrktbox/clerk/hooks/useLoading';

import { actionTypes, parseIndex } from '#mrktbox/clerk/utils/data';

interface ResourceType {
  id? : number;
}

type Process<Args extends any[], Return = any> =
  (...args : Args) => Promise<Response<Return>>;

export function checkCachedRecord<DT>(data : DataIndex<DT> | null) {
  return (id : number) => {
    return data !== null;
  }
}

export function checkCachedIndex<DT>(data : DataIndex<DT> | null) {
  return () => data !== null;
}

export function getCachedRecord<DT>(data : DataIndex<DT> | null) {
  return (id : number) => { return data ? data[id] ?? null : null; }
}

export function getCachedIndex<DT>(data : DataIndex<DT> | null) {
  return () => data;
}

interface useCacheProps<
  DT = {},
  Return = any,
  Args extends any[] = any[],
  Request extends Process<Args, Return> = Process<Args, Return>,
  Output extends any = Return,
  Index extends boolean = boolean,
> {
  data? : DataIndex<DT> | null;
  stale? : boolean;
  update? : boolean;
  refresh? : boolean;
  drop? : boolean;
  dropNull? : boolean;
  isLoader? : boolean;
  process : Request;
  dispatch : DataDispatch<DT>;
  adjustment? : (args : Args) => Args;
  parser? : (data : Return) => DataIndex<DT> | null;
  validator? : (data : DataIndex<DT> | null) => (...args : Args) => boolean;
  callback? : (data : DataIndex<DT> | null) => (...args : Args) => Return;
  filter? : (data : Return) => Output;
  keyLoader? : (...args : Args) => string;
  index? : Index;
}

interface useIndexCacheProps<
  DT = {},
  Return extends DataIndex<DT> | null = DataIndex<DT> | null,
  Args extends [] = [],
  Request extends Process<Args, Return> = Process<Args, Return>,
> extends useCacheProps<DT, Return, Args, Request, true> {
  data : DataIndex<DT> | null;
  validator? : undefined;
  callback? : undefined;
  index? : true;
}
interface useRecordCacheProps<
  DT = {},
  Return extends DT | null = DT | null,
  Args extends [number] = [number],
  Request extends Process<Args, Return> = Process<Args, Return>,
> extends useCacheProps<DT, Return, Args, Request, false> {
  data : DataIndex<DT> | null;
  validator? : undefined;
  callback? : undefined;
  index? : false;
}

interface useNoCacheProps<
  DT = {},
  Return = any,
  Args extends any[] = any[],
  Request extends Process<Args, Return> = Process<Args, Return>,
  Output extends any = Return,
> extends useCacheProps<DT, Return, Args, Request, Output> {
  data? : undefined;
  validator? : undefined;
  callback? : undefined;
  index? : undefined;
}
interface useImplicitNoCacheProps<
  DT = {},
  Return extends DataIndex<DT> | DT | null = DataIndex<DT> | DT | null,
  Args extends any[] = any[],
  Output extends any = Return,
> extends useNoCacheProps<DT, Return, Args, Process<Args, Return>, Output> {}
interface useExplicitNoCacheProps<
  DT = {},
  Return = any,
  Args extends any[] = any[],
  Output extends any = Return,
> extends useNoCacheProps<DT, Return, Args, Process<Args, Return>, Output> {
  parser : (data : Return) => DataIndex<DT> | null;
}

interface useCustomeCacheProps<
  DT = {},
  Return = any,
  Args extends any[] = any[],
  Output extends any = Return,
> extends useCacheProps<DT, Return, Args, Process<Args, Return>, Output> {
  data : DataIndex<DT> | null;
  validator : (data : DataIndex<DT> | null) => (...args : Args) => boolean;
  callback : (data : DataIndex<DT> | null) => (...args : Args) => Return;
  index? : undefined;
}
interface useImplicitCustomCacheProps<
  DT = {},
  Return extends DataIndex<DT> | DT | null = DataIndex<DT> | DT | null,
  Args extends any[] = any[],
  Output extends any = Return,
> extends useCustomeCacheProps<DT, Return, Args, Output> {}
interface useExplicitCustomCacheProps<
  DT = {},
  Return = any,
  Args extends any[] = any[],
  Output extends any = Return,
> extends useCustomeCacheProps<DT, Return, Args, Output> {
  parser : (data : Return) => DataIndex<DT> | null;
}

function useCache<
  DT extends ResourceType,
  Return extends DataIndex<DT> | null = DataIndex<DT> | null,
  Args extends [] = []
>(
  props : useIndexCacheProps<DT, Return, Args>,
) : (...args : Args) => Promise<DataIndex<DT> | null>;
function useCache<
  DT extends ResourceType,
  Return extends DT | null = DT | null,
  Args extends [number] = [number],
>(
  props : useRecordCacheProps<DT, Return, Args>,
) : (...args : Args) => Promise<DT | null>;
function useCache<
  DT extends ResourceType,
  Return extends DataIndex<DT> | DT | null = DataIndex<DT> | DT | null,
  Args extends any[] = any[],
  Output extends any = Return,
>(
  props : useImplicitNoCacheProps<DT, Return, Args, Output>
) : (...args : Args) => Promise<Return | null>;
function useCache<
  DT extends ResourceType,
  Return = any,
  Args extends any[] = any[],
  Output = Return,
>(
  props : useExplicitNoCacheProps<DT, Return, Args, Output>
) : (...args : Args) => Promise<Output | null>;
function useCache<
  DT extends ResourceType,
  Return extends DataIndex<DT> | { [id : number] : DT } | DT | null = DataIndex<DT> | DT | null,
  Args extends any[] = any[],
  Output extends any = Return,
>(
  props : useImplicitCustomCacheProps<DT, Return, Args, Output>
) : (...args : Args) => Promise<Return | null>;
function useCache<
  DT extends ResourceType,
  Return = any,
  Args extends any[] = any[],
  Output extends any = Return,
>(
  props : useExplicitCustomCacheProps<DT, Return, Args, Output>
) : (...args : Args) => Promise<DataIndex<DT> | null>;

function useCache<
  DT extends ResourceType,
  Return,
  Args extends any[],
  Output extends any = Return,
>({
  data = null,
  stale = false,
  update = false,
  refresh = false,
  drop = false,
  dropNull = false,
  isLoader = false,
  process,
  dispatch,
  parser,
  validator,
  callback,
  filter,
  keyLoader,
} : useCacheProps<DT, Return, Args, Process<Args, Return>, Output>) {
  const { load } = useLoading<Response<Return>>();

  type CallbackReturn = Output | Return | null
  return useCallback(async (...args : Args) : Promise<CallbackReturn> => {
    const isResource = process.length === 1;
    const isIndex = process.length === 0;

    if ((!stale || !refresh) && data) {
      if (validator && callback) {
        if (validator(data)(...args)) return callback(data)(...args);
      } else if (isIndex) {
        if (checkCachedIndex(data)()) return getCachedIndex(data)() as Return;
      } else if (isResource) {
        if (checkCachedRecord(data)(args[0])) {
          return getCachedRecord(data)(args[0]) as Return;
        }
      }
    }

    const { response } = isLoader
      ? (await load(
        async () => await process(...args),
        undefined,
        keyLoader ? keyLoader(...args) : `${args}`
      ))[0]
      : await process(...args);

    const resource = response
      ? (parser ? parser(response) : parseIndex<DT>(response))
      : null;

    if (resource === null) {
      if (typeof args[0] == 'number') {
        dispatch({
          data : { [args[0]] : null },
          type : actionTypes.add,
        });
      }
      return response;
    }

    dispatch({
      data: resource,
      type : drop ? actionTypes.remove
        : (refresh ? actionTypes.set
          : (update ? actionTypes.update
            : actionTypes.add)),
    });
    return (filter && response) ? filter(response) : response;
  }, [
    data,
    stale,
    update,
    refresh,
    drop,
    isLoader,
    process,
    dispatch,
    parser,
    validator,
    callback,
    filter,
    keyLoader,
    load,
  ]);
}

export default useCache;
